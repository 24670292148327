import React, { useContext, useState } from 'react';
import CouponSummary from './CouponSummary';
import './textarea.css';
import { renderTooltip } from "../../Components/Helper";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Info from "../../Assets/images/info.png";
import CouponContext from './CouponContext';

const CreateCoupon = ({couponType}) => {
    const { couponData, updateCouponData } = useContext(CouponContext);
    const [upc, setUpc] = useState(couponData.upc ? couponData.upc.join('\n') : "");
    const [rewardUpc, setRewardUpc] = useState(couponData.reward_upc ? couponData.reward_upc.join('\n') : "");
    const handlePaste = (event, type = "upc") => {
        const pastedData = event.clipboardData.getData('Text');
        const upcArray = pastedData.split(/\n/).filter(value => value.trim() !== "");

        if (type === "upc") {
            updateCouponData({ upc: upcArray });
        } else if (type === "reward") {
            updateCouponData({ reward_upc: upcArray });
        }
    };

    const handleUpcChange = (event, type = "upc") => {
        const inputData = event.target.value;
        const upcArray = inputData.split(/[\s,]+/).filter(value => value.trim() !== "");

        if (type === "upc") {
            const sanitizedValue = event.target.value.replace(/[^0-9,\n]/g, '');
            setUpc(sanitizedValue);
            updateCouponData({ upc: upcArray });
        } else if (type === "reward") {
            const sanitizedValue = event.target.value.replace(/[^0-9,\n]/g, '');
            setRewardUpc(sanitizedValue);
            updateCouponData({ reward_upc: upcArray });
        }
    };

    return (
        <React.Fragment>
            <div
                className="tab-pane fade show active"
                id="nav-step-1"
                role="tabpanel"
                aria-labelledby="nav-step-1-tab"
            >
                <div className="row  g-3">
                    <div className="col-xxl-9 col-xl-8 col-12">
                        <div className="Steps_Header d-flex align-items-center justify-content-between">
                            <h5 className="">Create Digital Coupons  </h5>
                            <div className="Search_Box">
                                <input
                                    type="text"
                                    className="form-control Search_Toggle"
                                    placeholder="Search"
                                    aria-label="Search"
                                    aria-describedby="search-addon"
                                />
                                <span className="Input_Icon">
                                    <svg
                                        width="17"
                                        height="18"
                                        viewBox="0 0 17 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M7.7 14.9C11.4003 14.9 14.4 11.9003 14.4 8.2C14.4 4.49969 11.4003 1.5 7.7 1.5C3.99969 1.5 1 4.49969 1 8.2C1 11.9003 3.99969 14.9 7.7 14.9Z"
                                            stroke="white"
                                            strokeWidth="1.5"
                                            strokeMiterlimit="3.3333"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M16.0004 16.5L12.4004 12.9"
                                            stroke="white"
                                            strokeWidth="1.5"
                                            strokeMiterlimit="3.3333"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </div>

                        <div className="table-responsive Create_Coupons_Table">

                            <div className="Common_Block_Body pt-17 pb-17 pr-14 pl-14 Build_Offer_Block">
                                <h5 className="Title">Enter UPCs
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip('No spaces and special charcters like `@`,`#`,etc... not allowed')}
                                    >
                                        <img src={Info} className="img-fluid" alt="Edit"
                                            style={{ padding: '1px', width: "31px", height: "31px", cursor: 'pointer' }} />
                                    </OverlayTrigger>
                                </h5>
                                <p style={{ color: "gray", fontStyle: "italic", fontSize: "13px" }}>
                                    Please hit enter if products count is not updated on right side
                                </p>
                                <div className="row">
                                    <div className="col-lg-6 col-md-12 col-12">
                                        <textarea
                                            type="text"
                                            rows="10"
                                            onChange={e => handleUpcChange(e, "upc")}
                                            className="upctextarea"
                                            onPaste={e =>  handlePaste(e, "upc")}
                                            placeholder='Copy and Paste from Excel Column or Start Typing..'
                                            value={upc}
                                        />
                                    </div>
                                </div>
                            </div>
                            {(couponData.offer_type === 3 || couponData.offer_type === 4) && (
                                    <div className="Common_Block_Body pt-17 pb-17 pr-14 pl-14 Build_Offer_Block">
                                        <h5 className="Title">Reward UPCs
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderTooltip('No spaces and special charcters like `@`,`#`,etc... not allowed')}
                                            >
                                                <img src={Info} className="img-fluid" alt="Edit"
                                                    style={{ padding: '1px', width: "31px", height: "31px", cursor: 'pointer' }} />
                                            </OverlayTrigger>
                                        </h5>
                                        <p style={{ color: "gray", fontStyle: "italic", fontSize: "13px" }}>
                                            Please hit enter if products count is not updated on right side
                                        </p>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <textarea
                                                    type="text"
                                                    rows="10"
                                                    onChange={(e) => handleUpcChange(e, "reward")}
                                                    className="upctextarea"
                                                    onPaste={e =>  handlePaste(e, "reward")}
                                                    placeholder='Copy and Paste from Excel Column or Start Typing..'
                                                    value={rewardUpc}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    )}
                        </div>
                    </div>

                    <div className="col-xxl-3 col-xl-4 col-12">
                        <CouponSummary couponType={couponType} />
                        <div className="Common_Block_Body">
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CreateCoupon;