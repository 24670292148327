import React, { useContext, useEffect } from 'react';
import CouponContext from './CouponContext';
import { toast } from 'react-toastify';
import { getCouponByGuid } from '../../API/Wholesalerapi';
const Stepper = ({ guid, action, provider, isFsi }) => {
    const { couponData, updateCouponData } = useContext(CouponContext);

    const formatDate = (dateTimeString) => {
        return dateTimeString.split(' ')[0];
    };

    const getRetailers = (data) => {
        return data.flatMap(wholesaler =>
            wholesaler.retailer.map(retailer => ({
                retailer_id: retailer.retailer_id,
                start_date: formatDate(retailer.start_date),
                end_date: formatDate(retailer.end_date),
                shutoff_date: formatDate(retailer.shutoff_date),
                clip_limit: retailer.clip_limit || 1000000,
                redeem_limit: 0,
                wholesaler_id: wholesaler.wholesaler_id
            }))
        );
    };

    

    const getCouponDetails = async () => {
        try {
            const coupon = await getCouponByGuid(guid);
            const getOfferType = () => {
                const { category_name, coupon_type_name } = coupon;
                if (category_name === "Scan" || (category_name === "UPC Coupon" && coupon_type_name === "AmountOff")) {
                    return 1;
                } else if (category_name === "UPC Coupon" && coupon_type_name === "PercentOff") {
                    return 2;
                } else if (category_name === "Cross Sell" && coupon_type_name === "AmountOff") {
                    return 3;
                } else if (category_name === "Cross Sell" && coupon_type_name === "PercentOff") {
                    return 4;
                }
            };

            const getSelectIds = () => {
                if (coupon.retailer_groups?.length > 0) {
                    return coupon.retailer_groups.flatMap(group => group.retailer_group_id);
                }
                if(coupon.coupon_target_type_id === 1) {
                    return coupon.wholesalers.map(wholesaler => wholesaler.wholesaler_id);
                }
                if(coupon.coupon_target_type_id === 2) {
                    return coupon.wholesalers.flatMap(wholesaler =>
                        wholesaler.retailer.map(retailer => retailer.retailer_id)
                    );
                }
            }
            updateCouponData(
                {
                    addtional_details: coupon.addtional_details,
                    coupon_category: coupon.coupon_category_id,
                    coupon_target_type_id: coupon.retailer_groups?.length > 0 ? 3 : coupon.coupon_target_type_id,
                    offer_type: getOfferType(),
                    description: coupon.coupon_description,
                    disclaimer: coupon.disclaimer,
                    end_date: formatDate(coupon.coupon_end_date),
                    image_1: coupon.image_1,
                    is_discount_percentage: coupon.is_discount_percentage,
                    is_flex: coupon.is_flex,
                    min_quantity: coupon.min_quantity,
                    retailers: coupon.coupon_target_type_id === 2 ? getRetailers(coupon.wholesalers) : [],
                    retailer_groups: coupon.retailer_groups,
                    reward_quantity: coupon.reward_quantity,
                    shutoff_date: formatDate(coupon.coupon_shutoff_date),
                    start_date: formatDate(coupon.coupon_start_date),
                    submitting_offer: false,
                    title: coupon.coupon_title,
                    upc: provider === "brand" ? coupon.upc?.map(upc => upc.upc_guid) : coupon.upc?.map(upc => upc.upc11),
                    wholesalers: coupon.wholesalers.map(wholesaler => ({
                        wholesaler_id: wholesaler.wholesaler_id,
                        start_date: formatDate(wholesaler.wholesaler_start_date),
                        end_date: formatDate(wholesaler.wholesaler_end_date),
                        shutoff_date: formatDate(wholesaler.wholesaler_shutoff_date),
                        clip_limit: wholesaler.clip_limit || 1000000,
                        redeem_limit: 0,
                        retailer: wholesaler.retailer?.map(retailer => ({
                            retailer_id: retailer.retailer_id,
                            start_date: formatDate(retailer.start_date),
                            end_date: formatDate(retailer.end_date),
                            shutoff_date: formatDate(retailer.shutoff_date),
                            clip_limit: retailer.clip_limit || 1000000,
                            redeem_limit: 0
                        }))
                    })),
                    reward_upc: provider === "brand" ? coupon.reward_upc?.map(upc => upc.upc_guid) : coupon.reward_upc?.map(upc => upc.upc11),
                    selectedIds: getSelectIds(),
                    selectAll: false,
                    reward_amount: coupon.reward_amount,
                    action: action,
                    guid: guid,
                    coupon_status: coupon.coupon_status,
                    coupon_code: coupon.coupon_code,
                    coupon_limit: coupon.coupon_condition?.per_transaction_limit,
                }
            );
        } catch (error) {
            toast.error("Failed to fetch coupon details:");
        }
    };

    useEffect(() => {
        if (guid) {
            getCouponDetails();
        }
        updateCouponData({ provider: provider, isFsi: isFsi });
    }, [guid, provider]);

    return (
        <div>
        </div>
    );
};

export default Stepper;