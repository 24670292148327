import $ from "jquery";
import React from "react";
import Header from "../../Components/Wholesaler/layout/header";
import ls from "local-storage";
import RV from "simple-react-validator";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Redirect } from "react-router";
import { buildCoupon } from "../../API/CreateCoupon";

import { getBrands } from "../../API/DashBoard";
import CreateCoupon from "./CreateCoupons";
import BuildOffer from "./BuildOffer";
//import SelectTargets from "./SelectTargets";
//import CampaignDetails from "./CampaignDetails";
import Preview from "./Preview";

import FooterStrict from "../Dashboard/FooterStrict";
import SelectTarget from "./SelectTarget";
import CampaignDetail from "./CampaignDetail";
import VendorParticipation from "./VendorParticipation";

const Loader = () => <div id="cover-spin"></div>;

class WholesalerCoupon extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new RV();
    this.validator.visibleFields = [];
    toast.configure();
    this.state = {
      step: 0,
      title: "All Brands",
      upcArrlength: 0,
      error: [],
      formControls: {},
      offerType: 0,
      wholesaler: [],
      retailer: [],
      selectedOption: "",
      camp_minDate: "",
      camp_maxDate: "",
      camp_shutoffDate: "",
      select_all: false,
      retailerGroups: [],
      retailerGroupsData: [],
      vendorParticipation: {
        per_redemption: 0,
        per_scan: 0,
        fixed_amount: 0,
      },
      rewardUpc: [],
      upcArr: [],
    };

    this.selectAll = this.selectAll.bind(this);
  }

  selectAll = (dataInput) => {
    const selectAllState = !this.state.select_all;
    const updatedWholesalers = selectAllState
      ? dataInput.map(item => ({
        wholesaler_name: item.wholesaler_name,
        logo: item.logo,
        wholesaler_id: item.wholesaler_id,
        camp_wholesaler_start_date: this.state.global_prev_start_date,
        camp_iso_wholesaler_start_date: this.state.global_build_start_date,
        camp_wholesaler_end_date: this.state.global_prev_end_date,
        camp_iso_wholesaler_end_date: this.state.global_build_end_date,
        camp_iso_wholesaler_shutoff_date: this.state.global_build_shutoff_date || this.state.global_build_end_date,
      }))
      : [];

    this.setState({
      select_all: selectAllState,
      wholesaler: updatedWholesalers,
    });
  };

  updateWholeSalers = (newData) => {
    const updatedWholesalers = newData.map(item => ({
      ...item,
      camp_wholesaler_start_date: this.state.global_prev_start_date,
      camp_iso_wholesaler_start_date: this.state.global_build_start_date,
      camp_wholesaler_end_date: this.state.global_prev_end_date,
      camp_iso_wholesaler_end_date: this.state.global_build_end_date,
      camp_iso_wholesaler_shutoff_date: this.state.global_build_shutoff_date || this.state.global_build_end_date,
      clip_limit: 1000000,
    }))
    this.setState({ wholesaler: updatedWholesalers });
  };

  updateRetailerGroupsData = (newData) => {
    this.setState({ retailerGroupsData: newData });
  };

  selectAllRetailerGroups = (retailersGroupsData) => {
    const selectAllState = !this.state.select_all;
    const updatedRetailerGroups = selectAllState
      ? retailersGroupsData.map(({ retailer_group_id }) => retailer_group_id)
      : [];

    this.setState({
      select_all: selectAllState,
      retailerGroups: updatedRetailerGroups,
    });
  };

  selectAllRetailers = (dataInput1) => {
    const selectAllState = !this.state.select_all;
    const updatedRetailers = selectAllState
      ? dataInput1.map(item => ({
        retailer_name: item.retailer_name,
        retailer_id: item.retailer_id,
        wholesaler_id: item.wholesaler_id,
        retailerlogo: item.logo,
        camp_retailer_id: item.retailer_id,
        camp_wholesaler_id: item.wholesaler_id,
        camp_retailer_start_date: this.state.global_prev_start_date,
        camp_retailer_end_date: this.state.global_prev_end_date,
        camp_iso_retailer_start_date: this.state.global_build_start_date,
        camp_iso_retailer_end_date: this.state.global_build_end_date,
        camp_iso_retailer_shutoff_date: this.state.global_build_shutoff_date || this.state.global_build_end_date,
      }))
      : [];

    this.setState({
      select_all: selectAllState,
      retailer: updatedRetailers,
    });
  };

  handleOptionChange = (newValue) => {
    this.setState({
      selectedOption: newValue
    });
  };

  async componentDidMount() {
    const brands = ls.get("brands") || "";

    $(".wizard li").on("click", function () {
      $(this).removeClass("completed")
        .prevAll().addClass("completed")
        .end().nextAll().removeClass("completed")
        .end().addClass("active");
    });

    $(".Offer_Type").on("click", function () {
      $(".Offer_Type").removeClass("Selected");
      $(this).addClass("Selected");
    });

    $(".Search_Box").on("click", function () {
      $(".Search_Box input").show();
    });

    $(document).on("mouseup", function (e) {
      if (!$(e.target).closest(".Search_Box input").length) {
        $(".Search_Box input").hide();
      }
    });

    try {
      const response = await getBrands(brands[0].guid);
      this.setState({ WholeSaler: response.Message });
    } catch (error) {
      console.error("Error fetching brands:", error);
    }
  }

  handleUpcChange = (value, type) => {
    const upcArr = value?.split(/[\n,]+/).map(upc => upc.trim()) || [];
    if (upcArr.length > 0 && type === "upc") {
      this.setState({ upcArr: upcArr, upcArrlength: upcArr.length });
    }
    else if (upcArr.length > 0 && type === "reward") {
      this.setState({ rewardUpc: upcArr });
    }
  }

  checkValidation(upcArr) {
    const error = upcArr.some(upc => isNaN(parseInt(upc)));
    this.setState({
      upcArrlength: upcArr.length,
      upcArr,
      error: error ? ["error"] : []
    });
    return error;
  }

  handleSeconDdate(date) {
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  }

  changeHandler = ({ target: { name, value } }) => {
    this.setState(prevState => ({
      formControls: {
        ...prevState.formControls,
        [name]: value
      }
    }));
  };

  showLoader = () => {
    this.setState({ spinloading: true });
  };

  hideLoader = () => {
    this.setState({ spinloading: false });
  };

  onchangeoffercheckbox = async (e) => {
    if (e.target.checked) {
      this.setState({ offercheckbox: 1 });
    } else {
      this.setState({ offercheckbox: 0 });
    }
  };

  onchangeOffertype = async (event, Offer_Type) => {
    this.setState({ offerType: Number(Offer_Type) });
  };

  setStartDate = async (e) => {
    const value = e.target.value;
    const global_start_date = new Date(value);
    const prev_start_date = this.handleSeconDdate(global_start_date);
    const build_start_date = global_start_date.toISOString();
    const maxDate = global_start_date.toISOString().split("T")[0];

    $("input[type='date'][name='end_date']").attr("min", maxDate);

    this.setState({
      global_build_start_date: build_start_date,
      global_prev_start_date: prev_start_date,
      camp_minDate: maxDate,
      [this.state.selectedOption !== "wholesaler" ? "retailer" : "wholesaler"]: this.state[
        this.state.selectedOption !== "wholesaler" ? "retailer" : "wholesaler"
      ].map((item) => ({
        ...item,
        [this.state.selectedOption !== "wholesaler" ? "camp_retailer_start_date" : "camp_wholesaler_start_date"]: prev_start_date,
        [this.state.selectedOption !== "wholesaler" ? "camp_iso_retailer_start_date" : "camp_iso_wholesaler_start_date"]: build_start_date,
      })),
    });
  };

  onchangeSetdate = async (e, key) => {
    const iso_camp_start_date = new Date(e.target.value);
    const setstartdate = this.handleSeconDdate(iso_camp_start_date);

    this.setState((prevState) => ({
      wholesaler: prevState.wholesaler.map((item) =>
        item.wholesaler_id === key.wholesaler_id
          ? {
            ...item,
            camp_wholesaler_start_date: setstartdate,
            camp_iso_wholesaler_start_date: iso_camp_start_date.toISOString(),
          }
          : item
      ),
    }));
  };

  onchangeSetdateRetailer = async (e, key) => {
    const iso_camp_start_date = new Date(e.target.value);

    this.setState((prevState) => ({
      retailer: prevState.retailer.map((item) =>
        item.retailer_id === key.retailer_id
          ? {
            ...item,
            camp_iso_retailer_start_date: iso_camp_start_date.toISOString(),
          }
          : item
      ),
    }));
  };

  onchangeSetEnddateRetailer = async (e, key) => {
    const iso_camp_start_date = new Date(e.target.value);
    const setstartdate = iso_camp_start_date.toISOString().split("T")[0];

    $("#shutoff_date").val(e.target.value).attr("max", "yyyy-MM-dd");
    $("input[type='date'][name='shutoff_date']").attr("max", setstartdate);

    this.setState((prevState) => ({
      retailer: prevState.retailer.map((item) =>
        item.retailer_id === key.retailer_id
          ? {
            ...item,
            camp_iso_retailer_end_date: iso_camp_start_date.toISOString(),
            camp_iso_retailer_shutoff_date: iso_camp_start_date.toISOString(),
          }
          : item
      ),
    }));
  };

  onchangeSetShutoffdateRetailer = async (e, key) => {
    const iso_camp_start_date = new Date(e.target.value);

    this.setState((prevState) => ({
      retailer: prevState.retailer.map((item) =>
        item.retailer_id === key.retailer_id
          ? {
            ...item,
            camp_iso_retailer_shutoff_date: iso_camp_start_date.toISOString(),
          }
          : item
      ),
    }));
  };

  onchangesetEndDate = async (e, key) => {
    const iso_camp_start_date = new Date(e.target.value);
    const setstartdate = this.handleSeconDdate(iso_camp_start_date);

    $("#shutoff_date").val(e.target.value).attr("max", "yyyy-MM-dd");
    $("input[type='date'][name='shutoff_date']").attr("max", setstartdate);

    this.setState((prevState) => ({
      wholesaler: prevState.wholesaler.map((item) =>
        item.wholesaler_id === key.wholesaler_id
          ? {
            ...item,
            camp_wholesaler_end_date: setstartdate,
            camp_iso_wholesaler_end_date: iso_camp_start_date.toISOString(),
            camp_wholesaler_shutoff_date: setstartdate,
            camp_iso_wholesaler_shutoff_date: iso_camp_start_date.toISOString(),
          }
          : item
      ),
    }));
  };

  cliplimitChange = async (e, key) => {
    this.setState((prevState) => ({
      wholesaler: prevState.wholesaler.map((item) =>
        item.wholesaler_id === key.wholesaler_id
          ? {
            ...item,
            camp_clip_limit_val: e.target.value,
          }
          : item
      ),
    }));
  };

  cliplimitChangeRetailer = async (e, key) => {
    this.setState((prevState) => ({
      retailer: prevState.retailer.map((item) =>
        item.retailer_id === key.retailer_id
          ? {
            ...item,
            clip_limit: e.target.value,
          }
          : item
      ),
    }));
  };

  onchangeShutOffDate = async (e, key) => {
    const iso_camp_start_date = new Date(e.target.value);
    const setstartdate = this.handleSeconDdate(iso_camp_start_date);

    this.setState((prevState) => ({
      wholesaler: prevState.wholesaler.map((item) =>
        item.wholesaler_id === key.wholesaler_id
          ? {
            ...item,
            camp_wholesaler_shutoff_date: setstartdate,
            camp_iso_wholesaler_shutoff_date: iso_camp_start_date.toISOString(),
          }
          : item
      ),
    }));
  };

  setEndDate = async (e) => {
    const value = e.target.value;
    const global_end_date = new Date(value);
    const prev_end_date = this.handleSeconDdate(global_end_date);
    const build_end_date = global_end_date.toISOString();
    const maxDate = global_end_date.toISOString().split("T")[0];

    $("#shutoff_date").val(value).attr("max", "yyyy-MM-dd");
    $("input[type='date'][name='shutoff_date']").attr("max", maxDate);

    this.setState({
      global_build_end_date: build_end_date,
      global_prev_end_date: prev_end_date,
      camp_maxDate: maxDate,
      camp_shutoffDate: maxDate,
      global_build_shutoff_date: build_end_date,
      [this.state.selectedOption !== "wholesaler" ? "retailer" : "wholesaler"]: this.state[
        this.state.selectedOption !== "wholesaler" ? "retailer" : "wholesaler"
      ].map((item) => ({
        ...item,
        [this.state.selectedOption !== "wholesaler" ? "camp_retailer_end_date" : "camp_wholesaler_end_date"]: prev_end_date,
        [this.state.selectedOption !== "wholesaler" ? "camp_iso_retailer_end_date" : "camp_iso_wholesaler_end_date"]: build_end_date,
        [this.state.selectedOption !== "wholesaler" ? "camp_retailer_shutoff_date" : "camp_wholesaler_shutoff_date"]: prev_end_date,
        [this.state.selectedOption !== "wholesaler" ? "camp_iso_retailer_shutoff_date" : "camp_iso_wholesaler_shutoff_date"]: build_end_date,
      })),
    });
  };

  setShutoffDate = async (e) => {
    const value = e.target.value;
    const global_shutoff_date = new Date(value);
    const maxDate = global_shutoff_date.toISOString().split("T")[0];
    const build_shutoff_date = global_shutoff_date.toISOString();

    this.setState({
      global_build_shutoff_date: build_shutoff_date,
      camp_shutoffDate: maxDate,
    });
  };

  onFileChange = (event) => {
    const file = event.target.files[0];
    if (!file.name.match(/\.(jpg|jpeg|png|PNG|JPEG|JPG)$/)) {
      toast.error("Please Select a Valid Image!");
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({ selectedFile: reader.result });
    };
  };

  changeTab = async (e, key) => {
    if (this.state.upcArrlength > 0) {
      const validationErr = this.checkValidation(this.state.upcArr);

      if (validationErr) {
        if (this.state.error.includes("error")) {
          toast.error("Invalid UPCs entered - only integer and comma allowed no spaces", { theme: "colored" });
          return;
        }
      } else {
        $("#nav-step-" + key + "-tab").trigger("click").attr("aria-selected", false);
      }
    } else {
      toast.error("Please Enter at least one product to continue", { theme: "colored" });
    }
    if (this.state.offerType === 3 || this.state.offerType === 4) {
      if (this.state.rewardUpc.length > 0) {
        const validationErr = this.checkValidation(this.state.rewardUpc);

        if (validationErr) {
          if (this.state.error.includes("error")) {
            toast.error("Invalid Reward UPCs entered - only integer and comma allowed no spaces", { theme: "colored" });
            return;
          }
        } else {
          $("#nav-step-" + key + "-tab").trigger("click").attr("aria-selected", false);
        }
      } else {
        toast.error("Please Enter at least one reward product to continue", { theme: "colored" });
      }
    }
  };

  changeVendorParticipation = async (e, key) => {
    $("#nav-step-" + key + "-tab").trigger("click").attr("aria-selected", false);
  };

  changeTargetTabSelect = async (e, key) => {
    if (
      (this.state.selectedOption === "retailer" && this.state.retailer.length < 1) ||
      this.state.selectedOption === "" ||
      (this.state.selectedOption === "wholesaler" && this.state.wholesaler.length < 1)
    ) {
      toast.error("Please select at least one wholesaler or retailer", { theme: "colored" });
    } else {
      $("#nav-step-" + key + "-tab").trigger("click").attr("aria-selected", false);
    }
  };

  changeTargetTab = async (e, key) => {
    $("#nav-step-" + key + "-tab").trigger("click").attr("aria-selected", false);
  };

  changeTabCompaignDetails = async (e, key) => {
    const hasInvalidDates = this.state[this.state.selectedOption === "wholesaler" ? "wholesaler" : "retailer"].some(
      (item) =>
        new Date(item[this.state.selectedOption === "wholesaler" ? "camp_iso_wholesaler_shutoff_date" : "camp_iso_retailer_shutoff_date"]) >
        new Date(item[this.state.selectedOption === "wholesaler" ? "camp_iso_wholesaler_end_date" : "camp_iso_retailer_end_date"])
    );

    if (hasInvalidDates) {
      toast.error("Please choose shutoff date before end date", { theme: "colored" });
    } else {
      $("#nav-step-" + key + "-tab").trigger("click").attr("aria-selected", false);
    }
  };

  changeSecondTab = async (e, key) => {
    const z = new RegExp(/^\d*\.?\d*$/);

    if (this.state.offerType <= 0) {
      toast.error("Please Select Offer Type Field", { theme: "colored" });
    } else if ($("#title").val().length <= 0) {
      toast.error("Please Fill Title Field", { theme: "colored" });
    } else if (!z.test($("#reward_amount").val()) || $("#reward_amount").val().trim().length <= 0) {
      toast.error("Please Fill Offer Value, accept only numeric values", { theme: "colored" });
    } else if ($("#description").val().trim().length <= 0) {
      toast.error("Please Fill Description field", { theme: "colored" });
    } else if ($("#min_purchase_amount").val().trim().length <= 0 || !z.test($("#min_purchase_amount").val())) {
      toast.error("Please Fill Minimum Purchase Offer, accept only numeric values", { theme: "colored" });
    } else if ($("#min_purchase_amount").val() > 10) {
      toast.error("Minimum Purchase Quantity should not have value greater than 10,", { theme: "colored" });
    } else if ($("#file-upload").val().trim().length <= 0) {
      toast.error("Please Upload Coupon Image", { theme: "colored" });
    } else if (
      $("input[type='date'][name='start_date']").val().length > 0 &&
      $("input[type='date'][name='end_date']").val().length > 0 &&
      $("input[type='date'][name='shutoff_date']").val().length > 0
    ) {
      $("#nav-step-" + key + "-tab").trigger("click").attr("aria-selected", false);
    } else if (
      new Date($("input[type='date'][name='start_date']").val()).getTime() <
      new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())).getTime()
    ) {
      $("#nav-step-" + key + "-tab").trigger("click").attr("aria-selected", false);
    } else {
      toast.error("Please Select Date", { theme: "colored" });
    }
  };

  changeThirdTab = async (e, key) => {
    $("#nav-step-" + key + "-tab").trigger("click");
  };

  changePrevTab = async (e, key) => {
    $("#nav-step-" + key + "-tab").trigger("click");
  };

  selectWholesaler = async (e, logo, id) => {
    const { wholesaler } = this.state;
    const isChecked = e.target.checked;

    this.setState({
      wholesaler: isChecked
        ? [
          ...wholesaler,
          {
            wholesaler_name: e.target.value,
            clip_limit: 1000000,
            logo,
            wholesaler_id: id,
            camp_wholesaler_start_date: this.state.global_prev_start_date,
            camp_iso_wholesaler_start_date: this.state.global_build_start_date,
            camp_wholesaler_end_date: this.state.global_prev_end_date,
            camp_iso_wholesaler_end_date: this.state.global_build_end_date,
            camp_iso_wholesaler_shutoff_date: this.state.global_build_shutoff_date || this.state.global_build_end_date,
          },
        ]
        : wholesaler.filter((item) => item.wholesaler_id !== parseInt(id)),
    });
  };

  selectRetailer = async (e, retailer_name, retailer_id, wholesaler_id, logo) => {
    const { retailer } = this.state;
    const isChecked = e.target.checked;

    this.setState({
      retailer: isChecked
        ? [
          ...retailer,
          {
            retailer_name,
            retailer_id,
            wholesaler_id,
            retailerlogo: logo,
            camp_retailer_id: retailer_id,
            camp_wholesaler_id: wholesaler_id,
            camp_retailer_start_date: this.state.global_prev_start_date,
            camp_retailer_end_date: this.state.global_prev_end_date,
            camp_iso_retailer_start_date: this.state.global_build_start_date,
            camp_iso_retailer_end_date: this.state.global_build_end_date,
            camp_iso_retailer_shutoff_date: this.state.global_build_shutoff_date || this.state.global_build_end_date,
          },
        ]
        : retailer.filter((item) => item.retailer_id !== parseInt(retailer_id)),
    });
  };

  selectRetailerGroup = async (e, retailer_group_id) => {
    if (e.target.checked) {
      this.setState((prevState) => ({
        retailerGroups: [...prevState.retailerGroups, retailer_group_id],
      }));
    } else {
      this.setState((prevState) => ({
        retailerGroups: prevState.retailerGroups.filter((id) => id !== retailer_group_id),
      }));
    }
  };

  getCouponTargetTypeId = (selectedOption) => {
    switch (selectedOption) {
      case "wholesaler":
        return 1;
      case "retailer":
        return 2;
      case "retailerGroups":
        return 2;
      default:
        return 0;
    }
  };

  updateVendorParticipation = (key, value) => {
    this.setState((prevState) => ({
      vendorParticipation: {
        ...prevState.vendorParticipation,
        [key]: value,
      },
    }));
  };

  getCouponCategory = (offerType) => {
    if (offerType === 1 || offerType === 2) {
      return 2 
    } else if (offerType === 3 || offerType === 4) {
      return 5;
    } else {
      return 0;
    }
  };

  buildCoupon = async (e) => {
    e.preventDefault();
    const { signup, offercheckbox,
      wholesaler, retailer, selectedOption,
      formControls, global_build_start_date,
      global_build_end_date, global_build_shutoff_date,
      upcArr, retailerGroups, retailerGroupsData,
      vendorParticipation, rewardUpc } = this.state;

    // if (!signup) {
    //   toast.error("Please Provide all Informations !");
    //   return;
    // }
    if (offercheckbox !== 1) {
      toast.error("Please confirm the offer");
      return;
    }

    const brands = ls.get("brands") || "";
    let Wholesaler_arr = [];
    if (selectedOption === "wholesaler") {
      Wholesaler_arr = wholesaler.map((val) => ({
        wholesaler_id: val.wholesaler_id,
        start_date: val.camp_iso_wholesaler_start_date,
        end_date: val.camp_iso_wholesaler_end_date,
        shutoff_date: val.camp_iso_wholesaler_shutoff_date,
        clip_limit: parseInt(val.camp_clip_limit_val) || 1000000,
        redeem_limit: 0,
        retailer: []
      }));
    }

    if (selectedOption === "retailerGroups") {
      Wholesaler_arr = wholesaler.map((val) => ({
        retailer: val.retailers.map((retailer) => ({
          ...retailer,
          start_date: val.camp_iso_wholesaler_start_date,
          end_date: val.camp_iso_wholesaler_end_date,
          shutoff_date: val.camp_iso_wholesaler_shutoff_date,
          clip_limit: parseInt(val.camp_clip_limit_val) || 1000000,
          redeem_limit: 0,
        })),
        wholesaler_id: val.wholesaler_id,
        start_date: val.camp_iso_wholesaler_start_date,
        end_date: val.camp_iso_wholesaler_end_date,
        shutoff_date: val.camp_iso_wholesaler_shutoff_date,
        clip_limit: parseInt(val.camp_clip_limit_val) || 1000000,
        redeem_limit: 0,
      }));
    }

    if (selectedOption === "retailer") {
      const retailer_arr = retailer.map((val) => ({
        retailer_id: val.retailer_id,
        start_date: val.camp_iso_retailer_start_date,
        end_date: val.camp_iso_retailer_end_date,
        shutoff_date: val.camp_iso_retailer_shutoff_date,
        clip_limit: val.clip_limit || 1000000,
        redeem_limit: 0,
        wholesaler_id: val.wholesaler_id
      }));

      const sorted = retailer_arr.reduce((acc, val) => {
        acc[val.wholesaler_id] = acc[val.wholesaler_id] || [];
        acc[val.wholesaler_id].push(val);
        return acc;
      }, {});

      Wholesaler_arr = Object.keys(sorted).map((wholesaler_id) => ({
        wholesaler_id: parseInt(wholesaler_id),
        start_date: sorted[wholesaler_id][0].start_date,
        end_date: sorted[wholesaler_id][0].end_date,
        shutoff_date: sorted[wholesaler_id][0].shutoff_date,
        clip_limit: 0,
        redeem_limit: 0,
        retailer: sorted[wholesaler_id]
      }));
    }

    toast.promise(new Promise((resolve) => setTimeout(resolve, 100)), {
      pending: "Processing 👌",
      success: "Processing 👌",
      error: "Processing 🤯"
    });
    const couponCategory = this.getCouponCategory(this.state.offerType);
    const data = JSON.stringify({
      title: formControls.title,
      description: formControls.description,
      offer_gs1: "",
      reward_amount: parseFloat(formControls.reward_amount) || 1,
      min_purchase_amount: 1,
      manufactuer_id: 0,
      coupon_category: couponCategory, 
      start_date: global_build_start_date,
      end_date: global_build_end_date,
      shutoff_date: global_build_shutoff_date,
      clip_limit: 0,
      redeem_limit: 0,
      clip_limit_amount: 0,
      redeem_limit_amount: 0,
      coupon_type: couponCategory === 2 ? this.state.offerType : this.state.offerType === 3 ? 1 : 2,
      reward_quantity: parseInt(formControls.reward_quantity || 0),
      min_quantity: parseInt(formControls.min_purchase_amount) || 1,
      coupon_code: this.state.slug === "fsi" ? formControls.coupon_code || "" : "",
      is_flex: formControls.coupon_limit > 1 ? 0 : 1,
      coupon_target_type_id: this.getCouponTargetTypeId(selectedOption),
      is_discount_percentage: this.state.offerType === 1 ? 0 : 1,
      max_reward_limit: 0,
      limit_per_customer: 0,
      addtional_details: formControls.additional_details || "",
      disclaimer: formControls.disclaimer || "",
      image_1: this.state.selectedFile,
      image_1_thumbnail: "string",
      media_url: "string",
      brand_guid: brands[0]?.guid,
      coupon_condition: {
        per_customer_limit: 1,
        per_transaction_limit: parseInt(formControls.coupon_limit) || 1
      },
      wholesalers: Wholesaler_arr,
      upc: upcArr.map(Number),
      retailer_groups: retailerGroups,
      vendor_participation: vendorParticipation,
      reward_upc: rewardUpc?.map(Number) || [],
    });
    
    this.setState({ subscribeButtonLoader: true }, () => {
      buildCoupon(data).then((response) => {
        this.setState({ subscribeButtonLoader: false }, () => {
          if (response.status === 200) {
            setTimeout(() => {
              window.location.href = "/confirmation/whcoupon";
            }, 2500);
          }
        });
      });
    });
  };

  render() {
    const isLogged = ls.get("token");

    if (!isLogged) {
      return <Redirect to="/login" />;
    }

    return (
      <>
        <Header location={`Wizard`} />

        {this.state.spinloading ? <Loader /> : null}

        <section className="ContentBlock pt-24 pb-24 Main_Block">
          <div className="Tab_Header ">
            <div className="container-fluid">
              <div className="row ">
                <div className="col-12">
                  <ul className="nav nav-tabs wizard pb-70">
                    <li className="active">
                      <button
                        className="nav-link "
                        id="nav-step-1-tab"
                        style={{
                          background:
                            "linear-gradient(105.16deg, #2a80c6 39.34%, #1fb3cd 83.14%) !important"
                        }}
                        data-bs-toggle="tab"
                        data-bs-target="#nav-step-1"
                        type="button"
                        role="tab"
                        aria-controls="step-1"
                        aria-selected="true"
                        onClick={() => this.setState({ step: 0 })}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21 2.992V21.008C20.9979 21.2706 20.8926 21.5219 20.7068 21.7075C20.521 21.8931 20.2696 21.9982 20.007 22H3.993C3.72981 22 3.47739 21.8955 3.2912 21.7095C3.105 21.5235 3.00027 21.2712 3 21.008V2.992C3.00209 2.72938 3.10742 2.47813 3.29322 2.29251C3.47902 2.1069 3.73038 2.00183 3.993 2H20.007C20.555 2 21 2.444 21 2.992ZM19 4H5V20H19V4ZM11.293 13.121L15.536 8.879L16.95 10.293L11.293 15.95L7.403 12.06L8.818 10.646L11.293 13.121Z"
                            fill="current"
                          />
                        </svg>
                        Select Targets
                      </button>
                    </li>

                    <li>
                      <button
                        className="nav-link"
                        id="nav-step-2-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-step-2"
                        type="button"
                        role="tab"
                        aria-controls="nav-step-2"
                        aria-selected="false"
                        style={{ display: "none" }}
                      ></button>

                      <button
                        className="nav-link"
                        type="button"
                        role="tab"
                        aria-controls="nav-step-2"
                        aria-selected="false"
                        onClick={(e) => {
                          this.setState({ step: 2 });
                          this.changeTab(e, 2);
                        }}
                      >
                        <svg
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.625 15.75C12.9288 15.75 12.2611 15.4734 11.7688 14.9812C11.2766 14.4889 11 13.8212 11 13.125C11 12.4288 11.2766 11.7611 11.7688 11.2688C12.2611 10.7766 12.9288 10.5 13.625 10.5C14.3212 10.5 14.9889 10.7766 15.4812 11.2688C15.9734 11.7611 16.25 12.4288 16.25 13.125C16.25 13.8212 15.9734 14.4889 15.4812 14.9812C14.9889 15.4734 14.3212 15.75 13.625 15.75ZM13.625 14.25C13.9234 14.25 14.2095 14.1315 14.4205 13.9205C14.6315 13.7095 14.75 13.4234 14.75 13.125C14.75 12.8266 14.6315 12.5405 14.4205 12.3295C14.2095 12.1185 13.9234 12 13.625 12C13.3266 12 13.0405 12.1185 12.8295 12.3295C12.6185 12.5405 12.5 12.8266 12.5 13.125C12.5 13.4234 12.6185 13.7095 12.8295 13.9205C13.0405 14.1315 13.3266 14.25 13.625 14.25ZM5.375 7.5C5.03028 7.5 4.68894 7.4321 4.37046 7.30018C4.05198 7.16827 3.7626 6.97491 3.51884 6.73116C3.27509 6.4874 3.08173 6.19802 2.94982 5.87954C2.8179 5.56106 2.75 5.21972 2.75 4.875C2.75 4.53028 2.8179 4.18894 2.94982 3.87046C3.08173 3.55198 3.27509 3.2626 3.51884 3.01884C3.7626 2.77509 4.05198 2.58173 4.37046 2.44982C4.68894 2.3179 5.03028 2.25 5.375 2.25C6.07119 2.25 6.73887 2.52656 7.23116 3.01884C7.72344 3.51113 8 4.17881 8 4.875C8 5.57119 7.72344 6.23887 7.23116 6.73116C6.73887 7.22344 6.07119 7.5 5.375 7.5ZM5.375 6C5.67337 6 5.95952 5.88147 6.1705 5.6705C6.38147 5.45952 6.5 5.17337 6.5 4.875C6.5 4.57663 6.38147 4.29048 6.1705 4.0795C5.95952 3.86853 5.67337 3.75 5.375 3.75C5.07663 3.75 4.79048 3.86853 4.5795 4.0795C4.36853 4.29048 4.25 4.57663 4.25 4.875C4.25 5.17337 4.36853 5.45952 4.5795 5.6705C4.79048 5.88147 5.07663 6 5.375 6ZM14.8032 2.63625L15.8638 3.69675L4.1975 15.3638L3.137 14.3032L14.8025 2.63625H14.8032Z"
                            fill="current"
                          />
                        </svg>
                        Build Offer
                      </button>
                    </li>
                    <li>
                      <button
                        className="nav-link"
                        id="nav-step-3-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-step-3"
                        type="button"
                        role="tab"
                        aria-controls="nav-step-3"
                        aria-selected="false"
                        style={{ display: "none" }}
                      ></button>

                      <button
                        className="nav-link"
                        type="button"
                        role="tab"
                        aria-controls="nav-step-3"
                        aria-selected="false"
                        onClick={(e) => {
                          this.setState({ step: 3 });
                          this.changeTab(e, 3);
                        }}
                      >
                        <svg
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.625 15.75C12.9288 15.75 12.2611 15.4734 11.7688 14.9812C11.2766 14.4889 11 13.8212 11 13.125C11 12.4288 11.2766 11.7611 11.7688 11.2688C12.2611 10.7766 12.9288 10.5 13.625 10.5C14.3212 10.5 14.9889 10.7766 15.4812 11.2688C15.9734 11.7611 16.25 12.4288 16.25 13.125C16.25 13.8212 15.9734 14.4889 15.4812 14.9812C14.9889 15.4734 14.3212 15.75 13.625 15.75ZM13.625 14.25C13.9234 14.25 14.2095 14.1315 14.4205 13.9205C14.6315 13.7095 14.75 13.4234 14.75 13.125C14.75 12.8266 14.6315 12.5405 14.4205 12.3295C14.2095 12.1185 13.9234 12 13.625 12C13.3266 12 13.0405 12.1185 12.8295 12.3295C12.6185 12.5405 12.5 12.8266 12.5 13.125C12.5 13.4234 12.6185 13.7095 12.8295 13.9205C13.0405 14.1315 13.3266 14.25 13.625 14.25ZM5.375 7.5C5.03028 7.5 4.68894 7.4321 4.37046 7.30018C4.05198 7.16827 3.7626 6.97491 3.51884 6.73116C3.27509 6.4874 3.08173 6.19802 2.94982 5.87954C2.8179 5.56106 2.75 5.21972 2.75 4.875C2.75 4.53028 2.8179 4.18894 2.94982 3.87046C3.08173 3.55198 3.27509 3.2626 3.51884 3.01884C3.7626 2.77509 4.05198 2.58173 4.37046 2.44982C4.68894 2.3179 5.03028 2.25 5.375 2.25C6.07119 2.25 6.73887 2.52656 7.23116 3.01884C7.72344 3.51113 8 4.17881 8 4.875C8 5.57119 7.72344 6.23887 7.23116 6.73116C6.73887 7.22344 6.07119 7.5 5.375 7.5ZM5.375 6C5.67337 6 5.95952 5.88147 6.1705 5.6705C6.38147 5.45952 6.5 5.17337 6.5 4.875C6.5 4.57663 6.38147 4.29048 6.1705 4.0795C5.95952 3.86853 5.67337 3.75 5.375 3.75C5.07663 3.75 4.79048 3.86853 4.5795 4.0795C4.36853 4.29048 4.25 4.57663 4.25 4.875C4.25 5.17337 4.36853 5.45952 4.5795 5.6705C4.79048 5.88147 5.07663 6 5.375 6ZM14.8032 2.63625L15.8638 3.69675L4.1975 15.3638L3.137 14.3032L14.8025 2.63625H14.8032Z"
                            fill="current"
                          />
                        </svg>
                        Select Products
                      </button>
                    </li>
                    <li>
                      <button
                        className="nav-link"
                        id="nav-step-4-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-step-4"
                        type="button"
                        role="tab"
                        aria-controls="nav-step-4"
                        aria-selected="false"
                        style={{ display: "none" }}
                      ></button>

                      <button
                        className="nav-link"
                        type="button"
                        role="tab"
                        aria-controls="nav-step-4"
                        aria-selected="false"
                        onClick={(e) => {
                          this.setState({ step: 4 });
                          this.changeTab(e, 4);
                        }}
                      >
                        <svg
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.625 15.75C12.9288 15.75 12.2611 15.4734 11.7688 14.9812C11.2766 14.4889 11 13.8212 11 13.125C11 12.4288 11.2766 11.7611 11.7688 11.2688C12.2611 10.7766 12.9288 10.5 13.625 10.5C14.3212 10.5 14.9889 10.7766 15.4812 11.2688C15.9734 11.7611 16.25 12.4288 16.25 13.125C16.25 13.8212 15.9734 14.4889 15.4812 14.9812C14.9889 15.4734 14.3212 15.75 13.625 15.75ZM13.625 14.25C13.9234 14.25 14.2095 14.1315 14.4205 13.9205C14.6315 13.7095 14.75 13.4234 14.75 13.125C14.75 12.8266 14.6315 12.5405 14.4205 12.3295C14.2095 12.1185 13.9234 12 13.625 12C13.3266 12 13.0405 12.1185 12.8295 12.3295C12.6185 12.5405 12.5 12.8266 12.5 13.125C12.5 13.4234 12.6185 13.7095 12.8295 13.9205C13.0405 14.1315 13.3266 14.25 13.625 14.25ZM5.375 7.5C5.03028 7.5 4.68894 7.4321 4.37046 7.30018C4.05198 7.16827 3.7626 6.97491 3.51884 6.73116C3.27509 6.4874 3.08173 6.19802 2.94982 5.87954C2.8179 5.56106 2.75 5.21972 2.75 4.875C2.75 4.53028 2.8179 4.18894 2.94982 3.87046C3.08173 3.55198 3.27509 3.2626 3.51884 3.01884C3.7626 2.77509 4.05198 2.58173 4.37046 2.44982C4.68894 2.3179 5.03028 2.25 5.375 2.25C6.07119 2.25 6.73887 2.52656 7.23116 3.01884C7.72344 3.51113 8 4.17881 8 4.875C8 5.57119 7.72344 6.23887 7.23116 6.73116C6.73887 7.22344 6.07119 7.5 5.375 7.5ZM5.375 6C5.67337 6 5.95952 5.88147 6.1705 5.6705C6.38147 5.45952 6.5 5.17337 6.5 4.875C6.5 4.57663 6.38147 4.29048 6.1705 4.0795C5.95952 3.86853 5.67337 3.75 5.375 3.75C5.07663 3.75 4.79048 3.86853 4.5795 4.0795C4.36853 4.29048 4.25 4.57663 4.25 4.875C4.25 5.17337 4.36853 5.45952 4.5795 5.6705C4.79048 5.88147 5.07663 6 5.375 6ZM14.8032 2.63625L15.8638 3.69675L4.1975 15.3638L3.137 14.3032L14.8025 2.63625H14.8032Z"
                            fill="current"
                          />
                        </svg>
                        Vendor Participation
                      </button>
                    </li>
                    <li>
                      <button
                        className="nav-link"
                        id="nav-step-5-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-step-5"
                        type="button"
                        role="tab"
                        aria-controls="nav-step-5"
                        aria-selected="false"
                        style={{ display: "none" }}
                      ></button>

                      <button
                        className="nav-link"
                        type="button"
                        role="tab"
                        aria-controls="nav-step-5"
                        aria-selected="false"
                        onClick={(e) => {
                          this.setState({ step: 5 });
                          this.changeTab(e, 5);
                        }}
                      >
                        <svg
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="0.8">
                            <path
                              d="M24 22H8C7.73478 22 7.48043 21.8946 7.29289 21.7071C7.10536 21.5196 7 21.2652 7 21V3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10536 7.73478 2 8 2H24C24.2652 2 24.5196 2.10536 24.7071 2.29289C24.8946 2.48043 25 2.73478 25 3V21C25 21.2652 24.8946 21.5196 24.7071 21.7071C24.5196 21.8946 24.2652 22 24 22ZM23 20V4H9V20H23ZM12 7H20V9H12V7ZM12 11H20V13H12V11ZM12 15H17V17H12V15Z"
                              fill="current"
                            />
                          </g>
                        </svg>
                        Campaign Details
                      </button>
                    </li>

                    <li>
                      <button
                        className="nav-link"
                        id="nav-step-6-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-step-6"
                        type="button"
                        role="tab"
                        aria-controls="nav-step-6"
                        aria-selected="false"
                        style={{ display: "none" }}
                      ></button>
                      <button
                        className="nav-link"
                        type="button"
                        role="tab"
                        aria-controls="nav-step-6"
                        aria-selected="false"
                        onClick={(e) => {
                          this.setState({ step: 6 });
                          this.changeThirdTab(e, 6);
                        }}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM11.003 16L6.76 11.757L8.174 10.343L11.003 13.172L16.659 7.515L18.074 8.929L11.003 16Z"
                            fill="current"
                          />
                        </svg>
                        Preview
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <form method="post" className="Offer_Form">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div
                    className="tab-content Wizard_Content mb-30"
                    id="nav-tabContent"
                  >
                    <div
                      className="tab-pane fade show active"
                      id="nav-step-1"
                      role="tabpanel"
                      aria-labelledby="nav-step-1-tab"
                    >
                      <SelectTarget
                        changeTargetTab={this.changeTargetTab}
                        changePrevTab={this.changePrevTab}
                        wholesaler={this.state.wholesaler}
                        selectWholesaler={this.selectWholesaler}
                        retailer={this.state.retailer}
                        selectRetailer={this.selectRetailer}
                        handleOptionChange={this.handleOptionChange}
                        selectedOption={this.state.selectedOption}
                        selectAll={this.selectAll}
                        select_all={this.state.select_all}
                        selectAllRetailers={this.selectAllRetailers}
                        changeTargetTabSelect={this.changeTargetTabSelect}
                        isCouponOrScan="coupon"
                        retailerGroups={this.state.retailerGroups}
                        selectRetailerGroup={this.selectRetailerGroup}
                        selectAllRetailerGroups={this.selectAllRetailerGroups}
                        updateRetailerGroupsData={this.updateRetailerGroupsData}
                      />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-step-2"
                      role="tabpanel"
                      aria-labelledby="nav-step-2-tab"
                    >
                      <BuildOffer
                        offerType={this.state.offerType}
                        reward_amount={this.state.formControls.reward_amount}
                        selectedFile={this.state.selectedFile}
                        title={this.state.formControls.title}
                        description={this.state.formControls.description}
                        onchangeOffertype={this.onchangeOffertype}
                        //onchangeFlex={this.onchangeFlex}
                        changeHandler={this.changeHandler}
                        changePrevTab={this.changePrevTab}
                        changeSecondTab={this.changeSecondTab}
                        setStartDate={this.setStartDate}
                        setEndDate={this.setEndDate}
                        setShutoffDate={this.setShutoffDate}
                        onFileChange={this.onFileChange}
                        upcArrlength={this.state.upcArrlength}
                        isCouponOrScan="coupon"
                      />
                    </div>

                    <div
                      className="tab-pane fade"
                      id="nav-step-3"
                      role="tabpanel"
                      aria-labelledby="nav-step-3-tab"
                    >
                      <CreateCoupon
                        WholeSaler={this.state.WholeSaler}
                        reward_amount={this.state.formControls.reward_amount}
                        changeStep={this.changeTab}
                        upcArrlength={this.state.upcArrlength}
                        handleUpcChange={this.handleUpcChange}
                        isCouponOrScan="coupon"
                        changePrevTab={this.changePrevTab}
                        offerType={this.state.offerType}
                      />
                    </div>

                    <div
                      className="tab-pane fade"
                      id="nav-step-4"
                      role="tabpanel"
                      aria-labelledby="nav-step-4-tab"
                    >
                      <VendorParticipation
                        isCouponOrScan="coupon"
                        upcArrlength={this.state.upcArrlength}
                        reward_amount={this.state.formControls.reward_amount}
                        selectedFile={this.state.selectedFile}
                        changeStep={this.changeVendorParticipation}
                        updateVendorParticipation={this.updateVendorParticipation}
                        vendorParticipation={this.state.vendorParticipation}
                        changePrevTab={this.changePrevTab}
                      />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-step-5"
                      role="tabpanel"
                      aria-labelledby="nav-step-5-tab"
                    >
                      <CampaignDetail
                        changeTargetTab={this.changeTargetTab}
                        selectedOption={this.state.selectedOption}
                        wholesaler={this.state.wholesaler}
                        retailer={this.state.retailer}
                        changePrevTab={this.changePrevTab}
                        camp_minDate={this.state.camp_minDate}
                        camp_maxDate={this.state.camp_maxDate}
                        camp_shutoffDate={this.state.camp_shutoffDate}
                        onchangeSetdate={this.onchangeSetdate}
                        onchangesetEndDate={this.onchangesetEndDate}
                        onchangeShutOffDate={this.onchangeShutOffDate}
                        cliplimitChange={this.cliplimitChange}
                        cliplimitChangeRetailer={this.cliplimitChangeRetailer}
                        onchangeSetdateRetailer={this.onchangeSetdateRetailer}
                        onchangeSetEnddateRetailer={
                          this.onchangeSetEnddateRetailer
                        }
                        onchangeSetShutoffdateRetailer={
                          this.onchangeSetShutoffdateRetailer
                        }
                        changeTabCompaignDetails={this.changeTabCompaignDetails}
                        retailerGroups={this.state.retailerGroups}
                        retailerGroupsData={this.state.retailerGroupsData}
                        updateWholeSalers={this.updateWholeSalers}
                        isCouponOrScan="coupon"
                        global_build_start_date={this.state.global_build_start_date}
                        global_build_end_date={this.state.global_build_end_date}
                        global_build_shutoff_date={this.state.global_build_shutoff_date}
                      />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-step-6"
                      role="tabpanel"
                      aria-labelledby="nav-step-6-tab"
                    >
                      <Preview
                        selectedFile={this.state.selectedFile}
                        title={this.state.formControls.title}
                        description={this.state.formControls.description}
                        subscribeButtonLoader={this.state.subscribeButtonLoader}
                        global_prev_start_date={
                          this.state.global_prev_start_date
                        }
                        global_prev_end_date={this.state.global_prev_end_date}
                        onchangeoffercheckbox={this.onchangeoffercheckbox}
                        buildCoupon={this.buildCoupon}
                        buildCouponretailer={this.buildCouponretailer}
                        selectedOption={this.state.selectedOption}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
        <FooterStrict />
      </>
    );
  }
}
export default WholesalerCoupon;
