import React, { useEffect, useState } from 'react';
import SelectTarget from './SelectTarget';
import BuildOffer from './BuildOffer';
import CampaignDetail from './CampaignDetail';
import Header from '../../Components/Wholesaler/layout/header';
import { useLocation } from 'react-router-dom';
import FooterStrict from '../Dashboard/FooterStrict';
import CouponProvider from './CouponProvider';
import CreateCoupon from './Products';
import VendorParticipation from './VendorParticipation';
import Preview from './Preview';
import Step from './Step';
import Stepper from './Stepper';
import BrandProducts from './BrandProducts';

const Loader = () => <div id="cover-spin"></div>;
const Coupon = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const [spinloading, setSpinLoading] = useState(false);
    const [steps, setSteps] = useState([]);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const couponType = queryParams.get('coupon_type');
    const guid = queryParams.get('guid');
    const action = queryParams.get('action');
    const provider = queryParams.get('provider');
    const isFsi = queryParams.get('isFsi') === 'true';
    
    const scanSteps = [
        { id: 1, label: 'Select Target', component: SelectTarget },
        { id: 2, label: 'Build Offer', component: BuildOffer },
        { id: 3, label: 'Select Products', component: provider === "brand" ? BrandProducts : CreateCoupon },
        { id: 4, label: 'Preview', component: Preview },
    ];

    const couponSteps = [
        { id: 1, label: 'Select Target', component: SelectTarget },
        { id: 2, label: 'Build Offer', component: BuildOffer },
        { id: 3, label: 'Select Products', component: provider === "brand" ? BrandProducts : CreateCoupon },
        { id: 4, label: 'Vendor Participation', component: VendorParticipation },
        { id: 5, label: 'Campaign Detail', component: CampaignDetail },
        { id: 6, label: 'Preview', component: Preview },
    ];

    const FSICouponSteps = [
        { id: 1, label: 'Select Target', component: SelectTarget },
        { id: 2, label: 'Build Offer', component: BuildOffer },
        { id: 3, label: 'Select Products', component: BrandProducts },
        { id: 4, label: 'Campaign Detail', component: CampaignDetail },
        { id: 5, label: 'Preview', component: Preview },
    ];

    useEffect(() => {
        if (couponType) {
            const stepsToSet = isFsi ? FSICouponSteps : (couponType === 'scan' ? scanSteps : couponSteps);
            setSteps(stepsToSet);
        }
    }, [couponType, isFsi]);

    useEffect(() => {
        renderStepComponent();
    }, [currentStep]);

    const setStep = (id) => {
        setCurrentStep(id);
    }

    const renderStepComponent = () => {
        const step = steps.find(step => step.id === currentStep);
        if (!step) return null;
        const StepComponent = step.component;
        return <StepComponent couponType={couponType} provider={provider} />;
    };

    const selectStep = (id) => {
        if (id < currentStep) {
            setCurrentStep(id);
        }
    };

    return (
        <>
            <Header location={`Wizard`} />
            {spinloading ? <Loader /> : null}
            <div>
                <CouponProvider>
                    {<Stepper guid={guid} action={action}  provider={provider} isFsi={isFsi}/>}
                    <section className="ContentBlock pt-24 pb-24 Main_Block">
                        <div className="Tab_Header ">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <ul className="nav nav-tabs wizard pb-70">
                                            {steps.map((step, index) => (
                                                // <li key={index} className={currentStep === step.id ? "active" : ""}>
                                                <li key={index} className={currentStep === step.id ? "active" : currentStep > step.id ? "completed" : "" }>
                                                    <button
                                                        className="nav-link"
                                                        id={`nav-step-${step.id}-tab`}
                                                        style={{
                                                            background:
                                                                currentStep === step.id
                                                                    ? "linear-gradient(105.16deg, #2a80c6 39.34%, #1fb3cd 83.14%) !important"
                                                                    : ""
                                                        }}
                                                        data-bs-toggle="tab"
                                                        data-bs-target={`#nav-step-${step.id}`}
                                                        type="button"
                                                        role="tab"
                                                        aria-controls={`step-${step.id}`}
                                                        aria-selected={currentStep === step.id}
                                                        onClick={() => selectStep(step.id)}
                                                    >
                                                        <svg
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M21 2.992V21.008C20.9979 21.2706 20.8926 21.5219 20.7068 21.7075C20.521 21.8931 20.2696 21.9982 20.007 22H3.993C3.72981 22 3.47739 21.8955 3.2912 21.7095C3.105 21.5235 3.00027 21.2712 3 21.008V2.992C3.00209 2.72938 3.10742 2.47813 3.29322 2.29251C3.47902 2.1069 3.73038 2.00183 3.993 2H20.007C20.555 2 21 2.444 21 2.992ZM19 4H5V20H19V4ZM11.293 13.121L15.536 8.879L16.95 10.293L11.293 15.95L7.403 12.06L8.818 10.646L11.293 13.121Z"
                                                                fill="current"
                                                            />
                                                        </svg>
                                                        {step.label}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <form method="post" className="Offer_Form">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <div
                                            className="tab-content Wizard_Content mb-30"
                                            id="nav-tabContent">
                                            <div
                                                className="tab-pane fade show active"
                                                id={`nav-step-${currentStep}`}
                                                role="tabpanel"
                                                aria-labelledby={`nav-step-${currentStep}-tab`}>
                                                {renderStepComponent(currentStep)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </section>
                    <Step currentStep={currentStep} setStep={setStep} steps={steps} couponType={couponType} />
                </CouponProvider>
            </div>
            <FooterStrict />
        </>
    );
};

export default Coupon;

