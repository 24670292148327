import React, { useContext } from 'react';
import CouponContext from './CouponContext';
import { toast } from "react-toastify";
import { Button } from 'react-bootstrap';
const Step = ({ currentStep, setStep, steps, couponType }) => {
    const { couponData, updateCouponData } = useContext(CouponContext);
    const nextStep = () => {
        const step = steps.find(step => step.id === currentStep);
        if (!step) return;
        if (!validateStep(step.label)) return;
        if (currentStep <= steps.length - 1) {
            setStep(currentStep + 1);
        }
    };

    const prevStep = () => {
        if (currentStep > 1) {
            setStep(currentStep - 1);
        }
    };

    const validateStep = (stepName) => {
        switch (stepName) {
            case 'Select Products':
                return SelectProductsValidation();
            case 'Build Offer':
                return BuildOfferValidation();
            case 'Select Target':
                return SelectTargetValidation();
            case 'Campaign Detail':
                return CampaignDetailValidation();
            case 'Vendor Participation':
                return VendorParticipationValidation();
            case 'Preview':
                return PreviewValidation();
            default:
                return true;
        }
    };

    const VendorParticipationValidation = () => {
        return true;
    }

    const SelectProductsValidation = () => {
        if (couponData.upc.length === 0) {
            toast.error("Please enter at least one UPC");
            return false;
        }
        if (couponData.offer_type > 2 && couponData.reward_upc.length === 0) {
            toast.error("Please enter at least one reward UPC");
            return false;
        }

        return true;
    }
    const BuildOfferValidation = () => {
        if (couponData.offer_type === 0) {
            toast.error("Please select Offer Type");
            return false;
        }
        if (couponData.reward_amount <= 0) {
            toast.error("Please enter a valid Offer Value greater than 0");
            return false;
        }
        if (couponData.isFsi && couponData.coupon_code === "") {
            toast.error("Please enter Coupon Code");
            return false;
        }
        if (couponData.title === "") {
            toast.error("Please enter Offer Title");
            return false;
        }
        if (couponData.description === "") {
            toast.error("Please enter Description");
            return false;
        }
        if (couponType === "coupon") {
            if (couponData.min_quantity <= 0 || couponData.min_quantity > 10) {
                toast.error("Minimum Purchase Quantity should be between 1 and 10");
                return false;
            }
            if ((couponData.offer_type === 3 || couponData.offer_type === 4) && couponData.reward_quantity <= 0 || couponData.reward_quantity > 10) {
                toast.error("Reward Quantity should be between 1 and 10");
                return false;
            }
        }

        if (couponData.start_date === "") {
            toast.error("Please enter Start Date");
            return false;
        }
        if (couponData.end_date === "") {
            toast.error("Please enter End Date");
            return false;
        }
        if (new Date(couponData.end_date) <= new Date(couponData.start_date)) {
            toast.error("End Date should be greater than Start Date");
            return false;
        }
        if (couponData.shutoff_date === "") {
            toast.error("Please enter Shutoff Date");
            return false;
        }
        if (new Date(couponData.shutoff_date) <= new Date(couponData.start_date) || new Date(couponData.shutoff_date) > new Date(couponData.end_date)) {
            toast.error("Shutoff Date should be between Start Date and End Date");
            return false;
        }
        if (couponData.image_1 === "") {
            toast.error("Please Upload Coupon Image");
            return false;
        }
        return true;
    }
    const SelectTargetValidation = () => {
        if (couponData.coupon_target_type_id === 0) {
            toast.error("Please select Target");
            return false;
        }
        if (couponData.coupon_target_type_id === 1 && couponData.wholesalers.length === 0) {
            toast.error("Please select at least one Wholesaler");
            return false;
        }
        if (couponData.coupon_target_type_id === 2 && couponData.retailers.length === 0) {
            toast.error("Please select at least one Retailer");
            return false;
        }
        if (couponData.coupon_target_type_id === 3 && couponData.retailer_groups.length === 0) {
            toast.error("Please select at least one Retailer Group");
            return false;
        }
        return true;
    }
    const CampaignDetailValidation = () => {
        return true;
    }
    const PreviewValidation = () => {
        return true;
    }

    return (
        <div className="d-flex align-items-center justify-content-end">
            <div className="TableButton">
                {currentStep !== steps[0]?.id && (
                    <button
                        type="button"
                        className="mr-10 btn btn-primary"
                        onClick={prevStep} disabled={currentStep === steps[0]?.id}
                    >
                        <i className="fas fa-arrow-left mr-10" aria-hidden="true"></i> Prev
                    </button>
                )}
                {currentStep !== steps.length && (
                    <Button onClick={nextStep} >
                        Next{" "}
                        <i className="fas fa-arrow-right ml-10"></i>{" "}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default Step;