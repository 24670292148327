import React, { useEffect, useState } from 'react';
import Header from "../../Components/Wholesaler/layout/header.js";
import { Link } from "react-router-dom";
import { getListOfRetailerGroups, deleteRetailerGroup } from "../../API/Wholesalerapi.js";
import { toast } from 'react-toastify';

const Loader = () => <div id="cover-spin"></div>;

const RetailerGroup = () => {
    const [retailerGroups, setRetailerGroups] = useState([]);
    const [spinloading, setSpinloading] = useState(false);

    const showLoader = () => setSpinloading(true);
    const hideLoader = () => setSpinloading(false);

    useEffect(() => {
        showLoader();
        getListOfRetailerGroups()
            .then(response => {
                if (response && response.data) {
                    setRetailerGroups(response.data.Message.groups);
                }
                hideLoader();
            })
            .catch(error => {
                console.error("Error fetching retailer groups:", error);
                hideLoader();
            });
    }, []);

    const handleDeleteRetailerGroup = (e, guid) => {
        e.preventDefault();
        const confirmed = window.confirm("Are you sure you want to delete this retailer group?");
        if (!confirmed) return;

        showLoader();
        deleteRetailerGroup(guid)
            .then(response => {
                if (response && response.status === 200) {
                    setRetailerGroups(prevGroups => prevGroups.filter(group => group.retailer_group_id !== guid));
                    toast.success("Retailer group deleted successfully");
                } else {
                    console.error("Error deleting retailer group:", response.data?.Message);
                    toast.error("Failed to delete retailer group");
                }
                hideLoader();
            })
            .catch(error => {
                console.error("Error deleting retailer group:", error);
                toast.error("Failed to delete retailer group");
                hideLoader();
            });
    };

    return (
        <React.Fragment>
            <Header />
            <div className="ContentBlock pt-40 pb-24 Main_Block" style={{ padding: "20px" }}>
                <div className="row g-3 retailer-group-wrapper">
                    <div className="col-xxl-12 col-xl-12 col-12">
                        <div className="group-btn">
                            <Link to="/create-group" className="btn Add_User_Button mt-20 mb-20" style={{ margin: "3px" }}>
                                Create New Group
                            </Link>
                        </div>
                        <div className="Common_Block">
                            <div className="Common_Block_Header d-flex align-items-center justify-content-between">
                                <h5>Retailer Group</h5>
                            </div>
                            {spinloading && <Loader />}
                            <div className="Common_Block_Body pt-17 pb-17 pr-14 pl-14 Build_Offer_Block">
                                <div className="retailer-group">
                                    {retailerGroups.map((group, index) => (
                                        <div className="group-card" key={index}>
                                            <div className="group-header">
                                                <Link
                                                    to={{
                                                        pathname: `/create-group`,
                                                        search: `?${new URLSearchParams({
                                                            retailer_group_id: `${group.retailer_group_id}`
                                                        })}`,
                                                    }}
                                                >
                                                    <div className="edit-icon">
                                                        <svg width="20" height="20" viewBox="0 0 36 36">
                                                            <path fill="currentColor" d="M33.87 8.32L28 2.42a2.07 2.07 0 0 0-2.92 0L4.27 23.2l-1.9 8.2a2.06 2.06 0 0 0 2 2.5a2 2 0 0 0 .43 0l8.29-1.9l20.78-20.76a2.07 2.07 0 0 0 0-2.92M12.09 30.2l-7.77 1.63l1.77-7.62L21.66 8.7l6 6ZM29 13.25l-6-6l3.48-3.46l5.9 6Z" />
                                                            <path fill="none" d="M0 0h36v36H0z" />
                                                        </svg>
                                                    </div>
                                                </Link>
                                                <div className="delete-icon" onClick={e => handleDeleteRetailerGroup(e, group.retailer_group_id)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M7 21q-.825 0-1.412-.587T5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.587 1.413T17 21zM17 6H7v13h10zM9 17h2V8H9zm4 0h2V8h-2zM7 6v13z" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="group-content">
                                                <p>{group.group_name}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default RetailerGroup;
