import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../Assets/images/logo-small.png";
import BuyOnline from "../../Assets/images/buy-online.svg";
import Digitalcoupons from "../../Assets/images/digital-coupons.svg";
import Insights from "../../Assets/images/insights.svg";
import NearBy from "../../Assets/images/nearby.svg";
import Arrow from "../../Assets/images/arrow.svg";
import MediaSupport from "../../Assets/images/media-support.svg";

import { Button, Form, Spinner } from "react-bootstrap";

import * as CONFIG from "../../Components/_config";
import * as XLSX from "xlsx";
import axios from "axios";
import { Tabs, Tab } from "react-bootstrap";
import RV from "simple-react-validator";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Country, State } from "country-state-city";
import $ from "jquery";

const HEADERS_MAP = new Map();
HEADERS_MAP.set(0, "UPC");
HEADERS_MAP.set(1, "PRODUCTNAME");
HEADERS_MAP.set(2, "CATEGORY");
HEADERS_MAP.set(3, "DO NOT REMOVE THIS ROW");

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new RV();
    this.validator.visibleFields = [];
    toast.configure();

    this.state = {
      upclist: "",
      upcname: "",
      product_name: "",
      currentTab: "1",
      secondtab: false,
      thirdtab: false,
      signup: true,
      isLoading: false,
      States: "",
      excelError: false,
      excellengthError: false,
      upc11Arr: "",
      headerError: false,
      file: "https://my-brandiqpub.s3.amazonaws.com/UPC.xlsx",

      formControls: {
        brand_name: "",
        fein: "",
        company_name: "",
        address: "",
        postal_code: "",
        city: "",
        country_state: "",
        country: "",
        website: "",
        email: "",
        primary_phone: "",
        upcdata: "",
        selectedFile: null,
        upc: ""
      }
    };
    this.brandRef = React.createRef();
    this.companyRef = React.createRef();
    this.myRef = React.createRef();
    this.stateRef = React.createRef();
    this.cityRef = React.createRef();
    this.zipcodeRef = React.createRef();
    this.websiteRef = React.createRef();

    this.firstnameRef = React.createRef();
    this.lastnameRef = React.createRef();

    this.emailRef = React.createRef();
    this.phoneRef = React.createRef();

    this.upcRef = React.createRef();
    this.termsRef = React.createRef();
  }

  componentDidMount() {
    const co = Country.getCountryByCode("US");
    const states = State.getStatesOfCountry(co.isoCode);
    this.setState({ States: states });
  }

  checkHeader(sheet) {
    HEADERS_MAP.forEach((value, index) => {
      var cell = sheet[XLSX.utils.encode_cell({ c: index, r: 0 })];
      var header = "";
      if (cell && cell.t) {
        header = XLSX.utils.format_cell(cell);
        if (value !== header) {
          console.log(value);
        }
      }
    });
  }

  validateMissingHeaders = (sheet) => {
    var missingHeaders = [];
    const errors = [];
    HEADERS_MAP.forEach((value, index) => {
      var cell = sheet[XLSX.utils.encode_cell({ c: index, r: 0 })]

      var header = "";
      if (typeof cell === 'undefined') {
        header = XLSX.utils.format_cell(cell);
        if (value !== header) {
          missingHeaders.push(value);
        }
      }
    });
    if (missingHeaders.length !== 0) {

      errors.push({
        row: "Missing Headers",
        column: missingHeaders,
      });


    }
    return errors;
  }

  readExcel = (file) => {
    let upc11arr = [];

    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        //this.checkHeader(ws)

        // HEADERS_MAP.forEach((value, index) => {
        //   var cell = ws[XLSX.utils.encode_cell({ c: index, r: 0 })];

        //   var header = "";
        //   if (cell && cell.t) {
        //     header = XLSX.utils.format_cell(cell);
        //     if (value !== header) {
        //       // console.log(value)
        //     }
        //   }
        // });
        const errors = this.validateMissingHeaders(ws)


        if (errors.length > 0) {
          errors.forEach((error) => {

            toast.error(` ${`Header column ` + error.column[0] + ` is missing`}`)

          })
          return false;

        }
        else {

          const data = XLSX.utils.sheet_to_json(ws);
          resolve(data);
        }
        // const data = XLSX.utils.sheet_to_json(ws);
        // resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {

      d.map((item, index) => {
        return (
          (!!(item.UPC)) ?
            ''
            :
            d.splice(index, 1)
        )
      })


      if (Object.hasOwn(d[0], "UPC")) {

        this.setState({ upc11Arr: "" });
        if (d.every((v) => typeof v.UPC === "number") === false) {
          this.setState({ excelError: true });
          return false;
        }

        else if (d.every((v) => v.UPC.toString().length < 10)) {
          this.setState({ excellengthError: true });
          return false;
        }
        else {
          d.map((item) => {

            return upc11arr.push({ 'UPC': item.UPC, 'product_name': item.PRODUCTNAME, 'category': item.CATEGORY });
          });

        }

      } else {

        this.setState({ headerError: true });
        //console.log("not working");
      }

      this.setState({ upc11Arr: upc11arr });
    });
  };

  handlefirstTabChange = (value) => {

    if (!this.validator.allValid()) {
      this.validator.showMessages();

      // console.log(this.validator.getErrorMessages())

      if (!this.validator.fieldValid("Brand")) {
        this.brandRef.current.scrollIntoView();
      } else if (!this.validator.fieldValid("Company")) {
        this.companyRef.current.scrollIntoView();
      } else if (!this.validator.fieldValid("Address")) {
        this.myRef.current.scrollIntoView();
      } else if (!this.validator.fieldValid("City")) {
        this.cityRef.current.scrollIntoView();
      } else if (!this.validator.fieldValid("State")) {
        this.stateRef.current.scrollIntoView();
      } else if (!this.validator.fieldValid("Zipcode")) {
        this.zipcodeRef.current.scrollIntoView();
      } else if (!this.validator.fieldValid("Website")) {
        this.websiteRef.current.scrollIntoView();
      }

      this.forceUpdate();
      return false;
    } else {
      this.setState({
        currentTab: value,
        secondtab: true
      });
    }
  };

  handlesecondTabChange = (value) => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();

      // var Objmsg = this.validator.getErrorMessages();

      if (!this.validator.fieldValid("First Name")) {
        this.firstnameRef.current.scrollIntoView();
      } else if (!this.validator.fieldValid("Last Name")) {
        this.lastnameRef.current.scrollIntoView();
      } else if (!this.validator.fieldValid("Email")) {
        this.emailRef.current.scrollIntoView();
      } else if (!this.validator.fieldValid("Phone")) {
        this.phoneRef.current.scrollIntoView();
      }
      this.forceUpdate();
      return false;
    } else {
      this.setState({
        currentTab: value,
        thirdtab: true
      });
    }
  };

  handleChange = (value) => {
    this.setState({
      currentTab: value
    });
  };
  changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      formControls: {
        ...this.state.formControls,
        [name]: value
      }
    });
  };

  onFileChange = (event) => {
    let files = event.target.files;
    let images = event.target.files[0];

    if (!images.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
      toast.error("Upload Valid Image. Only PNG,JPEG and JPG are allowed.");
      return false;
    }

    for (var i = 0; i < files.length; i++) {
      let file = files[i];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.setState({ selectedFile: reader.result });
      };
    }
  };

  componentDidUpdate() {
    if (this.state.excelError === true) {

      toast.error("UPCs must be integer");

      $("#formFileSm").val("");

      this.setState({ excelError: false });
    }
    if (this.state.excellengthError === true) {
      toast.error("UPCs must be with a minimum length of 10.");
      $("#formFileSm").val("");

      this.setState({ excellengthError: false });
    }
    if (this.state.headerError === true) {

      toast.error("The excel file can't open due to header error. In header UPC is not set ");

      $("#formFileSm").val("");

      this.setState({ headerError: false });
    }
  }

  Signup = async (e) => {
    e.preventDefault();
    if (!this.validator.allValid()) {
      //toast.error(this.validator.showMessages())
      toast.error("Please Fill all Fields");

      this.validator.showMessages();

      if (!this.validator.fieldValid("UPC")) {
        this.upcRef.current.scrollIntoView();
      } else if (!this.validator.fieldValid("Terms & Condition")) {
        this.termsRef.current.scrollIntoView();
      }
      this.forceUpdate();
      return false;
    } else {

      const upcdata = [];
      if (this.state.upc11Arr.length > 0) {
        this.state.upc11Arr.map((val, idx) => {
          if (val.UPC.toString().length < 11) {
            if ((typeof val.product_name == 'undefined') && (typeof val.category == 'undefined')) {
              upcdata.push({
                upc11: "0".repeat(11 - val.UPC.toString().length) + val.UPC.toString(),
                product_name: "0".repeat(11 - val.UPC.toString().length) + val.UPC.toString(),
                category: "",
              });
            }
            else if (typeof val.product_name == 'undefined') {
              upcdata.push({
                upc11: "0".repeat(11 - val.UPC.toString().length) + val.UPC.toString(),
                product_name: "0".repeat(11 - val.UPC.toString().length) + val.UPC.toString(),
                category: val.category.toString(),
              });
            }
            else if (typeof val.category == 'undefined') {
              upcdata.push({
                upc11: "0".repeat(11 - val.UPC.toString().length) + val.UPC.toString(),
                product_name: val.product_name.toString(),
                category: "",
              });
            }
            else {
              upcdata.push({
                upc11: "0".repeat(11 - val.UPC.toString().length) + val.UPC.toString(),
                product_name: val.product_name.toString(),
                category: val.category.toString(),
              });
            }
          } else {
            this.setState({
              isLoading: true
            })
            if ((typeof val.product_name == 'undefined') && (typeof val.category == 'undefined')) {
              upcdata.push({
                upc11: val.UPC.toString(),
                product_name: "0" + val.UPC.toString(),
                category: ""
              });
            }
            else if (typeof val.product_name == 'undefined') {
              upcdata.push({
                upc11: val.UPC.toString(),
                product_name: "0" + val.UPC.toString(),
                category: val.category.toString()
              });
            }
            else if (typeof val.category == 'undefined') {
              upcdata.push({
                upc11: val.UPC.toString(),
                product_name: val.product_name.toString(),
                category: ""
              });
            }
            else {
              upcdata.push({
                upc11: val.UPC.toString(),
                product_name: val.product_name.toString(),
                category: val.category.toString(),
              });
            }
          }
        });

        const resolveAfter3Sec = new Promise((resolve) =>
          setTimeout(resolve, 1000)
        );

        toast.promise(resolveAfter3Sec, {
          pending: "Processing 👌",
          success: "Processing 👌",
          error: "Processing 🤯"
        });

        var data = JSON.stringify({
          name: this.state.formControls.company_name.replace(/'/g, ""),
          fein: this.state.formControls.fein,
          industry_code: this.state.formControls.company_name.replace(
            /[&\/\\#, +()$~%.'":*?<>{}]/g,
            ""
          ),
          nac_code: "",
          address: this.state.formControls.address.replace(/'/g, ""),
          address2: "",
          postal_code: this.state.formControls.postal_code,
          city: this.state.formControls.city.replace(/'/g, ""),
          country_state: this.state.formControls.country_state,
          country: "USA",
          website: this.state.formControls.website,
          logo: this.state.selectedFile ? this.state.selectedFile : "String", //this.state.selectedFile
          state: 0,
          brand: {
            name: this.state.formControls.brand_name.replace(/'/g, ""),
            address: this.state.formControls.address.replace(/'/g, ""),
            address2: "",
            postal_code: this.state.formControls.postal_code,
            city: this.state.formControls.city.replace(/'/g, ""),
            country_state: this.state.formControls.country_state,
            country: "USA",
            website: this.state.formControls.website,
            logo: this.state.selectedFile ? this.state.selectedFile : "String", //this.state.selectedFile
            contact: {
              first_name: this.state.formControls.first_name,
              last_name: this.state.formControls.last_name,
              email: this.state.formControls.email,
              primary_phone: this.state.formControls.primary_phone,
              secondary_phone: ""
            },
            wholesalers: [],
            upc: upcdata
          }
        });

        var config = {
          method: "post",
          url: CONFIG.API_URL + "company",
          headers: CONFIG.config_head.headers,
          data: data
        };

        axios(config)
          .then((response) => {
            if (response.status === 201) {
              this.setState({
                isLoading: false,
              },
                () => {
                  toast.success("Email Sent Successfully. Signup Successful");
                  window.location.href = "/LogIn";
                }
              )
            } else {
              toast.error("Login Unsuccessful,Please try again!");
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 502) {
                this.setState({
                  isLoading: false,
                },
                  () => {
                    setTimeout(() => { toast.error("Bad Request, Please Try Again!") }, 2500)

                  }
                )
              } else if (error.response.status === 400) {
                this.setState({
                  isLoading: false,
                },
                  () => {
                    setTimeout(() => { toast.error(error.response.data.Message) }, 2500)
                  }
                )

              } else {

                this.setState({
                  isLoading: false,
                },
                  () => {
                    setTimeout(() => { toast.error(error.response.data.Message) }, 2500)

                  }
                )
              }
            }
          });
      } else {
        toast.error(
          "UPC file is not uploaded. Please upload a valid UPC file."
        );
      }
    }
  };

  render() {

    return (
      <div className="FormBlock">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-12">
              <div className="Logo pt-21">
                <img src={Logo} className="img-fluid" alt="Logo" />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className=" MainBlock pb-20">
            <div className="row  align-items-center justify-content-between">
              <div className="col-xl-4 col-lg-6 order-lg-1 order-2 pr-100">
                <div className="LeftBlock">
                  <h2 className="">Fueled by Real Time Trusted Data</h2>
                  <h4 className="mt-10">
                    Drive Velocity Digitally with ML & AI{" "}
                  </h4>
                  <ul className="Data_Listing mt-63">
                    <li>
                      <img
                        src={Digitalcoupons}
                        className="img-fluid"
                        alt="Product"
                      />
                      <span>Digital Coupons</span>
                    </li>
                    <li>
                      <img
                        src={BuyOnline}
                        className="img-fluid"
                        alt="Product"
                      />
                      <span>Buy Online</span>
                    </li>
                    <li>
                      <img
                        src={MediaSupport}
                        className="img-fluid"
                        alt="Product"
                      />
                      <span>Digital Media Support</span>
                    </li>
                    <li>
                      <img src={Insights} className="img-fluid" alt="Product" />
                      <span>Insights</span>
                    </li>
                    <li>
                      <img src={NearBy} className="img-fluid" alt="Product" />
                      <span>Forecasting and Personalization</span>
                    </li>
                  </ul>
                  <ul className="CommonLink mt-85 d-flex align-items-center">
                    <li>
                      <a href="https://rsaamerica.com/terms/" target={"blank"}>
                        Terms
                      </a>
                    </li>
                    <li>
                      <a href="https://rsaamerica.com/privacy-policy/">
                        Privacy
                      </a>
                    </li>
                    <li>
                      <Link to="">Home</Link>
                    </li>
                    {/* <li>
                      <Link to="/helpcentre">Support Center</Link>
                    </li> */}

                  </ul>
                </div>
              </div>
              <div className="col-xl-5 col-lg-6 col-12 order-lg-2 order-1 pl-140">
                <div className="Form_OuterBlock pt-23 pb-34  ">
                  <div className="Form_Header">
                    <div className="StepInfo">
                      <p>Registration</p>
                      <span>Step 1</span>
                    </div>
                    <h5>
                      Welcome To <span>BrandIQ</span><sub>ai</sub>
                    </h5>
                    <div className="d-flex align-items-center justify-content-between HeaderTtl">
                      <h2>Sign Up</h2>
                      <Link to="/LogIn" className="">
                        Sign In
                      </Link>
                    </div>
                  </div>

                  <form
                    method="POST"
                    className="mt-35"
                    encType="multipart/form-data"
                  >
                    <Tabs
                      className="pl-34"
                      activeKey={this.state.currentTab}
                      id="controlled-tab-example"
                      onSelect={() => this.handleChange()}
                    >
                      <Tab eventKey={1} title="Brand Info">
                        <div
                          className="tab-pane fade show active"
                          id="home"
                          role="tabpanel"
                          aria-labelledby="home-tab"
                        >
                          <div className="InputField CustomScroll ">
                            <div className="row">
                              <div className="col-12">
                                <Form.Group className="form-group">
                                  <div id="Brand" ref={this.brandRef}></div>

                                  <Form.Label>Brand Name*</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Brand Name*"
                                    name="brand_name"
                                    onChange={this.changeHandler}
                                    onBlur={() =>
                                      this.validator.showMessageFor(
                                        "brand_name"
                                      )
                                    }
                                  />

                                  <samp className="text-danger">
                                    {this.validator.message(
                                      "Brand",
                                      this.state.formControls.brand_name,
                                      `required`
                                    )}
                                  </samp>
                                </Form.Group>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6 col-12 mt-16">
                                <Form.Group className="form-group">
                                  <div id="Company" ref={this.companyRef}></div>

                                  <Form.Label>Company Name*</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Company Name*"
                                    name="company_name"
                                    onChange={this.changeHandler}
                                  />

                                  <samp className="text-danger">
                                    {this.validator.message(
                                      "Company",
                                      this.state.formControls.company_name,
                                      `required`
                                    )}
                                  </samp>
                                </Form.Group>
                              </div>
                              <div className="col-lg-6 col-12 mt-16">
                                <Form.Group className="form-group">
                                  <Form.Label>FEIN</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter FEIN"
                                    name="fein"
                                    onChange={this.changeHandler}
                                  />
                                </Form.Group>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 mt-16">
                                <Form.Group className="form-group">
                                  <div id="Address" ref={this.myRef}></div>

                                  <Form.Label>Address*</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Your Address*"
                                    as="textarea"
                                    rows={2}
                                    name="address"
                                    onChange={this.changeHandler}
                                  />
                                  {/* <Link className="text-end d-block mt-4"> Need Address :2</Link> */}

                                  <samp className="text-danger">
                                    {this.validator.message(
                                      "Address",
                                      this.state.formControls.address,
                                      `required`
                                    )}
                                  </samp>
                                </Form.Group>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 mt-16">
                                <Form.Group className="form-group">
                                  <Form.Label>City*</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter City*"
                                    name="city"
                                    onChange={this.changeHandler}
                                  />
                                  <div id="City" ref={this.cityRef}></div>

                                  <samp className="text-danger">
                                    {this.validator.message(
                                      "City",
                                      this.state.formControls.city,
                                      `required`
                                    )}
                                  </samp>
                                </Form.Group>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6 col-12 mt-16">
                                <Form.Label>State*</Form.Label>
                                {this.state.States ? (
                                  <Form.Select
                                    name="country_state"
                                    onChange={this.changeHandler}
                                  >
                                    <option>Select please</option>

                                    {this.state.States.map((val, idx) => (
                                      <option>{val.name}</option>
                                    ))}
                                  </Form.Select>
                                ) : (
                                  ""
                                )}
                                <div id="State" ref={this.stateRef}></div>

                                <samp className="text-danger">
                                  {this.validator.message(
                                    "State",
                                    this.state.formControls.country_state,
                                    `required`
                                  )}
                                </samp>
                              </div>
                              <div className="col-lg-6 col-12 mt-16">
                                <Form.Group className="form-group">
                                  <Form.Label>Postal Code*</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Zipcode*"
                                    name="postal_code"
                                    onChange={this.changeHandler}
                                  />
                                  <div id="Zipcode" ref={this.zipcodeRef}></div>

                                  <samp className="text-danger">
                                    {this.validator.message(
                                      "Zipcode",
                                      this.state.formControls.postal_code,
                                      `required|numeric|min:5|max:5`
                                    )}
                                  </samp>
                                </Form.Group>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 mt-16">
                                <Form.Group className="form-group">
                                  <Form.Label>Website*</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Brand Website*"
                                    name="website"
                                    onChange={this.changeHandler}
                                  />

                                  <div id="Website" ref={this.websiteRef}></div>

                                  <samp className="text-danger">
                                    {this.validator.message(
                                      "Website",
                                      this.state.formControls.website,
                                      `required|url`
                                    )}
                                  </samp>
                                </Form.Group>
                              </div>
                            </div>
                          </div>
                          <div className="pl-34 pr-34 Button_Outer_Block">
                            <Button
                              variant=""
                              type="button"
                              className="mt-48 Btn_gray"
                              onClick={() => this.handlefirstTabChange("2")}
                            >
                              Next
                              <img
                                src={Arrow}
                                className="img-fluid float-end"
                                alt="Arrow"
                              />
                            </Button>
                          </div>
                        </div>
                      </Tab>

                      <Tab eventKey={2} title="Contact Info">
                        <div className="InputField CustomScroll">
                          <div className="row">
                            <div className="col-lg-6 col-12 mt-16">
                              <div id="firstname" ref={this.firstnameRef}></div>

                              <Form.Group className="form-group">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Name*"
                                  name="first_name"
                                  onChange={this.changeHandler}
                                />

                                {this.state.secondtab === true ? (
                                  <samp className="text-danger">
                                    {this.validator.message(
                                      "First Name",
                                      this.state.formControls.first_name,
                                      `required`
                                    )}
                                  </samp>
                                ) : (
                                  ""
                                )}
                              </Form.Group>
                            </div>
                            <div className="col-lg-6 col-12 mt-16">
                              <Form.Group className="form-group">
                                <div id="lastname" ref={this.lastnameRef}></div>

                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Name*"
                                  name="last_name"
                                  onChange={this.changeHandler}
                                />
                                {this.state.secondtab === true ? (
                                  <samp className="text-danger">
                                    {this.validator.message(
                                      "Last Name",
                                      this.state.formControls.last_name,
                                      `required`
                                    )}
                                  </samp>
                                ) : (
                                  ""
                                )}
                              </Form.Group>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 mt-16">
                              <Form.Group className="form-group">
                                <Form.Label>Job Title</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Job Title*"
                                  name="brand_job_title"
                                  onChange={this.changeHandler}
                                />
                              </Form.Group>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 mt-16">
                              <div id="email" ref={this.emailRef}></div>

                              <Form.Group className="form-group">
                                <Form.Label>Email (work)</Form.Label>
                                <Form.Control
                                  type="mail"
                                  placeholder="Enter Email Id*"
                                  name="email"
                                  onChange={this.changeHandler}
                                />

                                {this.state.secondtab === true ? (
                                  <samp className="text-danger">
                                    {this.validator.message(
                                      "Email",
                                      this.state.formControls.email,
                                      `required|email`
                                    )}
                                  </samp>
                                ) : (
                                  ""
                                )}
                              </Form.Group>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 mt-16">
                              <div id="phone" ref={this.phoneRef}></div>

                              <Form.Group className="form-group">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Phone Number*"
                                  name="primary_phone"
                                  onChange={this.changeHandler}
                                  onBlur={() =>
                                    this.validator.showMessageFor(
                                      "primary_phone"
                                    )
                                  }
                                />
                                {this.state.secondtab === true ? (
                                  <samp className="text-danger">
                                    {this.validator.message(
                                      "Phone",
                                      this.state.formControls.primary_phone,
                                      `required|numeric|min:10|max:10`
                                    )}
                                  </samp>
                                ) : (
                                  ""
                                )}
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                        <div className="pl-34 pr-34 Button_Outer_Block">
                          <Button
                            variant=""
                            type="button"
                            className="mt-48 Btn_gray"
                            onClick={() => this.handlesecondTabChange(3)}
                          >
                            Next
                            <img
                              src={Arrow}
                              className="img-fluid float-end"
                              alt="Arrow"
                            />
                          </Button>
                        </div>
                      </Tab>


                      <Tab eventKey={3} title="UPCs">
                        <div className="InputField CustomScroll">
                          {/* <div className='row'>
                                                        <div className='col-12 mt-16 d-flex '>
                                                      
                                                      
                                                            <Form.Group className="form-group" >
                                                                <Form.Label> Upload UPCs</Form.Label>
                                                                <br />
                                                                <Form.Control type="text" placeholder="Enter UPCs*" as="textarea" rows={6} name='upc' onChange={this.changeHandler} />
                                                        <strong style={{fontSize:'11px'}}>*Please Enter UPCs as Comma Separated Values.</strong>

                                                                 {this.state.thirdtab == true ?

                                                                    <samp className='text-danger'>
                                                                        {this.validator.message('UPC', this.state.formControls.upc, `required`)}
                                                                    </samp>
                                                                    : ''
                                                                } *
                                                             </Form.Group>

                                                            <div className='UploadButton ml-10 mt-30'>
                                                                

                                                            </div>
                                                        </div>
                                                    </div> */}

                          <div className="row">
                            <div className="col-12 mt-16 d-flex ">
                              <Form.Group className="form-group">
                                <Form.Label> Upload Brand Logo *</Form.Label>
                                <br />

                                <input
                                  type="file"
                                  onChange={this.onFileChange}
                                  onClick={(event) => {
                                    event.target.value = null;
                                  }}
                                />
                              </Form.Group>
                              <div className="UploadButton ml-10 mt-30"></div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-12 mt-16 d-flex "></div>
                          </div>

                          <div className="row">
                            <div className="col-12 mt-16 ">
                              <Form.Group className="form-group">
                                <Form.Label> Upload UPCs *</Form.Label>
                                <br />

                                <input
                                  id="formFileSm"
                                  type="file"
                                  onChange={(e) => {
                                    const file = e.target.files[0];
                                    this.readExcel(file);
                                  }}
                                  name="upc"
                                  onClick={(event) => {
                                    event.target.value = null;
                                  }}
                                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                ></input>
                              </Form.Group>
                              <br />

                              <a
                                href={this.state.file}
                                target="_blank"
                                rel="noopener noreferrer"
                                download
                                style={{
                                  color: "#0d6ef",
                                  textDecoration: "underline",
                                  marginTop: "4px"
                                }}
                              >
                                Click this link to download sample UPC file.
                              </a>

                              <div id="upc" ref={this.upcRef}></div>

                              {/* {this.state.thirdtab == true ?

                                                                    <samp className='text-danger'>
                                                                        {this.validator.message('UPC', this.state.formControls.upc, `required`)}
                                                                    </samp>
                                                                    : ''
                                                                }
                                                              */}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-12 mt-16 d-flex "></div>
                          </div>

                          <div className="row">
                            <div className=" col-12 mt-16">
                              <div id="terms" ref={this.termsRef}></div>

                              <Form.Group
                                className="mb-3"
                                id="formGridCheckbox"
                              >
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="check1"
                                    name="option1"
                                    onChange={this.changeHandler}
                                  />
                                  <label className="form-check-label">
                                    I agree to{" "}
                                    <a
                                      href="https://rsaamerica.com/terms/"
                                      target={"blank"}
                                    >
                                      {" "}
                                      terms & conditions.
                                    </a>{" "}
                                  </label>
                                </div>
                                {this.state.thirdtab === true ? (
                                  <samp className="text-danger">
                                    {this.validator.message(
                                      "Terms & Condition",
                                      this.state.formControls.option1,
                                      `required`
                                    )}
                                  </samp>
                                ) : (
                                  ""
                                )}
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                        <div
                          className="pl-34 pr-34 Button_Outer_Block"
                          style={{ paddingBottom: "15px" }}
                        >
                          <Button
                            variant=""
                            type="submit"
                            className="mt-48 Btn_blue"
                            disabled={this.state.isLoading}
                            onClick={(e) => {
                              this.Signup(e);
                            }}
                          >
                            {
                              this.state.isLoading ?
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  className={"float-end"}
                                /> :
                                <img
                                  src={Arrow}
                                  className="img-fluid float-end"
                                  alt="Arrow"
                                />
                            }
                            {this.state.isLoading ? "Submitting..." : "Submit"}
                          </Button>
                        </div>
                      </Tab>
                    </Tabs>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SignUp;
