import ProductsImage from "../../Assets/images/products.svg";
import OfferValueImage from "../../Assets/images/offer-value.svg";
import React from "react";

const ScanSummary = ({ upcArrlength, reward_amount }) => {

    return (

        <React.Fragment>

            <div className="Common_Block">
                <div className="Common_Block_Header d-flex align-items-center justify-content-between">
                    <h5 className="">
                        Scan Summary
                    </h5>
                </div>
                <div className="Common_Block_Body">
                    <ul className="Campaign_Summery">
                        <li>
                            <img
                                src={ProductsImage}
                                className="img-fluid"
                                alt="Products"
                            />
                            <span>Products</span>
                            <h5>{upcArrlength}</h5>
                        </li>
                        <li>
                            <img
                                src={OfferValueImage}
                                className="img-fluid"
                                alt="Scan Value"
                            />
                            <span>Scan Value</span>
                            <h5>
                                {reward_amount}
                            </h5>
                        </li>
                    </ul>
                </div>
            </div>



        </React.Fragment>

    )
}

export default ScanSummary;