import React from "react";
import Logo from "../../Assets/images/logo.png";
import ProductMoment from "../../Assets/images/product.svg";
import BuyOnline from "../../Assets/images/buy-online.svg";
import Digitalcoupons from "../../Assets/images/digital-coupons.svg";
import Insights from "../../Assets/images/insights.svg";
import NearBy from "../../Assets/images/nearby.svg";
//import Eye from "../../Assets/images/eye.svg";
import $ from "jquery";

import ResetPasswordSvg from "../../Assets/images/resetpassword.svg";
import Arrow from "../../Assets/images/arrow.svg";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";

import { toast } from "react-toastify";
import * as CONFIG from "../../Components/_config";
import axios from "axios";
import RV from "simple-react-validator";
import "react-toastify/dist/ReactToastify.css";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new RV();
    this.validator.visibleFields = [];
    toast.configure();

    this.state = {
      formControls: {
        email: ""
      }
    };
  }
  changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      formControls: {
        ...this.state.formControls,
        [name]: value
      }
    });
  };

  Forgotpassword = async (e) => {
    e.preventDefault();

    if (!this.validator.allValid()) {
      this.validator.showMessages();

      //toast.error("Please fill email fields.")
      this.forceUpdate();
      return false;
    } else {
      var data = JSON.stringify({
        email: this.state.formControls.email
      });

      var config = {
        method: "post",
        url: CONFIG.API_URL + "user/forgot-password",
        headers: CONFIG.config_head.headers,
        data: data
      };

      axios(config)
        .then(function (res) {
           if (res.status === 200) {
            $("#succesModal").trigger("click");
          }
        })
        .catch(function (error) {
          if (error.response.status === 502) {
            toast.error("Bad Request, Please Try Again!");
          } else if (error.response.status === 400) {
            toast.error(error.response.data.Message);
          } else {
            toast.error(error.response.data.Message);
          }
        });
    }
  };

  render() {
    return (
      <div className="FormBlock">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-12">
              <div className="Logo pt-21 pb-21">
                <img src={Logo} className="img-fluid" alt="Logo" />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className=" MainBlock pb-40">
            <div className="row  align-items-center justify-content-between">
              <div className=" col-xl-4 col-lg-5 order-lg-1 order-2 pr-100">
                <div className="LeftBlock">
                  <h2 className="">Fueled by Real Time Trusted Data</h2>
                  <h4 className="mt-10">
                    Drive Velocity Digitally with ML & AI{" "}
                  </h4>

                  <ul className="Data_Listing mt-63">
                    <li>
                      <img
                        src={ProductMoment}
                        className="img-fluid"
                        alt="Product"
                      />
                      <span>Product Movement</span>
                    </li>
                    <li>
                      <img
                        src={BuyOnline}
                        className="img-fluid"
                        alt="Product"
                      />
                      <span>Buy Online</span>
                    </li>
                    <li>
                      <img
                        src={Digitalcoupons}
                        className="img-fluid"
                        alt="Product"
                      />
                      <span>Digital Coupons</span>
                    </li>
                    <li>
                      <img src={Insights} className="img-fluid" alt="Product" />
                      <span>Insights</span>
                    </li>
                    <li>
                      <img src={NearBy} className="img-fluid" alt="Product" />
                      <span>Forecasting and Personalization</span>
                    </li>
                  </ul>
                  <ul className="CommonLink mt-85 d-flex align-items-center">
                    <li>
                      <a href="https://rsaamerica.com/terms/" target={"blank"}>
                        Terms
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://rsaamerica.com/privacy-policy/"
                        target={"blank"}
                      >
                        Privacy
                      </a>
                    </li>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    {/* <li>
                      <a href="/helpcentre">Support Center</a>
                    </li> */}
                 
                  </ul>
                </div>
              </div>

              <div className=" col-xl-5 col-lg-6 col-12 order-lg-2 order-1 pl-140">
                <div className="Form_OuterBlock pt-54 pb-59  ">
                  <div className="Form_Header">
                    <Link to="/LogIn" className="Back_Link">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.828 7.00005H16V9.00005H3.828L9.192 14.364L7.778 15.778L0 8.00005L7.778 0.222046L9.192 1.63605L3.828 7.00005Z"
                          fill="current"
                        />
                      </svg>
                      Back
                    </Link>
                    <h2>Reset password</h2>
                    <p className="HeaderInfo mt-16">
                      Enter the email associated with your account and we’ll
                      send an email with instructions to reset your password.
                    </p>
                    <div className="text-center mt-30 mb-30">
                      <img
                        src={ResetPasswordSvg}
                        className="img-fluid"
                        alt="Reset Password"
                      />
                    </div>
                  </div>
                  <Form className="mt-16">
                    <div className="InputField CustomScroll ">
                      <Form.Group className="form-group" controlId="email">
                        <Form.Label>Enter register email address</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter email*"
                          name="email"
                          onChange={this.changeHandler}
                        />

                        <samp className="text-danger">
                          {this.validator.message(
                            "Email",
                            this.state.formControls.email,
                            `required|email`
                          )}
                        </samp>
                      </Form.Group>
                    </div>
                    <div className="pl-34 pr-34 Button_Outer_Block">
                      <Link to="/CheckYourMail">
                        <Button
                          variant=""
                          type="submit"
                          className="mt-48 Btn_blue"
                          onClick={(e) => {
                            this.Forgotpassword(e);
                          }}
                        >
                          Send
                          <img
                            src={Arrow}
                            className="img-fluid float-end"
                            alt="Arrow"
                          />
                        </Button>
                      </Link>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button
          className="btn btn-success"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdropsuccess"
          id="succesModal"
          style={{ display: "none" }}
        >
          Alert
        </button>

        <div
          className="modal fade Confirmation_Modal"
          id="staticBackdropsuccess"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="text-center mt-30 mb-30">
                  <img
                    src={ResetPasswordSvg}
                    className="img-fluid"
                    alt="Reset Password"
                  />
                </div>
                <h4 className="mt-20 fw-bold text-center">Password Reset </h4>
                <p className="mt-10 text-center">Email Sent Successfully..</p>
              </div>
              <div className="modal-footer">
                {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}

                <a href="/Login">
                  {" "}
                  <button type="button" className="btn btn-secondary">
                    Login
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
