import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../Assets/images/logo-small.png";
import Avtar from "../../../Assets/images/avtar-6.png";
import ls from "local-storage";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SecondHeader from "../SecondHeader/SecondHeader.js";
import RedemptionModal from "../../RedemptionModal/RedemptionModal";

class Header extends React.Component {
  constructor(props) {
    super(props);
    toast.configure();
    this.state = {
      brandinfo: "",
      show: false
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  handleClose = () => {
    this.setState({ show: false })
  }
  handleShow = () => {
    this.setState({ show: true })
  }
  logoutHandler = (e) => {
    ls.clear("token");
    ls.clear("brands");
    localStorage.removeItem("selectedbrand")

    ls.clear("selectedbrand");
    localStorage.removeItem("footerDate")
    //this.props.history.push('/login')
    window.location.href = "/login";
  };

  onchangeBrands = (e) => {
    this.props.changebrandDashboard(e);
  }
  onchangeBrandswizard = (e) => {
    this.props.changebrandWizard(e);
  }
  onchangeBrandUpclist = (e) => {
    this.props.changebrandUpclist(e);
  }
  render() {
    const brands = ls.get('brands');
    const decodetoken = ls.get("decodetoken");
    const selectedbrand = ls.get("selectedbrand") ? ls.get("selectedbrand") : [];


    return (
      <>
        <header className="Header">
          <div className="Main_Header">
            <nav className="Navbar  ">
              <div className="container-fluid">

                <div className="d-flex align-items-center justify-content-between">
                  {brands[0].scopes.includes('wholesaler:user') ? (
                    <Link className="Navbar-Brand" to="/wholesalerlist">
                      <img src={Logo} className="img-fluid" alt="Logo" />
                    </Link>
                  ) : (
                    <Link className="Navbar-Brand" to="/dashboard">
                      <img src={Logo} className="img-fluid" alt="Logo" />
                    </Link>
                  )}

                  <ul className="Navbar_Nav d-flex align-items-center">
                    {/* <li>
                      <Link to="/Notification" className="Notification_Icon"><i className="far fa-bell"></i></Link>
                    </li> */}

                    <li key={2}>
                      {/* <Link className="Navbar_Nav_Link" to="/helpcenterdetails">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.5 14.2499H13.5V6.86769L9 2.77719L4.5 6.86769V14.2499ZM14.25 15.7499H3.75C3.55109 15.7499 3.36032 15.6709 3.21967 15.5303C3.07902 15.3896 3 15.1989 3 14.9999V8.24994H0.75L8.49525 1.20894C8.63333 1.0833 8.81331 1.01367 9 1.01367C9.18669 1.01367 9.36667 1.0833 9.50475 1.20894L17.25 8.24994H15V14.9999C15 15.1989 14.921 15.3896 14.7803 15.5303C14.6397 15.6709 14.4489 15.7499 14.25 15.7499ZM5.625 9.74994H7.125C7.125 10.2472 7.32254 10.7241 7.67417 11.0758C8.02581 11.4274 8.50272 11.6249 9 11.6249C9.49728 11.6249 9.97419 11.4274 10.3258 11.0758C10.6775 10.7241 10.875 10.2472 10.875 9.74994H12.375C12.375 10.645 12.0194 11.5035 11.3865 12.1364C10.7535 12.7694 9.89511 13.1249 9 13.1249C8.10489 13.1249 7.24645 12.7694 6.61351 12.1364C5.98058 11.5035 5.625 10.645 5.625 9.74994Z"
                            fill="current"
                          />
                        </svg>

                        <span>Support Center</span>
                      </Link> */}
                    </li>

                    <li className="dropdown Profile_Dropdown" key={3}>
                      <Link
                        className="Navbar_Nav_Link dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <svg
                          width="12"
                          height="15"
                          viewBox="0 0 12 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.3337 14.6666H10.0003V13.3333C10.0003 12.8029 9.78961 12.2942 9.41454 11.9191C9.03946 11.544 8.53076 11.3333 8.00032 11.3333H4.00032C3.46989 11.3333 2.96118 11.544 2.58611 11.9191C2.21104 12.2942 2.00033 12.8029 2.00033 13.3333V14.6666H0.666992V13.3333C0.666992 12.4492 1.01818 11.6014 1.6433 10.9763C2.26842 10.3511 3.11627 9.99996 4.00032 9.99996H8.00032C8.88438 9.99996 9.73223 10.3511 10.3573 10.9763C10.9825 11.6014 11.3337 12.4492 11.3337 13.3333V14.6666ZM6.00032 8.66663C5.47504 8.66663 4.95489 8.56316 4.46959 8.36214C3.98429 8.16113 3.54333 7.86649 3.1719 7.49505C2.80046 7.12362 2.50583 6.68266 2.30481 6.19736C2.10379 5.71206 2.00033 5.19191 2.00033 4.66663C2.00033 4.14134 2.10379 3.62119 2.30481 3.13589C2.50583 2.65059 2.80046 2.20963 3.1719 1.8382C3.54333 1.46676 3.98429 1.17213 4.46959 0.971108C4.95489 0.770089 5.47504 0.666626 6.00032 0.666626C7.06119 0.666626 8.07861 1.08805 8.82875 1.8382C9.5789 2.58834 10.0003 3.60576 10.0003 4.66663C10.0003 5.72749 9.5789 6.74491 8.82875 7.49505C8.07861 8.2452 7.06119 8.66663 6.00032 8.66663ZM6.00032 7.33329C6.70757 7.33329 7.38585 7.05234 7.88594 6.55224C8.38604 6.05215 8.66699 5.37387 8.66699 4.66663C8.66699 3.95938 8.38604 3.2811 7.88594 2.78101C7.38585 2.28091 6.70757 1.99996 6.00032 1.99996C5.29308 1.99996 4.6148 2.28091 4.11471 2.78101C3.61461 3.2811 3.33366 3.95938 3.33366 4.66663C3.33366 5.37387 3.61461 6.05215 4.11471 6.55224C4.6148 7.05234 5.29308 7.33329 6.00032 7.33329Z"
                            fill="current"
                          />
                        </svg>
                        <span>{decodetoken.first_name.toUpperCase()}</span>
                      </Link>
                      {
                        !!selectedbrand ?

                          brands[0].scopes[0] === 'all' || selectedbrand.length > 0 ?
                            <ul
                              className="dropdown-menu "
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li key={4}>
                                <Link
                                  className="dropdown-item"
                                  to={`/profile/${decodetoken.user_guid}`}
                                >
                                  <svg
                                    width="19"
                                    height="19"
                                    viewBox="0 0 19 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M14.8337 5.83337H19.0003V7.50004H14.8337V5.83337ZM13.167 10H19.0003V11.6667H13.167V10ZM15.667 14.1667H19.0003V15.8334H15.667V14.1667ZM0.666992 18.3334C0.666992 16.5653 1.36937 14.8696 2.61961 13.6193C3.86986 12.3691 5.56555 11.6667 7.33366 11.6667C9.10177 11.6667 10.7975 12.3691 12.0477 13.6193C13.2979 14.8696 14.0003 16.5653 14.0003 18.3334H12.3337C12.3337 17.0073 11.8069 15.7355 10.8692 14.7978C9.93151 13.8602 8.65974 13.3334 7.33366 13.3334C6.00758 13.3334 4.73581 13.8602 3.79813 14.7978C2.86044 15.7355 2.33366 17.0073 2.33366 18.3334H0.666992ZM7.33366 10.8334C4.57116 10.8334 2.33366 8.59587 2.33366 5.83337C2.33366 3.07087 4.57116 0.833374 7.33366 0.833374C10.0962 0.833374 12.3337 3.07087 12.3337 5.83337C12.3337 8.59587 10.0962 10.8334 7.33366 10.8334ZM7.33366 9.16671C9.17533 9.16671 10.667 7.67504 10.667 5.83337C10.667 3.99171 9.17533 2.50004 7.33366 2.50004C5.49199 2.50004 4.00033 3.99171 4.00033 5.83337C4.00033 7.67504 5.49199 9.16671 7.33366 9.16671Z"
                                      fill="current"
                                    />
                                  </svg>
                                  Account
                                </Link>
                              </li>

                              {brands[0].scopes === "all" ? (
                                ""
                              ) : (
                                <li key={5}>
                                  {/* <Link className="dropdown-item" to={`/upclist`}>
                                    <svg
                                      width="19"
                                      height="19"
                                      viewBox="0 0 19 19"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M14.8337 5.83337H19.0003V7.50004H14.8337V5.83337ZM13.167 10H19.0003V11.6667H13.167V10ZM15.667 14.1667H19.0003V15.8334H15.667V14.1667ZM0.666992 18.3334C0.666992 16.5653 1.36937 14.8696 2.61961 13.6193C3.86986 12.3691 5.56555 11.6667 7.33366 11.6667C9.10177 11.6667 10.7975 12.3691 12.0477 13.6193C13.2979 14.8696 14.0003 16.5653 14.0003 18.3334H12.3337C12.3337 17.0073 11.8069 15.7355 10.8692 14.7978C9.93151 13.8602 8.65974 13.3334 7.33366 13.3334C6.00758 13.3334 4.73581 13.8602 3.79813 14.7978C2.86044 15.7355 2.33366 17.0073 2.33366 18.3334H0.666992ZM7.33366 10.8334C4.57116 10.8334 2.33366 8.59587 2.33366 5.83337C2.33366 3.07087 4.57116 0.833374 7.33366 0.833374C10.0962 0.833374 12.3337 3.07087 12.3337 5.83337C12.3337 8.59587 10.0962 10.8334 7.33366 10.8334ZM7.33366 9.16671C9.17533 9.16671 10.667 7.67504 10.667 5.83337C10.667 3.99171 9.17533 2.50004 7.33366 2.50004C5.49199 2.50004 4.00033 3.99171 4.00033 5.83337C4.00033 7.67504 5.49199 9.16671 7.33366 9.16671Z"
                                        fill="current"
                                      />
                                    </svg>
                                    UPC List
                                  </Link> */}
                                </li>
                              )}
                              <li key={6}>
                                <Link
                                  className="dropdown-item"
                                  to="/"
                                  data-bs-toggle="modal"
                                  data-bs-target="#staticBackdropheader"
                                >
                                  <svg
                                    width="16"
                                    height="18"
                                    viewBox="0 0 16 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1.33318 15.6666V10.6666C1.33318 8.89852 2.03556 7.20282 3.2858 5.95258C4.53604 4.70234 6.23173 3.99996 7.99984 3.99996C9.76795 3.99996 11.4636 4.70234 12.7139 5.95258C13.9641 7.20282 14.6665 8.89852 14.6665 10.6666V15.6666H15.4998V17.3333H0.499844V15.6666H1.33318ZM2.99984 15.6666H12.9998V10.6666C12.9998 9.34054 12.4731 8.06877 11.5354 7.13109C10.5977 6.19341 9.32593 5.66663 7.99984 5.66663C6.67376 5.66663 5.40199 6.19341 4.46431 7.13109C3.52663 8.06877 2.99984 9.34054 2.99984 10.6666V15.6666ZM7.16651 0.666626H8.83318V3.16663H7.16651V0.666626ZM14.4815 3.00663L15.6598 4.18496L13.8932 5.95246L12.714 4.77413L14.4815 3.00663ZM0.339844 4.18496L1.51818 3.00663L3.28568 4.77329L2.10818 5.95329L0.339844 4.18496ZM3.83318 10.6666C3.83318 9.56156 4.27216 8.50175 5.05357 7.72035C5.83497 6.93895 6.89477 6.49996 7.99984 6.49996V8.16663C7.3368 8.16663 6.70092 8.43002 6.23208 8.89886C5.76324 9.3677 5.49984 10.0036 5.49984 10.6666H3.83318Z"
                                      fill="current"
                                    />
                                  </svg>
                                  Notifications
                                </Link>
                              </li>
                              <li key={7}>
                                <Link
                                  className="dropdown-item"
                                  to="/retailer-group">
                                    <svg
                                    width="19"
                                    height="19"
                                    viewBox="0 0 19 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M14.8337 5.83337H19.0003V7.50004H14.8337V5.83337ZM13.167 10H19.0003V11.6667H13.167V10ZM15.667 14.1667H19.0003V15.8334H15.667V14.1667ZM0.666992 18.3334C0.666992 16.5653 1.36937 14.8696 2.61961 13.6193C3.86986 12.3691 5.56555 11.6667 7.33366 11.6667C9.10177 11.6667 10.7975 12.3691 12.0477 13.6193C13.2979 14.8696 14.0003 16.5653 14.0003 18.3334H12.3337C12.3337 17.0073 11.8069 15.7355 10.8692 14.7978C9.93151 13.8602 8.65974 13.3334 7.33366 13.3334C6.00758 13.3334 4.73581 13.8602 3.79813 14.7978C2.86044 15.7355 2.33366 17.0073 2.33366 18.3334H0.666992ZM7.33366 10.8334C4.57116 10.8334 2.33366 8.59587 2.33366 5.83337C2.33366 3.07087 4.57116 0.833374 7.33366 0.833374C10.0962 0.833374 12.3337 3.07087 12.3337 5.83337C12.3337 8.59587 10.0962 10.8334 7.33366 10.8334ZM7.33366 9.16671C9.17533 9.16671 10.667 7.67504 10.667 5.83337C10.667 3.99171 9.17533 2.50004 7.33366 2.50004C5.49199 2.50004 4.00033 3.99171 4.00033 5.83337C4.00033 7.67504 5.49199 9.16671 7.33366 9.16671Z"
                                      fill="current"
                                    />
                                  </svg>
                                  Retailer Group
                                </Link>
                              </li>
                              <li key={8}>
                                <Link
                                  className="dropdown-item"
                                  onClick={this.logoutHandler}

                                >
                                  <svg
                                    width="16"
                                    height="18"
                                    viewBox="0 0 16 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M0.699416 8.25055C0.646173 4.13324 3.94055 0.752551 8.05785 0.699308C12.1752 0.646065 15.5559 3.94044 15.6091 8.05775C15.6249 9.21522 15.3706 10.3604 14.8664 11.4024C14.3621 12.4444 13.6218 13.3544 12.7043 14.0602L12.6782 12.0399C13.4261 11.1692 13.9069 10.1012 14.0629 8.96406C14.2189 7.82688 14.0435 6.66884 13.5577 5.6289C13.0718 4.58896 12.2962 3.7113 11.324 3.10123C10.3517 2.49116 9.22403 2.1746 8.0763 2.18954C6.92857 2.20448 5.80953 2.55028 4.85345 3.18544C3.89738 3.82061 3.1449 4.71816 2.68629 5.77039C2.22769 6.82262 2.08244 7.98483 2.26799 9.11757C2.45354 10.2503 2.962 11.3054 3.73236 12.1563L3.75848 14.1766C2.8229 13.4947 2.05926 12.604 1.52822 11.5753C0.997185 10.5466 0.713401 9.40817 0.699416 8.25055V8.25055ZM5.2398 13.4111L7.47625 13.3822L7.39913 7.4183L8.8901 7.39902L8.96722 13.3629L11.2037 13.334L8.26994 17.1L5.2398 13.4111Z"
                                      fill="current"
                                    />
                                  </svg>
                                  logout{" "}
                                </Link>
                              </li>
                            </ul>
                            : selectedbrand?.length === 0 ?
                              <ul
                                className="dropdown-menu "
                                aria-labelledby="dropdownMenuButton1"
                              >
                                <li key={7}>
                                  <Link
                                    className="dropdown-item"
                                    // onClick={this.logoutHandler}
                                    onClick={this.handleShow}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      height="18"
                                      viewBox="0 -960 960 960"
                                      width="16"
                                      fill="none"
                                    >
                                      <path
                                        d="M481-323 256-547l66-65 113 114v-324h91v324l113-114 66 65-224 224ZM230-139q-37.175 0-64.087-26.913Q139-192.825 139-230v-143h91v143h500v-143h92v143q0 37-27.206 64T730-139H230Z"
                                        fill="current"
                                      />
                                    </svg>
                                    Redemption Summary{" "}
                                  </Link>
                                </li>
                                <li key={8}>
                                  <Link
                                    className="dropdown-item"
                                    onClick={this.logoutHandler}
                                  >
                                    <svg
                                      width="16"
                                      height="18"
                                      viewBox="0 0 16 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M0.699416 8.25055C0.646173 4.13324 3.94055 0.752551 8.05785 0.699308C12.1752 0.646065 15.5559 3.94044 15.6091 8.05775C15.6249 9.21522 15.3706 10.3604 14.8664 11.4024C14.3621 12.4444 13.6218 13.3544 12.7043 14.0602L12.6782 12.0399C13.4261 11.1692 13.9069 10.1012 14.0629 8.96406C14.2189 7.82688 14.0435 6.66884 13.5577 5.6289C13.0718 4.58896 12.2962 3.7113 11.324 3.10123C10.3517 2.49116 9.22403 2.1746 8.0763 2.18954C6.92857 2.20448 5.80953 2.55028 4.85345 3.18544C3.89738 3.82061 3.1449 4.71816 2.68629 5.77039C2.22769 6.82262 2.08244 7.98483 2.26799 9.11757C2.45354 10.2503 2.962 11.3054 3.73236 12.1563L3.75848 14.1766C2.8229 13.4947 2.05926 12.604 1.52822 11.5753C0.997185 10.5466 0.713401 9.40817 0.699416 8.25055V8.25055ZM5.2398 13.4111L7.47625 13.3822L7.39913 7.4183L8.8901 7.39902L8.96722 13.3629L11.2037 13.334L8.26994 17.1L5.2398 13.4111Z"
                                        fill="current"
                                      />
                                    </svg>
                                    logout{" "}
                                  </Link>
                                </li>
                              </ul>
                              :

                              ''


                          :
                          <ul
                            className="dropdown-menu "
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li key={4}>
                              <Link
                                className="dropdown-item"
                                to={`/wholesaler-profile/${decodetoken.user_guid}`}
                              >

                                <svg
                                  width="19"
                                  height="19"
                                  viewBox="0 0 19 19"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M14.8337 5.83337H19.0003V7.50004H14.8337V5.83337ZM13.167 10H19.0003V11.6667H13.167V10ZM15.667 14.1667H19.0003V15.8334H15.667V14.1667ZM0.666992 18.3334C0.666992 16.5653 1.36937 14.8696 2.61961 13.6193C3.86986 12.3691 5.56555 11.6667 7.33366 11.6667C9.10177 11.6667 10.7975 12.3691 12.0477 13.6193C13.2979 14.8696 14.0003 16.5653 14.0003 18.3334H12.3337C12.3337 17.0073 11.8069 15.7355 10.8692 14.7978C9.93151 13.8602 8.65974 13.3334 7.33366 13.3334C6.00758 13.3334 4.73581 13.8602 3.79813 14.7978C2.86044 15.7355 2.33366 17.0073 2.33366 18.3334H0.666992ZM7.33366 10.8334C4.57116 10.8334 2.33366 8.59587 2.33366 5.83337C2.33366 3.07087 4.57116 0.833374 7.33366 0.833374C10.0962 0.833374 12.3337 3.07087 12.3337 5.83337C12.3337 8.59587 10.0962 10.8334 7.33366 10.8334ZM7.33366 9.16671C9.17533 9.16671 10.667 7.67504 10.667 5.83337C10.667 3.99171 9.17533 2.50004 7.33366 2.50004C5.49199 2.50004 4.00033 3.99171 4.00033 5.83337C4.00033 7.67504 5.49199 9.16671 7.33366 9.16671Z"
                                    fill="current"
                                  />
                                </svg>
                                Account
                              </Link>
                            </li>

                            {/*<li key={5}>
                              <Link className="dropdown-item" to={`/companyupc`}>
                                <svg
                                  width="19"
                                  height="19"
                                  viewBox="0 0 19 19"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M14.8337 5.83337H19.0003V7.50004H14.8337V5.83337ZM13.167 10H19.0003V11.6667H13.167V10ZM15.667 14.1667H19.0003V15.8334H15.667V14.1667ZM0.666992 18.3334C0.666992 16.5653 1.36937 14.8696 2.61961 13.6193C3.86986 12.3691 5.56555 11.6667 7.33366 11.6667C9.10177 11.6667 10.7975 12.3691 12.0477 13.6193C13.2979 14.8696 14.0003 16.5653 14.0003 18.3334H12.3337C12.3337 17.0073 11.8069 15.7355 10.8692 14.7978C9.93151 13.8602 8.65974 13.3334 7.33366 13.3334C6.00758 13.3334 4.73581 13.8602 3.79813 14.7978C2.86044 15.7355 2.33366 17.0073 2.33366 18.3334H0.666992ZM7.33366 10.8334C4.57116 10.8334 2.33366 8.59587 2.33366 5.83337C2.33366 3.07087 4.57116 0.833374 7.33366 0.833374C10.0962 0.833374 12.3337 3.07087 12.3337 5.83337C12.3337 8.59587 10.0962 10.8334 7.33366 10.8334ZM7.33366 9.16671C9.17533 9.16671 10.667 7.67504 10.667 5.83337C10.667 3.99171 9.17533 2.50004 7.33366 2.50004C5.49199 2.50004 4.00033 3.99171 4.00033 5.83337C4.00033 7.67504 5.49199 9.16671 7.33366 9.16671Z"
                                    fill="current"
                                  />
                                </svg>
                                UPC List
                              </Link>
                            </li> */}

                            <li key={7}>
                              <Link
                                className="dropdown-item"
                                // onClick={this.logoutHandler}
                                onClick={this.handleShow}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  height="18"
                                  viewBox="0 -960 960 960"
                                  width="16"
                                  fill="none"
                                >
                                  <path
                                    d="M481-323 256-547l66-65 113 114v-324h91v324l113-114 66 65-224 224ZM230-139q-37.175 0-64.087-26.913Q139-192.825 139-230v-143h91v143h500v-143h92v143q0 37-27.206 64T730-139H230Z"
                                    fill="current"
                                  />
                                </svg>
                                Redemption Summary{" "}
                              </Link>
                            </li>
                            <li key={8}>
                              <Link
                                className="dropdown-item"
                                onClick={this.logoutHandler}
                              >
                                <svg
                                  width="16"
                                  height="18"
                                  viewBox="0 0 16 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M0.699416 8.25055C0.646173 4.13324 3.94055 0.752551 8.05785 0.699308C12.1752 0.646065 15.5559 3.94044 15.6091 8.05775C15.6249 9.21522 15.3706 10.3604 14.8664 11.4024C14.3621 12.4444 13.6218 13.3544 12.7043 14.0602L12.6782 12.0399C13.4261 11.1692 13.9069 10.1012 14.0629 8.96406C14.2189 7.82688 14.0435 6.66884 13.5577 5.6289C13.0718 4.58896 12.2962 3.7113 11.324 3.10123C10.3517 2.49116 9.22403 2.1746 8.0763 2.18954C6.92857 2.20448 5.80953 2.55028 4.85345 3.18544C3.89738 3.82061 3.1449 4.71816 2.68629 5.77039C2.22769 6.82262 2.08244 7.98483 2.26799 9.11757C2.45354 10.2503 2.962 11.3054 3.73236 12.1563L3.75848 14.1766C2.8229 13.4947 2.05926 12.604 1.52822 11.5753C0.997185 10.5466 0.713401 9.40817 0.699416 8.25055V8.25055ZM5.2398 13.4111L7.47625 13.3822L7.39913 7.4183L8.8901 7.39902L8.96722 13.3629L11.2037 13.334L8.26994 17.1L5.2398 13.4111Z"
                                    fill="current"
                                  />
                                </svg>
                                logout{" "}
                              </Link>
                            </li>
                          </ul>
                      }


                    </li>
                  </ul>
                </div>
              </div>
            </nav>
            <RedemptionModal show={this.state.show} handleClose={this.handleClose} handleShow={this.handleShow} />

          </div>

          <SecondHeader Avtar={Avtar} decodetoken={decodetoken} brands={brands}

            changeBrandsheader={this.onchangeBrands}

            onchangeBrandswizard={this.onchangeBrandswizard}

            onchangeBrandUpclist={this.onchangeBrandUpclist}

            title={this.props.title}
            changeallBrands={this.props.onchangeallBrands}

            location={this.props.location} />
        </header>

        <div
          className="modal fade Confirmation_Modal"
          id="staticBackdropheader"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <i className="fas fa-exclamation-triangle text-center "></i>
                <h4 className="mt-20 fw-bold text-center">
                  Not available for your current subscription.
                </h4>
                {/* <p className='mt-10 text-center'>You want to expire this selected coupon.</p> */}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>


        {/* BEGIN: VIEW MODAL */}
        <div
          className="modal fade Confirmation_Modal"
          id="ViewModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="ViewModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered ">
            <div className="modal-content">
              <div className="modal-body p-4">
                <h4 className="fw-bold text-center">Access Denied</h4>
                <p className="mt-10 text-center">
                  You are not authorized to perform this task.
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* END: VIEW MODAL */}

        {/* BEGIN: INACTIVE MODAL */}
        <div
          className="modal fade Confirmation_Modal"
          id="inactivemodal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="ViewModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered ">
            <div className="modal-content">
              <div className="modal-body p-4">
                <h4 className="fw-bold text-center">Not yet active</h4>
                <p className="mt-10 text-center">
                  .
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* END: INACTIVE MODAL */}
      </>

    );
  }
}
export default Header;
