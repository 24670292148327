import ProductsImage from "../../Assets/images/products.svg";
import OfferValueImage from "../../Assets/images/offer-value.svg";
import React, { useContext } from "react";
import CouponContext from "./CouponContext";

const CouponSummary = ({couponType}) => {
    const { couponData, updateCouponData } = useContext(CouponContext);
    return (
        <React.Fragment>
            <div className="Common_Block">
                <div className="Common_Block_Header d-flex align-items-center justify-content-between">
                    <h5>Digital Coupon Campaign Summary</h5>
                </div>
                <div className="Common_Block_Body">
                    <ul className="Campaign_Summery">
                        <SummaryItem
                            imageSrc={ProductsImage}
                            altText="Products"
                            label="Products"
                            value={couponData.upc?.filter(upc => upc).length || 0}
                        />
                        <SummaryItem
                            imageSrc={OfferValueImage}
                            altText={couponType === "scan" ? "Scan Offer Value" : "Offer Value"}
                            label={couponType === "scan" ? "Scan Offer Value" : "Offer Value"}
                            value={couponData.reward_amount}
                        />
                    </ul>
                </div>
            </div>
        </React.Fragment>
    );
};

const SummaryItem = ({ imageSrc, altText, label, value }) => (
    <li>
        <img src={imageSrc} className="img-fluid" alt={altText} />
        <span>{label}</span>
        <h5>{value}</h5>
    </li>
);

export default CouponSummary;