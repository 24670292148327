import React, { useState } from 'react';
import CouponContext from './CouponContext';

const CouponProvider = ({ children }) => {
  const [couponData, setCouponData] = useState({
    addtional_details: "",
    brand_guid: "",
    clip_limit: 0,
    clip_limit_amount: 0,
    confirm_offer: 0,
    coupon_category: 2,
    coupon_code: "",
    coupon_condition: {
      per_customer_limit: 1,
      per_transaction_limit: 1
    },
    coupon_target_type_id: 0,
    coupon_type: 1,
    description: "",
    disclaimer: "",
    end_date: "",
    fixed_amount: 0,
    image_1: "",
    image_1_thumbnail: "",
    is_discount_percentage: 0,
    is_flex: 0,
    limit_per_customer: 0,
    manufactuer_id: 0,
    max_reward_limit: 0,
    media_url: "",
    min_purchase_amount: 1,
    min_quantity: 1,
    offer_gs1: "",
    offer_type: 0,
    per_redemption: 0,
    per_scan: 0,
    redeem_limit: 0,
    redeem_limit_amount: 0,
    retailers: [],
    retailer_groups: [],
    reward_amount: 0,
    reward_quantity: 0,
    shutoff_date: "",
    start_date: "",
    submitting_offer: false,
    title: "",
    upc: [],
    wholesalers: [],
    reward_upc: [],
    selectedIds: [],
    selectAll: false,
    action: "",
    guid: "",
    coupon_status: 0,
    provider: "",
    isFsi: false,
    coupon_limit: 1
  });

  const updateCouponData = (updatedFields) => {
    setCouponData((prevData) => ({
      ...prevData,
      ...updatedFields,
    }));
  };

  return (
    <CouponContext.Provider value={{ couponData, updateCouponData }}>
      {children}
    </CouponContext.Provider>
  );
};

export default CouponProvider;
