import React from "react";
import Header from "../../Pages/Dashboard/Header";
import WhHeader from "../../Components/Wholesaler/layout/header";

import CardImage from "../../Assets/images/card.svg";
import { Link, useParams } from "react-router-dom";

import FooterStrict from "../../Pages/Dashboard/FooterStrict";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";


const ConfirmationMessage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const coupon_type = queryParams.get('coupon_type'); 
  const { slug } = useParams();


  return (
    <>

      {slug === 'whcoupon' ? <WhHeader /> : <Header />}


      <section className="ContentBlock pt-24 pb-24 Main_Block Confirmation_Message ">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="Common_Block">
                <div className="Common_Block_Body pr-62 pl-62 pt-62 pb-62 radius-5 text-center CelebrationBg">
                  {/* <img src={Celebration} alt="Celebration" className='img-fluid' /> */}
                  <span>{coupon_type === "scan" ? "Scan" : "Coupon"} will distributed in</span>
                  {slug === 'whcoupon' ? <h4>2 hours</h4> : <h4>24 hours</h4>}
                  <img src={CardImage} alt="" className="img-fluid mt-30" />
                  <ul className="d-flex CountDown align-items-center justify-content-center mt-25">

                  </ul>
                  {slug === 'whcoupon' ?
                    <Link to="/wholesalerlist">
                      <button className="Btn_blue mt-20">
                        Get To My {coupon_type === "scan" ? "Scans" : "Coupons"}
                      </button>
                    </Link>
                    :
                    <Link to="/coupons">
                      <button className="Btn_blue mt-20">
                        Get To My {coupon_type === "scan" ? "Scans" : "Coupons"}
                      </button>
                    </Link>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterStrict />
    </>
  );
}

export default ConfirmationMessage;
