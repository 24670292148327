import React from 'react'
import { Form } from "react-bootstrap";
import Header from "../../Components/Wholesaler/layout/header";
import FileIcon from "../../Assets/images/file-icon.png";
import scissors from "../../Assets/images/scissors.png";
import Info from "../../Assets/images/info.png";
import { renderTooltip } from "../../Components/Helper";
import FooterStrict from "../Dashboard/FooterStrict";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ScanSummary from './ScanSummary';


class ScanBuild extends React.Component {
    render() {
        return (
          <>
          <Header location={`Wizard`} />
<div className="container-fluid">
  <div className="ContentBlock pt-24 pb-24 Main_Block">
            <div className="row  g-3">
                <div className="col-xxl-9 col-xl-8 col-12">
                    <div className="Common_Block">
                        <div className="Common_Block_Header d-flex align-items-center justify-content-between">
                            <h5 className="">Scan Details</h5>
                        </div>
                        <div className="Common_Block_Body pt-17 pb-17 pr-14 pl-14 Build_Offer_Block">
                            <div className="row">
                                <div className="col-12 mt-28">
                                    <Form.Group
                                        controlId="reward_amount"
                                        className="form-group"
                                    >
                                        <Form.Label>Scan Value Per Units</Form.Label>
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={renderTooltip('Example: For 99c Enter .99')}
                                        >
                                            <img src={Info} className="img-fluid" alt="Edit"
                                                style={{ padding: '1px', width: "31px", height: "31px", cursor: 'pointer' }} />
                                        </OverlayTrigger>

                                        <Form.Control
                                            type="text"
                                            placeholder="E.g. 1.00"
                                            name="reward_amount"
                                            onChange={(e) => this.props.changeHandler(e)}
                                            step=".01"
                                        />
                                    </Form.Group>
                                </div>


                                <div className="col-12 mt-28">
                                    <Form.Group
                                        controlId="title"
                                        className="form-group"
                                    >
                                        <Form.Label>Title</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Title"
                                            name="title"
                                            onChange={(e) => this.props.changeHandler(e)}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-12 mt-28">
                                    <Form.Group className="form-group">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            placeholder="Description"
                                            as="textarea"
                                            rows={1}
                                            name="description"
                                            id="description"
                                            onChange={(e) => this.props.changeHandler(e)}
                                        />
                                    </Form.Group>
                                </div>

                                {/* <div className="col-12 mt-28 row"> */}

                                {/* </div> */}
                                <div className="col-md-4 col-12 mt-28">
                                    <Form.Group
                                        className="form-group"
                                        controlId="exampleForm.ControlInput1"
                                    >
                                        <Form.Label>Start Date</Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="start_date"
                                            onKeyDown={(e) => e.preventDefault()}
                                            onChange={(e) => this.props.setStartDate(e)}
                                            min={
                                                new Date(
                                                    new Date().getTime() +
                                                    1 * 24 * 60 * 60 * 1000
                                                )
                                                    .toISOString()
                                                    .split("T")[0]
                                            }
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-md-4 col-12 mt-28">
                                    <Form.Group
                                        className=" form-group"
                                        controlId="exampleForm.ControlInput2"
                                    >
                                        <Form.Label className="form-group">
                                            End Date
                                        </Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="end_date"
                                            onKeyDown={(e) => e.preventDefault()}
                                            onChange={(e) => this.props.setEndDate(e)}
                                            min={
                                                new Date().toISOString().split("T")[0]
                                            }
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-md-4 col-12 mt-28">
                                    <Form.Group
                                        className="form-group"
                                        controlId="shutoff_date"
                                    >
                                        <Form.Label className="form-group">
                                            Shutoff Date
                                        </Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="shutoff_date"
                                            onKeyDown={(e) => e.preventDefault()}
                                            onChange={(e) => this.props.setShutoffDate(e)}
                                            min={
                                                new Date().toISOString().split("T")[0]
                                            }
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-12 mt-28">
                                    <Form.Group
                                        controlId="Descripation"
                                        className="form-group"
                                    >
                                        <Form.Label>
                                            Scan Legal Disclaimer
                                        </Form.Label>
                                        <Form.Control
                                            placeholder="Coupon Legal Copy"
                                            as="textarea"
                                            rows={4}
                                            defaultValue=" "
                                            name="disclaimer"
                                            onChange={(e) => this.props.changeHandler(e)}
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-lg-4 col-md-6 col-12 mt-28">
                                    <Form.Group
                                        controlId="Description"
                                        className="form-group"
                                    >
                                        <Form.Label>Scan Image</Form.Label>
                                        <p>
                                            File must be 250px * 250px, 150dpi png or
                                            jpg
                                        </p>

                                        <div className="File_Upload position-relative ml-7">
                                            <img
                                                src={FileIcon}
                                                className="img-fluid"
                                                alt="File Icon"
                                            />
                                            <p>
                                                <b>Drag & Drop Your</b> files here or{" "}
                                                <b>
                                                    <span>browse</span>
                                                </b>{" "}
                                                your computer
                                            </p>
                                            <input
                                                id="file-upload"
                                                type="file"
                                                name="fileUpload"
                                                onChange={(e) => this.props.onFileChange(e)}
                                                onClick={(event) => {
                                                    event.target.value = null;
                                                }}
                                            />
                                        </div>
                                    </Form.Group>
                                </div>

                                <div className="col-lg-4 col-md-6 col-12 mt-28"></div>
                            </div>

                            <div className="row">
                            </div>
                            <div className="row mt-20">
                                <div className="col-12 d-flex justify-content-end">
                                    <div className="TableButton">
                                        <button
                                            type="button"
                                            className="mr-10 btn btn-primary"
                                            onClick={(e) => {
                                                this.props.changePrevTab(e, 1);
                                            }}
                                        >
                                            <i
                                                className="fas fa-arrow-left mr-10"
                                                aria-hidden="true"
                                            ></i>{" "}
                                            Prev
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={(e) => {
                                                this.props.changeSecondTab(e, 3);
                                            }}
                                        >
                                            Next{" "}
                                            <i
                                                className="fas fa-arrow-right ml-10"
                                                aria-hidden="true"
                                            ></i>{" "}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {/* </form>  */}
                        </div>
                    </div>
                </div>


                <div className="col-xxl-3 col-xl-4 col-12">

                    <ScanSummary reward_amount={this.props.reward_amount} upcArrlength={this.props.upcArrlength}/>

                </div>
            </div>
  </div>
</div>
             <FooterStrict />
          </>
        )
    }
}
export default ScanBuild