import { createContext } from 'react';
import VendorParticipation from './VendorParticipation';


const CouponContext = createContext(
  {
    title: "",
    description: "",
    offer_gs1: "",
    reward_amount: 0,
    min_purchase_amount: 1,
    manufactuer_id: 0,
    coupon_category: 2,
    start_date: "",
    end_date: "",
    shutoff_date: "",
    clip_limit: 0,
    redeem_limit: 0,
    clip_limit_amount: 0,
    redeem_limit_amount: 0,
    coupon_type: 1,
    reward_quantity: 0,
    min_quantity: 1,
    coupon_code: "",
    is_flex: 0,
    coupon_target_type_id: 0,
    is_discount_percentage: 0,
    max_reward_limit: 0,
    limit_per_customer: 0,
    addtional_details: "",
    disclaimer: "",
    image_1: "",
    image_1_thumbnail: "",
    media_url: "",
    brand_guid: "",
    coupon_condition: {
      per_customer_limit: 1,
      per_transaction_limit: 1
    },
    wholesalers: [],
    upc: [],
    retailer_groups: [],
    retailers: [],
    per_redemption: 0,
    per_scan: 0,
    fixed_amount: 0,
    confirm_offer: 0,
    submitting_offer: false,
    reward_upc: [],
    offer_type: 0,
    selectedIds: [],
    selectAll: false,
    action: "",
    guid: "",
    coupon_status: 0,
    provider: "",
    isFsi: false,
    coupon_limit: 1
  }
);

export default CouponContext;
