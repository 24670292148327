import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
  getListOfWholesalerCoupon,
  downloadallCoupon,
  getListOfDateSearchCoupon
} from "../../API/Wholesalerapi";
import ls from "local-storage";
import analytics from "../../Assets/images/analytics.png";
import sort from "../../Assets/images/sort.svg";
import Header from "../../Components/Wholesaler/layout/header.js";
import { DateFormatter } from "../../Components/Helper";
import edit_pencil from "../../Assets/images/edit_pencil.png";
import digitalCoupons from "../../Assets/images/coupons.svg";
import search from "../../Assets/images/search.png";
import cross from "../../Assets/images/cross.png";
import expire from "../../Assets/images/expire.png";
import copy from "../../Assets/images/copy-32.png";
import { renderTooltip } from "../../Components/Helper";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ClipLoader from "react-spinners/ClipLoader";
import CouponExpire from "../../Components/Common/ConfirmationModal/CouponExpire";
import $ from "jquery";
import * as CONFIG from "../../Components/_config";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Form, Spinner } from "react-bootstrap";
import { getBrands } from "../../API/DashBoard";
import { Link } from "react-router-dom";
import Paginate from "./Paginate";
import { async } from "q";
import localStorage from "local-storage";

const List = () => {
  var page;
  const [tableData, setTableData] = useState([]);
  const [wholesalerData, setWholeSalerData] = useState([]);
  const [couponAnalysis, setCouponAnalysis] = useState();
  const [showContainer, setShowContainer] = useState(false);

  const handleClick = () => {
    setShowContainer(!showContainer);
  };

  const [filedata, setFiledata] = useState([]);
  const [downloadcouponloader, setDownloadcouponloader] = useState(true);
  const [couponloader, setCouponLoader] = useState("true");
  const [searchData, setSearchData] = useState("");
  // const [updated, setUpdated] = useState(searchData)

  const handleChange = (e) => {
    setSearchData(e.target.value);
  };
  const [pageloader, setPageloader] = useState(false);
  const showLoader = () => {
    setPageloader(true);
  };

  const hideLoader = () => {
    setPageloader(false);
  };

  const Loader = () => <div id="cover-spin"></div>;


  const [couponid, setCouponid] = useState("");

  const [totalRecordsLength, setTotalRecordsLength] = useState();
  const [sortItem, setSortItem] = useState("");

  const toggleLoader = () => {
    setPageloader(!pageloader);
  };

  const handleDate = (date) => {
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();

    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();

    let calculate_date = year + "-" + month + "-" + day;
    return calculate_date;
  };

  const downloadupcbreakdown = (couponAnalysis) => {
    showLoader();
    var now = new Date();
    var today = new Date(
      Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
    );



    var c_end_date = new Date(
      handleDate(new Date(couponAnalysis.coupon_end_date))
    );
    var c_start_date = new Date(
      handleDate(new Date(couponAnalysis.coupon_start_date))
    );

    if (c_end_date.getTime() < today.getTime()) {
      var api_end_date1 = handleDate(
        new Date(couponAnalysis.coupon_end_date)
      );
    } else {
      api_end_date1 = new Date().toISOString().split("T")[0];
    }
    if (c_start_date.getTime() > today.getTime()) {
      var api_start_date = new Date().toISOString().split("T")[0];
    } else {
      api_start_date = handleDate(
        new Date(couponAnalysis.coupon_start_date)
      );
    }
    if (couponAnalysis.coupon_target_type_id === 1) {
      var data = JSON.stringify({
        StartDate: api_start_date ? api_start_date : " ",
        EndDate: api_end_date1 ? api_end_date1 : " ",
        OrderBy: "calMonthYear",
        BrandID: 0,
        UPC: couponAnalysis.upc?.map(upc => upc.upc11).reduce((acc, curr) => acc.concat(curr), []),
        type: "QtySalesByUPC",
        Wholesaler: couponAnalysis.wholesalers.map(wholesaler => wholesaler.wholesaler_number).reduce((acc, curr) => acc.concat(curr), []),
        Retailer: []
      });
    } else {
      data = JSON.stringify({
        StartDate: api_start_date ? api_start_date : " ",
        EndDate: api_end_date1 ? api_end_date1 : " ",
        OrderBy: "calMonthYear",
        BrandID: 0,
        UPC: couponAnalysis.upc?.map(upc => upc.upc11).reduce((acc, curr) => acc.concat(curr), []),
        type: "QtySalesByUPC",
        Wholesaler: [],
        Retailer: couponAnalysis.wholesalers?.map(wholesaler => wholesaler.retailer).reduce((acc, curr) => acc.concat(curr), []).map(retailer => retailer.rsa_identifier)
        
      });
    }

    var config = {
      method: "post",
      url: CONFIG.API_URL + "query",
      headers: CONFIG.config_head.headers,
      data: data
    };

    axios(config)
      .then((res) => {
        hideLoader();
        if (res.status === 200) {
          const fileUrl = res.data.Message[res.data.Message.length - 1].FilePath;
          const fileName = fileUrl.split('/').pop();

          const link = document.createElement('a');
          link.href = fileUrl;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        } else {
          toast.error(res.response.data.Message);
        }
      })
      .catch(function (error) {
        hideLoader();
        if (error.response) {
          if (error.response.status === 502) {
            toast.error("Bad Request!");
          } else if (error.response.status === 400) {
            toast.error(error.response.data.Message);
          } else {
            toast.error(error.res.data.Message);
          }
        }
      });
  }

  const downloadCoupon = (couponGuid) => {
    showLoader();
    var config = {
      method: "get",
      url: CONFIG.API_URL + `coupon/analysis/${couponGuid}`,
      headers: CONFIG.config_head.headers
    };

    axios(config)
      .then((response) => {
        hideLoader();
        if (response.status === 201) {
          downloadupcbreakdown(response.data.Message.Coupon);
        }
      })
      .catch((error) => {
        hideLoader();
        if (error.response) {
          if (error.response.status === 502) {
            toast.error("Bad Request!");
          } else if (error.response.status === 400) {
            toast.error(error.response.data.Message);
          } else {
            toast.error(error.response.data.Message);
          }
        }
      });
  }

  const expireCoupon = () => {
    $("#couponExpire").css("display", "none");
    $(".modal-backdrop").first().css("display", "none");

    showLoader();

    var config = {
      method: "get",
      url: CONFIG.API_URL + `coupon/expire/${couponid}`,
      headers: CONFIG.config_head.headers
    };

    axios(config)
      .then((response) => {
        hideLoader();
        if (response.status === 204) {
          toggleLoader();
          toast.success("Expired Succesfully");
          getCoupons();
        }
      })
      .catch((error) => {
        hideLoader();
        if (error.response) {
          if (error.response.status === 502) {
            toast.error("Bad Request!");
          } else if (error.response.status === 400) {
            toggleLoader();
            toast.error(error.response.data.Message);
          } else {
            toggleLoader();
            toast.error(error.response.data.Message);
          }
        }
      });
  };

  const guid = ls.get("brands") ? ls.get("brands")[0].guid : "";

  useEffect(() => {
    getCoupons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCoupons = async () => {
    showLoader();
    const response = await getListOfWholesalerCoupon(guid);
    hideLoader();
    if (response.status === 200) {
      setTableData(response.data.Message.entries);
      setTotalRecordsLength(response?.data?.Message?.totalcount);
    } else {
      setTableData([]);
    }
  };

  useEffect(() => {
    const getWholeslaler = async () => {
      const response = await getBrands(guid);
      if (Object.keys(response.Message).length > 1) {
        setWholeSalerData(response.Message);
        ls.set("wholesalerId", response.Message.wholesalers[0]?.WholesalerId);
      } else {
        setWholeSalerData([]);
      }
    };
    getWholeslaler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const couponownload = () => {
    if (Object.keys(wholesalerData).length > 1) {
      downloadallCoupon(
        wholesalerData.wholesalers[0].WholesalerNumber,
        guid
      ).then((response) => {
        if (response.status === 200) {
          setFiledata(response.data.Message);
          $("#mybtn").trigger("click");
        } else {
          toast.error(response.data.Message);
        }
      });
    } else {
      toast.error("No Coupon Present");
    }
  };
  const [name, setName] = useState("");
  const [remountComponent, setRemountComponent] = useState(0);
  const pageCount = Math.ceil(totalRecordsLength / 10);
  const [sortdesc, setSortdesc] = useState(0);
  const targetValue = (e) => {
    return e.target.value;
  };

  const handlePageClick = (event) => {
    page = event.selected;
    let sortvalue;
    if (sortdesc == 1) {
      sortvalue = 0;
    } else {
      sortvalue = 1;
    }
    showLoader();
    const getCouponsPaginate = async () => {
      const response = await getListOfWholesalerCoupon(
        guid,
        event.selected + 1,
        name,
        sortvalue,
        searchData,
        startDateSearch,
        startDatetoSearch,
        endDateSearch,
        endDateToSearch
      );
      hideLoader();
      if (response.status === 200) {
        setTableData(response.data.Message.entries);
      } else {
        setTableData([]);
      }
    };
    getCouponsPaginate();
  };

  const sortingData = async (e, name) => {
    setName(name);
    showLoader();
    const response = await getListOfWholesalerCoupon(
      guid,
      e.selected + 1,
      name,
      sortdesc,
      searchData,
      startDateSearch,
      startDatetoSearch,
      endDateSearch,
      endDateToSearch
    );
    hideLoader();
    sortdesc == 1 ? setSortdesc(0) : setSortdesc(1);
    if (response.status === 200) {
      setTableData(response.data.Message.entries);
    } else {
      setTableData([]);
    }
  };

  const handleSearchClose = async () => {
    setSearchData("");
    let sortvalue;
    if (sortdesc == 1) {
      sortvalue = 0;
    } else {
      sortvalue = 1;
    }
    showLoader();
    const response = await getListOfWholesalerCoupon(
      guid,
      1,
      name,
      sortvalue,
      "",
      startDateSearch,
      startDatetoSearch,
      endDateSearch,
      endDateToSearch
    );
    hideLoader();
    if (response.status === 200) {
      setTableData(response.data.Message.entries);
      setTotalRecordsLength(response?.data?.Message?.totalcount);
    } else {
      setTableData([]);
    }
  };

  const clearDates = async () => {
    setEndDateSearch("");
    setEndDateToSearch("");
    setStartDateSearch("");
    setStartDatetoSearch("");
    let sortvalue;
    if (sortdesc == 1) {
      sortvalue = 0;
    } else {
      sortvalue = 1;
    }
    showLoader();
    const response = await getListOfWholesalerCoupon(
      guid,
      1,
      name,
      sortvalue,
      searchData
    );
    hideLoader();
    if (response.status === 200) {
      setTableData(response.data.Message.entries);
      setTotalRecordsLength(response?.data?.Message?.totalcount);

    } else {
      setTableData([]);
    }
  }

  const [showRemainingBrands, setShowRemainingBrands] = useState(false);
  const handleSearch = async () => {
    if (
      (startDateSearch != "" && startDatetoSearch === "") ||
      (startDateSearch === "" && startDatetoSearch != "")
    ) {
      toast.error("Please Select Start Dates.");
      return;
    } else if (
      (endDateSearch != "" && endDateToSearch === "") ||
      (endDateSearch === "" && endDateToSearch != "")
    ) {
      toast.error("Please Select End Dates.");
      return;
    }
    else {
      let sortvalue;
      if (sortdesc == 1) {
        sortvalue = 0;
      } else {
        sortvalue = 1;
      }

      showLoader();
      const response = await getListOfWholesalerCoupon(
        guid,
        1,
        name,
        sortvalue,
        searchData,
        startDateSearch,
        startDatetoSearch,
        endDateSearch,
        endDateToSearch
      );
      hideLoader();
      if (response.status === 200) {
        setTableData(response.data.Message.entries);
        setTotalRecordsLength(response?.data?.Message?.totalcount);

      } else {
        setTableData([]);
      }
    }
  };
  const [startDateSearch, setStartDateSearch] = useState("");
  const [startDatetoSearch, setStartDatetoSearch] = useState("");
  const [endDateSearch, setEndDateSearch] = useState("");
  const [endDateToSearch, setEndDateToSearch] = useState("");

  const handleSearchDate = async () => {
    showLoader();
    const response = await getListOfDateSearchCoupon(
      guid,
      1,
      startDateSearch,
      startDatetoSearch,
      endDateSearch,
      endDateToSearch
    );
    hideLoader();
    if (response.status === 200) {
      setTableData(response.data.Message.entries);
      setTotalRecordsLength(response?.data?.Message?.totalcount);
    } else {
      setTableData([]);
    }
  };

  return (
    <React.Fragment>
      <Header />

      {pageloader ? <Loader /> : null}

      <div
        className="ContentBlock pt-40 pb-24 Main_Block"
        style={{ padding: "20px" }}
      >
        <a
          href={filedata}
          target="_blank"
          rel="noopener noreferrer"
          download
          style={{ display: "none" }}
        >
          <Button id="mybtn">Download File</Button>
        </a>

        {/* <a href='/add-excel'>
                    <button className='btn Add_User_Button' style={{ float: 'right', margin: '3px' }}>Add Excel</button>
                </a> */}

        <Button
          variant=""
          type="button"
          className="Digital_Brand_Button approve mt-20"
          style={{
            float: "right",
            padding: "10px",
            border: "none",
            margin: "1px"
          }}
          onClick={couponownload}
        >
          Download All Coupons
        </Button>
        <Link
          to="/retailer-group"
          className="btn Add_User_Button mt-20"
          style={{ float: "right", margin: "3px" }}
        >
          Retailer Group
        </Link>
        <Link
          to="/coupon?coupon_type=scan"
          className="btn Add_User_Button mt-20"
          style={{ float: "right", margin: "3px" }}
        >
          Create Scan
        </Link>
        <Link
          to="/coupon?coupon_type=coupon"
          className="btn Add_User_Button mt-20"
          style={{ float: "right", margin: "3px" }}
        >
          Create Coupon
        </Link>
        <Link
          to="/create-bulkcoupon"
          className="btn Add_User_Button mt-20"
          style={{ float: "right", margin: "3px" }}
        >
          Create Coupon in Bulk
        </Link>
        <>
          <div style={{ backgroundColor: "white" }}>
            <div className="search-form">
              <div className="d-flex align-items-center">
                <img
                  src={digitalCoupons}
                  className="img-fluid ml-15 mt-15"
                  alt="Digial Coupons"
                />
                <h5 style={{ fontSize: "18px", marginTop: "15px" }}>
                  Wholesaler Promotions
                </h5>
              </div>
              <div>
                <form className="example input-align">
                  <input
                    type="text"
                    placeholder="Search.."
                    name="search"
                    onChange={handleChange}
                    value={searchData}
                    className="search-input"
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        handleSearch();
                        e.preventDefault();
                      }
                    }}
                  />

                  <img
                    src={cross}
                    className="cross-img"
                    onClick={(e) => {
                      handleSearchClose();
                      e.preventDefault();
                    }}
                  />
                  <img
                    src={search}
                    className="search-img"
                    onClick={(e) => {
                      handleSearch();
                      e.preventDefault();
                    }}
                  />
                </form>
              </div>
            </div>

            <div
              className="Steps_Header d-flex align-items-center justify-content-between mt-15"
              style={{ height: "40px" }}
            >
              <h5>Search by Dates </h5>
              <div className="Search_Box">
                <input
                  type="text"
                  className="form-control Search_Toggle"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="search-addon"
                />
                {showContainer ? (
                  <span className="search_card_rotate" onClick={handleClick}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10 0 L20 20 L0 20 Z" fill="white" />
                    </svg>
                  </span>
                ) : (
                  <span className="search_card" onClick={handleClick}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10 0 L20 20 L0 20 Z" fill="white" />
                    </svg>
                  </span>
                )}
              </div>
            </div>
            {showContainer && (
              <div className="Steps_Header  mt-15 d-flex align-items-center justify-content-between" style={{ height: "40px" }}>
                <div className="d-flex">
                  <h6
                    style={{
                      textAlign: "center",
                      color: "white",
                      marginTop: "5px",
                      marginRight: "15px",
                      fontWeight: "bold"
                    }}
                  >
                    Start Date
                  </h6>
                  <div className="d-flex">
                    <div className="d-flex mr-10">
                      <h6 className="mr-6 mt-5" style={{ color: "white" }}>
                        between
                      </h6>
                      <input
                        type="date"
                        className="search_date"
                        value={startDateSearch.split("T")[0]}
                        onChange={(e) =>
                          setStartDateSearch(
                            new Date(e.target.value).toISOString()
                          )
                        }
                      />
                    </div>
                    <div className="d-flex">
                      <h6 className="mr-6 mt-5" style={{ color: "white" }}>
                        and
                      </h6>
                      <input
                        type="date"
                        className="search_date"
                        value={startDatetoSearch.split("T")[0]}
                        onChange={(e) =>
                          setStartDatetoSearch(
                            new Date(e.target.value).toISOString()
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <h6
                    style={{
                      textAlign: "center",
                      color: "white",
                      marginTop: "5px",
                      marginRight: "15px",
                      fontWeight: "bold"
                    }}
                  >
                    End Date
                  </h6>
                  <div className="d-flex">
                    <div className="d-flex mr-10">
                      <h6 className="mr-6 mt-5" style={{ color: "white" }}>
                        between
                      </h6>
                      <input
                        type="date"
                        className="search_date"
                        value={endDateSearch.split("T")[0]}
                        onChange={(e) =>
                          setEndDateSearch(
                            new Date(e.target.value).toISOString()
                          )
                        }
                      />
                    </div>
                    <div className="d-flex">
                      <h6 className="mr-6 mt-5" style={{ color: "white" }}>
                        and
                      </h6>
                      <input
                        type="date"
                        className="search_date"
                        value={endDateToSearch.split("T")[0]}
                        onChange={(e) =>
                          setEndDateToSearch(
                            new Date(e.target.value).toISOString()
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div >
                  <button className="search_button" onClick={handleSearch}>
                    Search
                  </button>
                </div>
                <div >
                  <button className="search_clear" onClick={clearDates}>
                    Clear
                  </button>
                </div>
              </div>
            )}
            <div className="table-responsive Coupons_Table">
              <table className="table mt-25">
                <thead>
                  <tr>
                    <th>
                      Title
                      <img
                        src={sort}
                        alt="Sorting"
                        className="img-fluid ml-15"
                        onClick={(e) => sortingData(e, "coupon_title")}
                      />
                    </th>
                    <th style={{ textAlign: "left" }}>
                      Type
                      <img
                        src={sort}
                        alt="Sorting"
                        className="img-fluid ml-15"
                        onClick={(e) => sortingData(e, "category_name")}
                      />
                    </th>
                    <th style={{ textAlign: "center" }}>
                      Value
                      <img
                        src={sort}
                        alt="Sorting"
                        className="img-fluid ml-15"
                        onClick={(e) => sortingData(e, "reward_amount")}
                      />
                    </th>
                    <th>
                      Start
                      <img
                        src={sort}
                        alt="Sorting"
                        className="img-fluid ml-15"
                        onClick={(e) => sortingData(e, "coupon_start_date")}
                      />
                    </th>
                    <th>
                      End
                      <img
                        src={sort}
                        alt="Sorting"
                        className="img-fluid ml-15"
                        onClick={(e) => sortingData(e, "coupon_end_date")}
                      />
                    </th>
                    <th>Brand(s)</th>
                    <th>
                      Clips
                      <img
                        src={sort}
                        alt="Sorting"
                        className="img-fluid ml-15"
                        onClick={(e) => sortingData(e, "clip_count")}
                      />
                    </th>
                    <th>
                      Redemptions
                      <img
                        src={sort}
                        alt="Sorting"
                        className="img-fluid ml-15"
                        onClick={(e) => sortingData(e, "redemption_count")}
                      />
                    </th>
                    <th>Copy/Edit/Expire</th>
                    <th>Status</th>
                    <th>Total Units Sold</th>
                    <th>Analysis</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.length < 1 ? (
                    <label style={{ textAlign: "center", fontWeight: "700" }}>
                      No Coupon to display
                    </label>
                  ) : (
                    tableData.map((each, i) => (
                      <tr key={i}>
                        {/* {console.log(tableData)} */}
                        <th style={{ fontSize: "12px" }}>
                          <a
                            href={`/whcoupon-details/${each.coupon_guid}`}
                            className="text-decoration-none"
                            style={{ textDecoration: "underline" }}
                          >
                            {each.coupon_title}
                          </a>
                        </th>
                        <th style={{ fontSize: "12px", textAlign: "left" }}>
                          {each.category_name}
                        </th>
                        <th style={{ fontSize: "12px", textAlign: "center" }}>
                          {each.is_discount_percentage === false
                            ? "$ " + each.reward_amount + " OFF"
                            : each.reward_amount + " % OFF"}
                        </th>
                        <th style={{ fontSize: "12px", textAlign: "center" }}>
                          {DateFormatter(
                            new Date(each.coupon_start_date.split("T")[0])
                          )}
                        </th>
                        <th style={{ fontSize: "12px", textAlign: "center" }}>
                          {DateFormatter(
                            new Date(each.coupon_end_date.split("T")[0])
                          )}
                        </th>
                        {/* {console.log("brands", each.associated_brands)} */}
                        {/* <th style={{ fontSize: "12px", textAlign: "center" }}>
                              {each.associated_brands.map((each) =>
                                <p>{each.brand_name}</p>
                              )}
                            </th> */}

                        <th
                          style={{ fontSize: "12px", textAlign: "center" }}
                          onMouseOver={(event) =>
                          (event.target.title = each.associated_brands
                            .map((brand) => brand.brand_name)
                            .join(", "))
                          }
                          onMouseLeave={(event) => (event.target.title = "")}
                        >
                          {each.associated_brands.slice(0, 2).map((each) => (
                            <p>{each.brand_name}</p>
                          ))}
                          {each.associated_brands.length > 2 && (
                            <>
                              {!showRemainingBrands && <p>...</p>}
                              <div
                                style={{
                                  display: showRemainingBrands
                                    ? "block"
                                    : "none"
                                }}
                              >
                                {each.associated_brands
                                  .slice(2)
                                  .map((each) => (
                                    <p>{each.brand_name}</p>
                                  ))}
                              </div>
                            </>
                          )}
                        </th>

                        <th style={{ fontSize: "13px", textAlign: "center" }}>
                          {each.clip_count}
                        </th>
                        <th style={{ fontSize: "12px", textAlign: "center" }}>
                          {each.redemption_count}
                        </th>
                        <th style={{ textAlign: "center" }}>
                          <a
                            href={`/coupon?coupon_type=${each.category_name === "Scan" ? "scan" : "coupon"}&guid=${each.coupon_guid}&action=copy`}
                            className="text-decoration-none"
                          >
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip("Copy")}
                            >
                              <img
                                src={copy}
                                height={"23px"}
                                width={"22px"}
                                style={{ marginRight: "12px" }}
                                alt={each.category_name}
                              />
                            </OverlayTrigger>
                          </a>

                          {!each.is_expired ? (
                            <a
                              href={`/coupon?coupon_type=${each.category_name === "Scan" ? "scan" : "coupon"}&guid=${each.coupon_guid}&action=edit`}
                              className="text-decoration-none"
                            >
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip("Edit")}
                              >
                                <img
                                  src={edit_pencil}
                                  height={"23px"}
                                  width={"22px"}
                                  style={{ marginRight: "12px" }}
                                  alt={each.category_name}
                                />
                              </OverlayTrigger>
                            </a>
                          ) : (
                            <span className="text-decoration-none" style={{ cursor: "not-allowed", opacity: 0.5 }}>
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip("You can't edit expired coupon")}
                              >
                                <img
                                  src={edit_pencil}
                                  height={"23px"}
                                  width={"22px"}
                                  style={{ marginRight: "12px" }}
                                  alt={each.category_name}
                                />
                              </OverlayTrigger>
                            </span>
                          )}

                          {each.is_expired ? (
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip("Alredy Expired")}
                            >
                              <img
                                src={expire}
                                height={"26px"}
                                width={"25px"}
                                alt={`expire`}
                              />
                            </OverlayTrigger>
                          ) : (
                            <a
                              className="text-decoration-none"
                              data-bs-toggle="modal"
                              data-bs-target="#couponExpire"
                              onClick={() => {
                                setCouponid(each.coupon_guid);
                              }}
                              href={(e) => e.preventDefault()}
                            >
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip("Expire")}
                              >
                                <img
                                  src={expire}
                                  height={"26px"}
                                  width={"25px"}
                                  alt={`expire`}
                                />
                              </OverlayTrigger>
                            </a>
                          )}
                        </th>
                        <th style={{ textAlign: "center" }}>
                          {each.is_expired === true ? (
                            <button
                              type="button"
                              className="btn btn-outline-danger"
                              style={{ pointerEvents: "none", opacity: "1" }}
                            >
                              Expired
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-outline-success"
                              style={{
                                pointerEvents: "none",
                                opacity: "0.8"
                              }}
                            >
                              Active
                            </button>
                          )}
                        </th>
                        <th style={{ textAlign: "center" }}>
                          <a
                            onClick={() => {
                              downloadCoupon(each.coupon_guid);
                            }}
                            href={(e) => e.preventDefault()}
                            className="text-decoration-none"
                          >
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip("Analysis")}
                            >
                              <img
                                src={analytics}
                                height={"26px"}
                                width={"25px"}
                                alt={`analytics`}
                              />
                            </OverlayTrigger>
                          </a>
                        </th>
                        <th style={{ textAlign: "center" }}>
                          <a
                            href={`/whcoupon-details/${each.coupon_guid}`}
                            className="text-decoration-none"
                          >
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip("Analysis")}
                            >
                              <img
                                src={analytics}
                                height={"26px"}
                                width={"25px"}
                                alt={`analytics`}
                              />
                            </OverlayTrigger>
                          </a>
                        </th>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {/* <div>
              <DataTable
                title="Wholesaler Coupon"
                columns={columns}
                data={tableData}
              />
            </div> */}
        </>
        <div>
          <Paginate
            handlePageClick={handlePageClick}
            pageCount={parseInt(pageCount)}
          />
        </div>
      </div>

      <CouponExpire callback={expireCoupon} />
    </React.Fragment>
  );
};
export default List;
