import React, { useContext } from 'react';
import scissors from "../../Assets/images/scissors.png";
import CouponSummary from './CouponSummary';
import Header from "../../Components/Wholesaler/layout/header.js";
import CouponContext from './CouponContext.js';

const VendorParticipation = ({ couponType }) => {
    const { couponData, updateCouponData } = useContext(CouponContext);
    return (
        <React.Fragment>
            <Header />
            <div
                className="ContentBlock pt-40 pb-24 Main_Block"
                style={{ padding: "20px" }}
            >
                <div className="row  g-3">
                    <div className="col-xxl-9 col-xl-8 col-12">
                        <div className="Common_Block">
                            <div className="Common_Block_Header d-flex align-items-center justify-content-between">
                                <h5 className="">Vendor Participation</h5>
                            </div>
                            <div className="Common_Block_Body pt-17 pb-17 pr-14 pl-14 Build_Offer_Block">
                                <div className="vendor-participation Coupons_Card">
                                    <label htmlFor="per-redemption">Vendor Funding:</label>
                                    <div className="input-group" style={{ width: "50%" }}>
                                        <label htmlFor="per-redemption">Per Redemption ($)</label>
                                        <input
                                            type="number"
                                            id="per-redemption"
                                            value={couponData.per_redemption}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (/^\d*\.?\d*$/.test(value)) {
                                                    updateCouponData({ per_redemption: value });
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="input-group" style={{ width: "50%" }}>
                                        <label htmlFor="per-scan">Per Scan ($)</label>
                                        <input
                                            type="number"
                                            id="per-scan"
                                            value={couponData.per_scan}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (/^\d*\.?\d*$/.test(value)) {
                                                    updateCouponData({ per_scan: value });
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="input-group" style={{ width: "50%" }}>
                                        <label htmlFor="fixed-amount">Fixed Amount ($)</label>
                                        <input
                                            type="number"
                                            id="fixed-amount"
                                            value={couponData.fixed_amount}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (/^\d*\.?\d*$/.test(value)) {
                                                    updateCouponData({ fixed_amount: value });
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-xl-4 col-12">
                        <div className="col-xxl-12 col-xl-12 col-12">
                            <CouponSummary />
                            <div className="Common_Block mt-21">
                                <div className="Common_Block_Header d-flex align-items-center justify-content-between">
                                    <h5 className="">Coupon Preview</h5>
                                </div>
                                <div className="Common_Block_Body pt-12 pr-12 pb-12 pl-12">
                                    <div className="Coupons_Card text-center">
                                        <img
                                            src={scissors}
                                            className="img-fluid scissors"
                                            alt="Products"
                                        />
                                        <img
                                            src={couponData.image_1}
                                            className="img-fluid"
                                            alt=""
                                        />
                                        <h2 className="Price">
                                            {couponData.title}
                                            <span></span>
                                        </h2>
                                        <p>{couponData.description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default VendorParticipation;