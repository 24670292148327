import React, { useState, useEffect } from 'react';
import Header from "../../Components/Wholesaler/layout/header.js";
import { getRetailers, createRetailerGroup, getRetailerGroup, updateRetailerGroup } from '../../API/Wholesalerapi.js';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import ls from "local-storage";
const Loader = () => <div id="cover-spin"></div>;

const CreateGroup = (props) => {
    const [retailers, setRetailers] = useState([]);
    const [groupName, setGroupName] = useState("");
    const [selectAll, setSelectAll] = useState(false);
    const [selectedRetailers, setSelectedRetailers] = useState([]);
    const [retailerGroupId, setRetailerGroupId] = useState("");
    const [spinloading, setSpinloading] = useState(false);
    const selectedBrand = ls.get("selectedbrand") || [];
    const brands = ls.get("brands") || [];
    const brandguid = selectedBrand.length > 0 ? selectedBrand[0].guid : (brands.length > 0 ? brands[0].guid : "");

    const showLoader = () => {
        setSpinloading(true);
    };

    const hideLoader = () => {
        setSpinloading(false);
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const retailer_group_id = urlParams.get('retailer_group_id');
        if (retailer_group_id) {
            setRetailerGroupId(retailer_group_id);
            fetchRetailers();
            fetchRetailerGroupDetails(retailer_group_id);
        } else {
            fetchRetailers();
        }
    }, []);

    const fetchRetailerGroupDetails = async (retailer_group_id) => {
        try {
            showLoader();
            const response = await getRetailerGroup(retailer_group_id);
            if (response && response.data) {
                const name = response.data.Message?.group_name;
                const selectedRetailers = response.data.Message?.retailers.map(retailer => retailer.retailer_id);
                setGroupName(name);
                setSelectedRetailers(selectedRetailers);
            }
            hideLoader();
        } catch (error) {
            console.error("Error fetching retailer group details:", error);
            hideLoader();
        }
    };

    const fetchRetailers = async () => {
        try {
            showLoader();
            const response = await getRetailers(brandguid);
            if (response.status === 200 && response.data) {
                if (response.data?.Message?.length > 0) {
                    setRetailers(response.data?.Message);
                } else {
                    setRetailers([]);
                    toast.error("No retailers found");
                }
            }
            hideLoader();
        } catch (error) {
            console.error("Error fetching retailers:", error);
            hideLoader();
        }
    };

    const handleSelectAll = (e) => {
        const { checked } = e.target;
        if (checked) {
            const allRetailerIds = retailers.map(retailer => retailer.retailer_id);
            setSelectedRetailers(allRetailerIds);
            setSelectAll(true);
        } else {
            setSelectedRetailers([]);
            setSelectAll(false);
        }
    };

    const handleCheckboxChange = (e, retailerId) => {
        const { checked } = e.target;
        setSelectedRetailers(prevSelectedRetailers => {
            return checked
                ? [...prevSelectedRetailers, retailerId]
                : prevSelectedRetailers.filter(id => id !== retailerId);
        });
    };

    const handleSaveRetailerGroup = async () => {
        if (!groupName) {
            toast.error("Retailer Group name is required");
            return;
        }
        if (selectedRetailers.length === 0) {
            toast.error("At least one retailer must be selected");
            return;
        }
        const data = {
            group_id: retailerGroupId,
            group_name: groupName,
            retailer_ids: selectedRetailers
        };
        showLoader();
        if (retailerGroupId) {
            await updateRetailerGroup(data, retailerGroupId)
                .then(response => {
                    if (response.status === 200) {
                        toast.success("Retailer group updated successfully");
                        setGroupName("");
                        setSelectAll(false);
                        setSelectedRetailers([]);
                        setRetailers(retailers.map(retailer => ({ ...retailer, selected: false })));
                        props.history.push('/retailer-group');
                    } else {
                        toast.error("Failed to update retailer group");
                    }
                    hideLoader();
                })
                .catch(error => {
                    console.error("Error updating retailer group:", error);
                    toast.error("An error occurred while updating the retailer group");
                    hideLoader();
                });
        } else {
            await createRetailerGroup(data)
                .then(response => {
                    if (response.status === 200) {
                        toast.success("Retailer group created successfully");
                        setGroupName("");
                        setSelectAll(false);
                        setSelectedRetailers([]);
                        setRetailers(retailers.map(retailer => ({ ...retailer, selected: false })));
                        props.history.push('/retailer-group');
                    } else {
                        toast.error("Failed to create retailer group");
                    }
                    hideLoader();
                })
                .catch(error => {
                    console.error("Error creating retailer group:", error);
                    toast.error("An error occurred while creating the retailer group");
                    hideLoader();
                });
        }
    };

    return (
        <React.Fragment>
            <Header />
            <div
                className="ContentBlock pt-40 pb-24 Main_Block"
                style={{ padding: "20px" }}
            >
                <div className="row  g-3">
                    <div className="col-xxl-12 col-xl-12 col-12">
                        <div className="Common_Block">
                            <div className="Common_Block_Header d-flex align-items-center justify-content-between">
                                <h5 className="">
                                    {retailerGroupId ? "Update Retailer Group" : "Create Retailer Group"}
                                </h5>
                            </div>
                            {spinloading ? <Loader /> : null}
                            <div className="Common_Block_Body pt-17 pb-17 pr-14 pl-14 Build_Offer_Block">
                                <div className="create-group">
                                    <div className="header">
                                        <div className="col-lg-6 col-12">
                                            <label
                                                htmlFor="inputEmail4"
                                                className="form-label"
                                            >
                                                Retailer Group Name
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Retailer Group Name"
                                                name="retailer_group_name"
                                                id="retailer_group_name"
                                                value={groupName}
                                                onChange={(e) => setGroupName(e.target.value)}
                                            />
                                        </div>
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={selectAll}
                                                onChange={(e) => { handleSelectAll(e) }}
                                            />
                                            Check / Uncheck All
                                        </label>
                                    </div>
                                    <div className="retailer-list">
                                        {retailers.map((retailer, index) => (
                                            <div className="retailer-card" key={retailer.retailer_id}>
                                                <img src={retailer.logo} alt="product-img" className="img-fluid" />
                                                <div className="retailer-info">
                                                    <h4>{retailer.retailer_name}</h4>
                                                    <label className="checkbox-container">
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedRetailers.includes(retailer.retailer_id)}
                                                            onChange={(e) => handleCheckboxChange(e, retailer.retailer_id)}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="d-flex justify-content-end text-end mb-50">
                                        <Button
                                            className="GrayButton mt-40"
                                            style={{
                                                backgroundColor: "#3D8ECC",
                                                color: "white",
                                                border: "none"
                                            }}
                                            type="submit"
                                            onClick={handleSaveRetailerGroup}
                                            disabled={!groupName || selectedRetailers.length === 0}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CreateGroup;