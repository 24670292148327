import React, { useContext, useEffect, useState } from "react";
import Error from "../../Assets/images/error.svg";
import CouponContext from "./CouponContext";

const CampaignDetail = ({ couponType }) => {
  const { couponData, updateCouponData } = useContext(CouponContext);
  const [isDataUpdated, setDataUpdated] = useState(false);
  const isWholesaler = (couponData.retailer_groups?.length || couponData.coupon_target_type_id === 1) ? true : false;

  const formatDates = (entity) => ({
    ...entity,
    start_date: couponData.start_date,
    end_date: couponData.end_date,
    shutoff_date: couponData.shutoff_date,
    clip_limit: entity.clip_limit ?? 1000000,
    redeem_limit: 0
  });
  useEffect(() => {
    const updateData = () => {
      switch (couponData.coupon_target_type_id) {
        case 1:
          if (couponData.wholesalers?.length > 0) {
            const updateWholesalers = couponData.wholesalers.map(wholesaler => ({
              ...formatDates(wholesaler)
            }));
            updateCouponData({ wholesalers: updateWholesalers });
          }
          break;
        case 2:
          if (couponData.retailers?.length > 0) {
            const updateRetailers = couponData.retailers.map(retailer => ({
              ...formatDates(retailer)
            }));
            updateCouponData({ retailers: updateRetailers });
          }
          break;
        case 3:
          if (couponData.retailer_groups?.length > 0) {
            const updateRetailerGroups = couponData.retailer_groups.map(group => ({
              ...formatDates(group)
            }));

            updateCouponData({ retailer_groups: updateRetailerGroups });
          }
          break;
        default:
          break;
      }
      setDataUpdated(true);
    };

    updateData();
  }, []);

  const renderDateInput = (name, value, min, max, onChange) => (

    <input
      type="date"
      name={name}
      value={value?.split("T")[0]}
      min={min}
      max={max}
      disabled={couponData.action === "edit" || couponData.action === "view"}
      onKeyDown={(e) => e.preventDefault()}
      onChange={onChange}
    />
  );

  const updateDate = (newDate, dateType, record) => {
    const updatedData = { ...couponData };
    switch (couponData.coupon_target_type_id) {
      case 1:
        updatedData.wholesalers = updatedData.wholesalers.map(wholesaler =>
          wholesaler.wholesaler_id === record.wholesaler_id
            ? { ...wholesaler, [dateType]: newDate }
            : wholesaler
        );
        break;
      case 2:
        updatedData.retailers = updatedData.retailers.map(retailer =>
          retailer.retailer_id === record.retailer_id
            ? { ...retailer, [dateType]: newDate }
            : retailer
        );
        break;
      case 3:
        updatedData.retailer_groups = updatedData.retailer_groups.map(group =>
          group.retailer_group_id === record.retailer_group_id
            ? { ...group, [dateType]: newDate }
            : group
        );
        break;
      default:
        break;
    }
    updateCouponData(updatedData);
  };

  const renderCampaignDetails = (data) => (
    data.map((val, idx) => (
      <div className="col-12" key={idx}>
        <div className="Campaign_Details_Card">
          <div className="Campaign_Details_Card_Header d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div className="Campaign_Logo">
                <img src={val.logo || val.retailerlogo || Error} className="img-fluid" alt="campaign" />
              </div>
              <h5 className="ml-16">{val.wholesaler_name || val.retailer_name || val.group_name}</h5>
            </div>
          </div>
          <div className="Campaign_Details_Card_Body">
            <ul>
              <li>
                <div className="d-flex align-items-center Title_Block">
                  <h5>Start Date</h5>
                  <img src={Error} className="img-fluid ml-5" alt="Error" />
                </div>
                <div className="Content_Block">
                  <div className="Date_Picker">
                    {renderDateInput(
                      "start_date",
                      val.start_date,
                      val.start_date,
                      val.end_date,
                      (e) => updateDate(e.target.value, "start_date", val)
                    )}
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center Title_Block">
                  <h5>Expiration Date</h5>
                  <img src={Error} className="img-fluid ml-5" alt="Error" />
                </div>
                <div className="Content_Block">
                  {renderDateInput(
                    "end_date",
                    val.end_date,
                    val.start_date,
                    val.end_date,
                    (e) => updateDate(e.target.value, "end_date", val)
                  )}
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center Title_Block">
                  <h5>Shutoff Date</h5>
                  <img src={Error} className="img-fluid ml-5" alt="Error" />
                </div>
                <div className="Content_Block">
                  <div className="Date_Picker">
                    {renderDateInput(
                      "shutoff_date",
                      val.shutoff_date,
                      val.start_date,
                      val.end_date,
                      (e) => updateDate(e.target.value, "shutoff_date", val)
                    )}
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center Title_Block">
                  <h5>Max Clips</h5>
                  <img src={Error} className="img-fluid ml-5" alt="Error" />
                </div>
                <div className="Content_Block">
                  <input
                    type="number"
                    defaultValue={1000000}
                    disabled={couponData.action === "edit" || couponData.action === "view"}
                    onChange={(e) => updateDate(e.target.value, "clip_limit", val)}
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    ))
  );

  return (
    <React.Fragment>
      {isDataUpdated && (
        <div className="row g-3">
          {couponData.coupon_target_type_id === 1 && renderCampaignDetails(couponData.wholesalers, true)}
          {couponData.coupon_target_type_id === 2 && renderCampaignDetails(couponData.retailers, false)}
          {couponData.coupon_target_type_id === 3 && renderCampaignDetails(couponData.retailer_groups, false)}
        </div>
      )}
    </React.Fragment>
  );
};

export default CampaignDetail;