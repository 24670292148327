import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import ls, { set } from 'local-storage';
import { getBrand } from '../../API/Wholesalerapi';
import CouponContext from './CouponContext';
import CouponSummary from './CouponSummary';
import scissors from "../../Assets/images/scissors.png";
const Loader = () => <div id="cover-spin"></div>;

const BrandProducts = ({ couponType, provider }) => {
    const { couponData, updateCouponData } = useContext(CouponContext);
    const [searchQuery, setSearchQuery] = useState("");
    const [upcList, setUpcList] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [brandName, setBrandName] = useState("");
    const [spinloading, setSpinLoading] = useState(false);
    const selectedbrand = ls.get("selectedbrand") ? ls.get("selectedbrand") : "";

    const fetchUPCs = useCallback(async () => {
        setSpinLoading(true);
        const response = await getBrand(selectedbrand[0].guid);
        const updatedUPCList = response.data.Message.upc.map(upc => ({
            ...upc,
            brand_name: response.data.Message?.brand_name
        }));

        setUpcList(updatedUPCList);
        setFilteredData(updatedUPCList);
        setSpinLoading(false);
    }, [selectedbrand]);

    useEffect(() => {
        if (provider === 'brand') {
            fetchUPCs();
        }
    }, []);

    useEffect(() => {
        if (!searchQuery) {
            setFilteredData(upcList);
        } else {
            setFilteredData(upcList.filter((eachData) =>
                eachData?.product_name?.toLowerCase().includes(searchQuery.toLowerCase())
            ));
        }
    }, [searchQuery, upcList]);

    const columns = useMemo(
        () => [
            {
                name: "BRAND",
                selector: (row) => row.brand_name,
            },
            {
                name: "UPC",
                selector: (row) => row.upc11,
                sortable: true
            },
            {
                name: "STORE LABEL",
                selector: (row) => row.is_store_label ? "Yes" : "No",
                sortable: true
            },
            {
                name: "PRODUCT NAME",
                selector: (row) => row.product_name
            },
            {
                name: "CATEGORY",
                selector: (row) => row.category_name,
                sortable: true
            }
        ],
        [],
    );

    const rowSelectCriteria = useCallback((row) => {
        return couponData.upc?.includes(row.upc_guid);
    }, []);

    const rewardUPCRowSelectCriteria = useCallback((row) => {
        return couponData.reward_upc?.includes(row.upc_guid);
    }, []);

    const handleUPCChange = useCallback(state => {
        const selectedUPCs = state.selectedRows.map((each) => each.upc_guid);
        updateCouponData({ upc: selectedUPCs });
    }, []);

    const handleRewardUPCChange = useCallback(state => {
        const selectedUPCs = state.selectedRows.map((each) => each.upc_guid);
        updateCouponData({ reward_upc: selectedUPCs });
    }, []);

    return (
        <React.Fragment>
            {spinloading && <Loader />}
            <div className="tab-pane fade show active" id="nav-step-1" role="tabpanel" aria-labelledby="nav-step-1-tab">
                <div className="row g-3">
                    <div className="col-xxl-9 col-xl-8 col-12">
                        <div className="Steps_Header d-flex align-items-center justify-content-between">
                            <h5>Select Products</h5>
                            <div className="Search_Box">
                                <input
                                    type="text"
                                    className="form-control Search_Toggle coupon_search"
                                    placeholder="Search"
                                    aria-label="Search"
                                    aria-describedby="search-addon"
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                <span className="Input_Icon">
                                <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.7 14.9C11.4003 14.9 14.4 11.9003 14.4 8.2C14.4 4.49969 11.4003 1.5 7.7 1.5C3.99969 1.5 1 4.49969 1 8.2C1 11.9003 3.99969 14.9 7.7 14.9Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="3.3333" strokeLinecap="round" strokeLinejoin="round" /> <path d="M16.0004 16.5L12.4004 12.9" stroke="white" strokeWidth="1.5" strokeMiterlimit="3.3333" strokeLinecap="round" strokeLinejoin="round" /></svg>
                                </span>
                            </div>
                        </div>
                        <div className="table-responsive Create_Coupons_Table">
                            {!spinloading && upcList?.length > 0 && (
                                <>
                                    <DataTable
                                        columns={columns}
                                        data={filteredData}
                                        selectableRows
                                        onSelectedRowsChange={handleUPCChange}
                                        selectableRowSelected={rowSelectCriteria}
                                        pagination
                                    />
                                </>
                            )}
                        </div>
                        {(couponData.offer_type === 3 || couponData.offer_type === 4) && (
                            <>
                                <div className="Steps_Header d-flex align-items-center justify-content-between">
                                    <h5>Select Rewards</h5>
                                    <div className="Search_Box">
                                        <input
                                            type="text"
                                            className="form-control Search_Toggle coupon_search"
                                            placeholder="Search"
                                            aria-label="Search"
                                            aria-describedby="search-addon"
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                        <span className="Input_Icon">
                                            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.7 14.9C11.4003 14.9 14.4 11.9003 14.4 8.2C14.4 4.49969 11.4003 1.5 7.7 1.5C3.99969 1.5 1 8.2C1 11.9003 3.99969 14.9 7.7 14.9Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="3.3333" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M16.0004 16.5L12.4004 12.9" stroke="white" strokeWidth="1.5" strokeMiterlimit="3.3333" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                                <div className="table-responsive Create_Coupons_Table">
                                    {!spinloading && upcList?.length > 0 && (
                                        <>
                                            <DataTable
                                                columns={columns}
                                                data={filteredData}
                                                selectableRows
                                                onSelectedRowsChange={handleRewardUPCChange}
                                                selectableRowSelected={rewardUPCRowSelectCriteria}
                                                pagination
                                            />
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                    <div className="col-xxl-3 col-xl-4 col-12">
                        <CouponSummary couponType={couponType} />
                        {couponType === "coupon" && (
                            <div className="Common_Block mt-21">
                                <div className="Common_Block_Header d-flex align-items-center justify-content-between">
                                    <h5 className="">Coupon Preview</h5>
                                </div>
                                <div className="Common_Block_Body pt-12 pr-12 pb-12 pl-12">
                                    <div className="Coupons_Card text-center">
                                        <img
                                            src={scissors}
                                            className="img-fluid scissors"
                                            alt="Products"
                                        />
                                        <img
                                            src={couponData.image_1}
                                            className="img-fluid"
                                            alt=""
                                        />
                                        <h2 className="Price">
                                            {couponData.title}
                                            <span></span>
                                        </h2>
                                        <p>{couponData.description}</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default BrandProducts;
