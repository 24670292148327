import React, { useContext, useEffect, useState } from "react";
import { getWholesalers, getListOfRetailerGroups, getRetailers } from "../../API/Wholesalerapi";
import CouponContext from "./CouponContext";
import TargetList from "./TargetList"; // Import the new component
const Loader = () => <div id="cover-spin"></div>;

const SelectTarget = () => {
  const { couponData, updateCouponData } = useContext(CouponContext);
  const [data, setData] = useState({
    retailersData: [],
    wholesalersData: [],
    retailerGroupData: []
  });

  const couponTypes = [
    {
      id: "wholesaler", name: "Wholesaler", value: 1
    },
    {
      id: "retailer", name: "Retailer", value: 2
    },
    {
      id: "retailerGroups", name: "Retailer Groups", value: 3
    }
  ];
  const [spinloading, setSpinLoading] = useState(false);

  const fetchData = async () => {
    try {
      setSpinLoading(true);
      const [retailersResponse, wholesalersResponse, retailerGroupsResponse] = await Promise.all([
        getRetailers(),
        getWholesalers(),
        getListOfRetailerGroups()
      ]);

      setData({
        retailersData: retailersResponse?.data?.Message || [],
        wholesalersData: wholesalersResponse?.data?.Message || [],
        retailerGroupData: retailerGroupsResponse?.data?.Message?.groups || []
      });
      setSpinLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setData({
        retailersData: [],
        wholesalersData: [],
        retailerGroupData: []
      });
      setSpinLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const checkUncheckAll = (checked) => {
    updateCouponData({
      selectAll: checked,
    });
    let updatedSelectedIds;
    if (checked) {
      switch (couponData.coupon_target_type_id) {
        case 1:
          updatedSelectedIds = data.wholesalersData.map(wholesaler => wholesaler.wholesaler_id);
          updateCouponData({
            wholesalers: data.wholesalersData,
          });
          break;
        case 2:
          updatedSelectedIds = data.retailersData.map(retailer => retailer.retailer_id);
          updateCouponData({
            retailers: data.retailersData,
          });
          break;
        case 3:
          updatedSelectedIds = data.retailerGroupData.map(group => group.retailer_group_id);
          updateCouponData({
            retailer_groups: data.retailerGroupData,
          });
          break;
        default:
          break;
      }
      updateCouponData({
        selectedIds: updatedSelectedIds,
      });
    } else {
      updateCouponData({
        selectedIds: [],
        wholesalers: [],
        retailers: [],
        retailer_groups: []
      });
    }
  };

  const renderCheckUncheckAll = () => (
    <div className="header_right" style={{ float: "right", marginTop: "-8px", color: "#000" }}>
      <input
        type="checkbox"
        style={{ marginRight: "5px", width: "18px", height: "18px" }}
        onChange={(e) => {
          checkUncheckAll(e.target.checked);
        }}
        checked={couponData.selectAll}
        disabled={couponData.action === "edit" || couponData.action === "view" }
      />
      Check / Uncheck All
    </div>
  );

  const selectIds = (selected, id) => {
    updateCouponData({
      selectAll: false,
    });
    let updatedSelectedIds;
    if (selected) {
      updatedSelectedIds = couponData.selectedIds.includes(id) ? couponData.selectedIds : [...couponData.selectedIds, id];
    } else {
      updatedSelectedIds = couponData.selectedIds.filter((existingId) => existingId !== id);
    }
    updateCouponData({
      selectedIds: updatedSelectedIds,
    });
    switch (couponData.coupon_target_type_id) {
      case 1:
        const selectedWholesalers = data.wholesalersData.filter(wholesaler => updatedSelectedIds.includes(wholesaler.wholesaler_id));
        updateCouponData({
          wholesalers: selectedWholesalers,
        });
        break;
      case 2:
        const selectedRetailers = data.retailersData.filter(retailer => updatedSelectedIds.includes(retailer.retailer_id));
        updateCouponData({
          retailers: selectedRetailers,
        });
        break;
      case 3:
        const selectedRetailerGroups = data.retailerGroupData.filter(group => updatedSelectedIds.includes(group.retailer_group_id));
        updateCouponData({
          retailer_groups: selectedRetailerGroups
        });
        break;
      default:
        break;
    }
  };

  const changeCouponTarget = (id) => {
    updateCouponData({
      coupon_target_type_id: id,
      selectAll: false,
      selectedIds: [],
      wholesalers: [],
      retailers: [],
      retailer_groups: []
    });
  }

  return (
    <div className="row g-3">
      <div className="col-12">
        {spinloading && <Loader />}
        <div className="Common_Block">
          <div className="Common_Block_Header d-flex align-items-center bg-white text-black justify-content-center">
            {couponTypes.map(option => (
              <h5 className="text-black mr-10" key={option.value}>

                <input
                  type="checkbox"
                  className="mr-7"
                  id={`${option.id}checkbox`}
                  checked={couponData.coupon_target_type_id === option.value}
                  disabled={couponData.action === "edit" || couponData.action === "view" }
                  onChange={() => {
                    changeCouponTarget(option.value);
                  }}
                />
                {option.name}
              </h5>
            ))}
          </div>
          <div className="Common_Block_Body pr-12 pl-12 pt-20 pb-20">
            {couponData.coupon_target_type_id > 0 && renderCheckUncheckAll()}
            <TargetList
              couponTargetTypeId={couponData.coupon_target_type_id}
              data={data}
              selectIds={selectIds}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectTarget;
