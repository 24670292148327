import React, { useContext } from "react";
import CouponContext from "./CouponContext";

const TargetList = ({ couponTargetTypeId, data, selectIds }) => {
  const { couponData, updateCouponData } = useContext(CouponContext);
  const renderListItems = () => {
    switch (couponTargetTypeId) {
      case 1:
        return data.wholesalersData.map((eachData) => (
          <li key={eachData.wholesaler_id}>
            <div className="Retailer_Block" style={{ boxShadow: "3px 3px 3px #B2BEB5" }}>
              <input
                type="checkbox"
                id="Retailer"
                disabled={couponData.action === "edit" || couponData.action === "view" }
                checked={couponData.selectedIds.includes(eachData.wholesaler_id)}
                onChange={(e) => selectIds(e.target.checked, eachData.wholesaler_id)}
              />
              <div className="RetailerImage">
                <img src={eachData.logo} className="img-fluid" alt={eachData.wholesaler_name} />
              </div>
              <h5>{eachData.wholesaler_name}</h5>
            </div>
          </li>
        ));
      case 2:
        return data.retailersData.map((eachData) => (
          <li key={eachData.retailer_id}>
            <div className="Retailer_Block" style={{ boxShadow: "3px 3px 3px #B2BEB5" }}>
              <input
                type="checkbox"
                id="Retailer"
                disabled={couponData.action === "edit" || couponData.action === "view" }
                checked={couponData.selectedIds.includes(eachData.retailer_id)}
                onChange={(e) => selectIds(e.target.checked, eachData.retailer_id)}
              />
              <div className="RetailerImage">
                <img src={eachData.logo} className="img-fluid" alt={eachData.retailer_name} />
              </div>
              <h5>{eachData.retailer_name}</h5>
            </div>
          </li>
        ));
      case 3:
        return data.retailerGroupData.map((retailerGroup) => (
          <li key={retailerGroup.retailer_group_id}>
            <div className="Retailer_Block" style={{ boxShadow: "3px 3px 3px #B2BEB5" }}>
              <input
                type="checkbox"
                id="Retailer"
                disabled={couponData.action === "edit" || couponData.action === "view" }
                checked={couponData.selectedIds.includes(retailerGroup.retailer_group_id)}
                onChange={(e) => selectIds(e.target.checked, retailerGroup.retailer_group_id)}
              />
              <h5>{retailerGroup.group_name}</h5>
            </div>
          </li>
        ));
      default:
        return null;
    }
  };

  return <ul className="mt-30 d-flex Retailer">{renderListItems()}</ul>;
};

export default TargetList;