import React, { useContext } from 'react';
import { Button, Form, Spinner } from "react-bootstrap";
import BlockImgBlock from "../../Assets/images/blockbg.png";
import LogoBig from "../../Assets/images/logo-big.png";
import Arrowsvg from "../../Assets/images/arrow.svg";
import CalenderSvgImage from "../../Assets/images/calender.svg";
import CalenderSvgRedImage from "../../Assets/images/calenderRed.svg";
import CouponContext from './CouponContext';
import { buildCoupon, updateCouponProducts } from '../../API/CreateCoupon';
import ls from "local-storage";
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

const Preview = ({ couponType }) => {
    const { couponData, updateCouponData } = useContext(CouponContext);
    const brands = ls.get("brands") || "";
    const history = useHistory();
    const getCouponCategory = (offerType) => {
        switch (offerType) {
            case 1:
            case 2:
                return 2;
            case 3:
            case 4:
                return 5;
            default:
                return 0;
        }
    };

    const formatDates = (entity) => ({
        ...entity,
        start_date: `${couponType === "scan" ? couponData.start_date : entity.start_date}T00:00:00.000Z`,
        end_date: `${couponType === "scan" ? couponData.end_date : entity.end_date}T00:00:00.000Z`,
        shutoff_date: `${couponType === "scan" ? couponData.shutoff_date : entity.shutoff_date}T00:00:00.000Z`,
        clip_limit: entity.clip_limit ?? 1000000,
        redeem_limit: 0
    });

    const prepareWholesalers = () => {
        switch (couponData.coupon_target_type_id) {
            case 1:
                return couponData.wholesalers.map(wholesaler => ({
                    ...formatDates(wholesaler),
                    retailer: wholesaler.retailers?.length ? wholesaler.retailers.map(retailer => ({
                        ...formatDates(retailer)
                    })) : []
                }));
            case 2:
                const retailerArr = couponData.retailers.map(retailer => ({
                    ...formatDates(retailer),
                    wholesaler_id: retailer.wholesaler_id
                }));
                const sorted = retailerArr.reduce((acc, val) => {
                    acc[val.wholesaler_id] = acc[val.wholesaler_id] || [];
                    acc[val.wholesaler_id].push(val);
                    return acc;
                }, {});
                return Object.keys(sorted).map(wholesaler_id => ({
                    wholesaler_id: parseInt(wholesaler_id),
                    start_date: sorted[wholesaler_id][0].start_date,
                    end_date: sorted[wholesaler_id][0].end_date,
                    shutoff_date: sorted[wholesaler_id][0].shutoff_date,
                    clip_limit: sorted[wholesaler_id][0].clip_limit,
                    redeem_limit: 0,
                    retailer: sorted[wholesaler_id]
                }));
            case 3:
                const mergedWholesalers = {};
                couponData.retailer_groups.forEach(group => {
                    group.wholesalers.forEach(wholesaler => {
                        if (!mergedWholesalers[wholesaler.wholesaler_id]) {
                            mergedWholesalers[wholesaler.wholesaler_id] = {
                                ...formatDates({...wholesaler,
                                    start_date: group.start_date,
                                    end_date: group.end_date,
                                    shutoff_date: group.shutoff_date,
                                    clip_limit: group.clip_limit ?? 1000000
                                }),
                                retailer: []
                            };
                        }
                        wholesaler.retailers.forEach(retailer => {
                            if (!mergedWholesalers[wholesaler.wholesaler_id].retailer.some(r => r.retailer_id === retailer.retailer_id)) {
                                mergedWholesalers[wholesaler.wholesaler_id].retailer.push({
                                    ...formatDates({
                                        ...retailer,
                                        start_date: group.start_date,
                                        end_date: group.end_date,
                                        shutoff_date: group.shutoff_date
                                    }),
                                    clip_limit: group.clip_limit ?? 1000000,
                                    redeem_limit: 0
                                });
                            }
                        });
                    });
                });
                return Object.values(mergedWholesalers);
            default:
                return [];
        }
    };

    const createCoupon = async () => {
        updateCouponData({ submitting_offer: true });
        toast.success("Processing 👌");
        const couponCategory = getCouponCategory(couponData.offer_type);
        const data = JSON.stringify({
            addtional_details: couponData.addtional_details || "",
            brand_guid: brands[0]?.guid,
            clip_limit: 0,
            clip_limit_amount: 0,
            coupon_category: couponType === "scan" ? 6 : couponCategory,
            coupon_code: couponData.slug === "fsi" ? couponData.coupon_code || "" : "",
            coupon_condition: {
                per_customer_limit: 1,
                per_transaction_limit: parseInt(couponData.coupon_limit) || 1
            },
            coupon_target_type_id: couponData.coupon_target_type_id === 3 ? 2 : couponData.coupon_target_type_id,
            coupon_type: couponCategory === 2 ? couponData.offer_type : couponData.offer_type === 3 ? 1 : 2,
            description: couponData.description,
            disclaimer: couponData.disclaimer,
            end_date: `${couponData.end_date}T00:00:00.000Z`,
            image_1: couponData.image_1,
            image_1_thumbnail: "",
            is_discount_percentage: couponData.offer_type === 1 ? 0 : 1,
            is_flex: couponData.coupon_limit > 1 ? 0 : 1,
            limit_per_customer: 0,
            manufactuer_id: 0,
            max_reward_limit: 0,
            media_url: "",
            min_purchase_amount: 1,
            min_quantity: parseInt(couponData.min_quantity) || 1,
            offer_gs1: "",
            redeem_limit: 0,
            redeem_limit_amount: 0,
            retailer_groups: couponData.retailer_groups,
            reward_amount: parseFloat(couponData.reward_amount) || 1,
            reward_quantity: parseInt(couponData.reward_quantity || 0),
            reward_upc: couponData.reward_upc?.map(Number) || [],
            shutoff_date: `${couponData.shutoff_date}T00:00:00.000Z`,
            start_date: `${couponData.start_date}T00:00:00.000Z`,
            title: couponData.title,
            upc: couponData.upc.map(Number) || [],
            vendor_participation: {
                fixed_amount: couponData.fixed_amount,
                per_redemption: couponData.per_redemption,
                per_scan: couponData.per_scan,
            },
            wholesalers: prepareWholesalers()
        });
        if(couponData.action === "edit"){
            const request = {
                upc: couponData.upc.map(String) || [],
                reward_upc: couponData.reward_upc?.map(String) || []
            }
            await updateCouponProducts(request, couponData.guid);
        }
        
        await buildCoupon(data, couponData.action, couponData.guid).then((response) => {
            if (response.status === 200) {
                toast.success(`coupon ${couponData.action === "edit" ? "updated" : "created"} successfully.`);
                history.push('/confirmation/whcoupon');
            } else {
                updateCouponData({ submitting_offer: false });
                toast.error("Error occurred while creating coupon");
            }
        });
    };

    return (
        <div className="row g-3 justify-content-center">
            <div className="col-12">
                <div className="Preview_Card Common_Block">
                    <div className="Common_Block_Body">
                        <h5>
                            <span>Your offers update will be sent to retailers</span> <b>within</b>
                        </h5>
                        <h4>2 hours</h4>
                        <div className="Preview_Outer_Block">
                            <ul className="Preview_Card_Row">
                                <li className="text-center Coupons_Image" style={{ margin: '0 auto', width: '50%' }}>
                                    <div className="Coupons_Outer_Block">
                                        {couponData.image_1 ? (
                                            <div className="Coupons_Card">
                                                <img src={couponData.image_1} className="img-fluid" alt="Coupon" />
                                                <h2 className="Price">{couponData.title}<span></span></h2>
                                                <p>{couponData.description}</p>
                                            </div>
                                        ) : (
                                            <div className="ImgBlock position-relative text-center">
                                                <img src={BlockImgBlock} className="img-fluid" alt="Background" />
                                                <img src={LogoBig} className="img-fluid LogoBig" alt="Background" />
                                            </div>
                                        )}
                                    </div>
                                </li>
                            </ul>
                            <div className="row">
                                <div className="col-lg-6 col-12 mt-45">
                                    <div className="date d-flex align-items-center">
                                        <Form.Group controlId="exampleForm.ControlInput1">
                                            <img src={CalenderSvgImage} className="img-fluid" alt="Calendar" />
                                            <Form.Label>&nbsp;Start: &nbsp;</Form.Label>
                                            <span className="fw-bold">{couponData.start_date}</span>
                                        </Form.Group>
                                        <Form.Group className="ml-12" controlId="exampleForm.ControlInput2">
                                            <img src={CalenderSvgRedImage} className="img-fluid" alt="Calendar" />
                                            <Form.Label>&nbsp; End: &nbsp;</Form.Label>
                                            <span className="fw-bold">{couponData.end_date}</span>
                                        </Form.Group>
                                    </div>
                                </div>
                                {couponData.action !== "view" && (
                                    <div className="col-lg-6 col-12 mt-45">
                                        <Form.Check
                                            type="checkbox"
                                            name="make_offer"
                                            label="By clicking, I understand that offer targets and dates cannot be changed after release and I confirm to release the offer. I also accept BrandIQ terms and condition"
                                            onChange={(e) => updateCouponData({ confirm_offer: e.target.checked })}
                                        />
                                        <Button
                                            variant=""
                                            type="button"
                                            className="Btn_blue mt-20 w-50 ms-auto"
                                            disabled={couponData.submitting_offer || !couponData.confirm_offer}
                                            onClick={createCoupon}
                                        >
                                            Save and Send Offer
                                            {couponData.submitting_offer ? (
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className="float-end"
                                                />
                                            ) : (
                                                <img src={Arrowsvg} className="img-fluid float-end" alt="Arrow" />
                                            )}
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Preview;