import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../Assets/images/logo-small.png";
import Avtar from "../../Assets/images/avtar-6.png";
import ls from "local-storage";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SecondHeader from "./SecondHeader/SecondHeader";

const NotificationIcon = () => (
  <Link to="/Notification" className="Notification_Icon">
    <i className="far fa-bell"></i>
  </Link>
);

const ProfileIcon = () => (
  <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.3337 14.6666H10.0003V13.3333C10.0003 12.8029 9.78961 12.2942 9.41454 11.9191C9.03946 11.544 8.53076 11.3333 8.00032 11.3333H4.00032C3.46989 11.3333 2.96118 11.544 2.58611 11.9191C2.21104 12.2942 2.00033 12.8029 2.00033 13.3333V14.6666H0.666992V13.3333C0.666992 12.4492 1.01818 11.6014 1.6433 10.9763C2.26842 10.3511 3.11627 9.99996 4.00032 9.99996H8.00032C8.88438 9.99996 9.73223 10.3511 10.3573 10.9763C10.9825 11.6014 11.3337 12.4492 11.3337 13.3333V14.6666ZM6.00032 8.66663C5.47504 8.66663 4.95489 8.56316 4.46959 8.36214C3.98429 8.16113 3.54333 7.86649 3.1719 7.49505C2.80046 7.12362 2.50583 6.68266 2.30481 6.19736C2.10379 5.71206 2.00033 5.19191 2.00033 4.66663C2.00033 4.14134 2.10379 3.62119 2.30481 3.13589C2.50583 2.65059 2.80046 2.20963 3.1719 1.8382C3.54333 1.46676 3.98429 1.17213 4.46959 0.971108C4.95489 0.770089 5.47504 0.666626 6.00032 0.666626C7.06119 0.666626 8.07861 1.08805 8.82875 1.8382C9.5789 2.58834 10.0003 3.60576 10.0003 4.66663C10.0003 5.72749 9.5789 6.74491 8.82875 7.49505C8.07861 8.2452 7.06119 8.66663 6.00032 8.66663ZM6.00032 7.33329C6.70757 7.33329 7.38585 7.05234 7.88594 6.55224C8.38604 6.05215 8.66699 5.37387 8.66699 4.66663C8.66699 3.95938 8.38604 3.2811 7.88594 2.78101C7.38585 2.28091 6.70757 1.99996 6.00032 1.99996C5.29308 1.99996 4.6148 2.28091 4.11471 2.78101C3.61461 3.2811 3.33366 3.95938 3.33366 4.66663C3.33366 5.37387 3.61461 6.05215 4.11471 6.55224C4.6148 7.05234 5.29308 7.33329 6.00032 7.33329Z"
      fill="current"
    />
  </svg>
);

class Header extends React.Component {
  constructor(props) {
    super(props);
    toast.configure();
    this.state = {
      brandinfo: ""
    };
  }

  logoutHandler = () => {
    ls.clear("token");
    ls.clear("brands");
    localStorage.removeItem("selectedbrand");
    ls.clear("selectedbrand");
    localStorage.removeItem("footerDate");
    window.location.href = "/login";
  };

  render() {
    const brands = ls.get('brands');
    const decodetoken = ls.get("decodetoken");
    const selectedbrand = ls.get("selectedbrand") || "";

    return (
      <>
        <header className="Header">
          <div className="Main_Header">
            <nav className="Navbar">
              <div className="container-fluid">
                <div className="d-flex align-items-center justify-content-between">
                  <Link className="Navbar-Brand" to={brands[0].scopes[0] === 'all' ? "/brandlist" : "/dashboard"}>
                    <img src={Logo} className="img-fluid" alt="Logo" />
                  </Link>
                  <ul className="Navbar_Nav d-flex align-items-center">
                    {/* <li>
                      <Link to="/Notification" className="Notification_Icon"><i className="far fa-bell"></i></Link>
                    </li> */}

                    <li key={2}>
                      {/* <Link className="Navbar_Nav_Link" to="/helpcenterdetails">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.5 14.2499H13.5V6.86769L9 2.77719L4.5 6.86769V14.2499ZM14.25 15.7499H3.75C3.55109 15.7499 3.36032 15.6709 3.21967 15.5303C3.07902 15.3896 3 15.1989 3 14.9999V8.24994H0.75L8.49525 1.20894C8.63333 1.0833 8.81331 1.01367 9 1.01367C9.18669 1.01367 9.36667 1.0833 9.50475 1.20894L17.25 8.24994H15V14.9999C15 15.1989 14.921 15.3896 14.7803 15.5303C14.6397 15.6709 14.4489 15.7499 14.25 15.7499ZM5.625 9.74994H7.125C7.125 10.2472 7.32254 10.7241 7.67417 11.0758C8.02581 11.4274 8.50272 11.6249 9 11.6249C9.49728 11.6249 9.97419 11.4274 10.3258 11.0758C10.6775 10.7241 10.875 10.2472 10.875 9.74994H12.375C12.375 10.645 12.0194 11.5035 11.3865 12.1364C10.7535 12.7694 9.89511 13.1249 9 13.1249C8.10489 13.1249 7.24645 12.7694 6.61351 12.1364C5.98058 11.5035 5.625 10.645 5.625 9.74994Z"
                            fill="current"
                          />
                        </svg>

                        <span>Support Center</span>
                      </Link> */}
                    </li>

                    <li className="dropdown Profile_Dropdown" key={3}>
                      <Link
                        className="Navbar_Nav_Link dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <svg
                          width="12"
                          height="15"
                          viewBox="0 0 12 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.3337 14.6666H10.0003V13.3333C10.0003 12.8029 9.78961 12.2942 9.41454 11.9191C9.03946 11.544 8.53076 11.3333 8.00032 11.3333H4.00032C3.46989 11.3333 2.96118 11.544 2.58611 11.9191C2.21104 12.2942 2.00033 12.8029 2.00033 13.3333V14.6666H0.666992V13.3333C0.666992 12.4492 1.01818 11.6014 1.6433 10.9763C2.26842 10.3511 3.11627 9.99996 4.00032 9.99996H8.00032C8.88438 9.99996 9.73223 10.3511 10.3573 10.9763C10.9825 11.6014 11.3337 12.4492 11.3337 13.3333V14.6666ZM6.00032 8.66663C5.47504 8.66663 4.95489 8.56316 4.46959 8.36214C3.98429 8.16113 3.54333 7.86649 3.1719 7.49505C2.80046 7.12362 2.50583 6.68266 2.30481 6.19736C2.10379 5.71206 2.00033 5.19191 2.00033 4.66663C2.00033 4.14134 2.10379 3.62119 2.30481 3.13589C2.50583 2.65059 2.80046 2.20963 3.1719 1.8382C3.54333 1.46676 3.98429 1.17213 4.46959 0.971108C4.95489 0.770089 5.47504 0.666626 6.00032 0.666626C7.06119 0.666626 8.07861 1.08805 8.82875 1.8382C9.5789 2.58834 10.0003 3.60576 10.0003 4.66663C10.0003 5.72749 9.5789 6.74491 8.82875 7.49505C8.07861 8.2452 7.06119 8.66663 6.00032 8.66663ZM6.00032 7.33329C6.70757 7.33329 7.38585 7.05234 7.88594 6.55224C8.38604 6.05215 8.66699 5.37387 8.66699 4.66663C8.66699 3.95938 8.38604 3.2811 7.88594 2.78101C7.38585 2.28091 6.70757 1.99996 6.00032 1.99996C5.29308 1.99996 4.6148 2.28091 4.11471 2.78101C3.61461 3.2811 3.33366 3.95938 3.33366 4.66663C3.33366 5.37387 3.61461 6.05215 4.11471 6.55224C4.6148 7.05234 5.29308 7.33329 6.00032 7.33329Z"
                            fill="current"
                          />
                        </svg>
                        <span>{decodetoken.first_name.toUpperCase()}</span>
                      </Link>
                      {selectedbrand && (
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                          <li>
                            <Link className="dropdown-item" to={`/profile/${decodetoken.user_guid}`}>
                              Account
                            </Link>
                          </li>
                          {brands[0].scopes !== "all" && (
                            <li>
                              <Link className="dropdown-item" to={`/upclist`}>
                                UPC List
                              </Link>
                            </li>
                          )}
                          <li>
                            <Link className="dropdown-item" to={`/retailer-group`}>
                              Retailer Groups
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" onClick={this.logoutHandler}>
                              Logout
                            </Link>
                          </li>
                        </ul>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
          <SecondHeader
            Avtar={Avtar}
            decodetoken={decodetoken}
            brands={brands}
            changeBrandsheader={this.onchangeBrands}
            onchangeBrandswizard={this.onchangeBrandswizard}
            onchangeBrandUpclist={this.onchangeBrandUpclist}
            title={this.props.title}
            changeallBrands={this.props.onchangeallBrands}
            location={this.props.location}
          />
        </header>
        <div className="modal fade Confirmation_Modal" id="staticBackdropheader" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <i className="fas fa-exclamation-triangle text-center"></i>
                <h4 className="mt-20 fw-bold text-center">Not available for your current subscription.</h4>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade Confirmation_Modal" id="ViewModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="ViewModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body p-4">
                <h4 className="fw-bold text-center">Access Denied</h4>
                <p className="mt-10 text-center">You are not authorized to perform this task.</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">OK</button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade Confirmation_Modal" id="inactivemodal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="ViewModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body p-4">
                <h4 className="fw-bold text-center">Not yet active</h4>
                <p className="mt-10 text-center">.</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">OK</button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Header;